<template>
  <div class="cydx">
    <div class="left">
      <div class="pub_border top">
        <div class="pub_title" @click="zzqkFlag = true">种植情况</div>
        <div class="content">
          <div class="chart" id="Zzqk"></div>
        </div>
      </div>
      <div class="pub_border middle">
        <div class="pub_title" @click="pzmjFlag = true">品种面积</div>
        <div class="chart" id="Pzmj"></div>
      </div>
      <div class="pub_border bottom">
        <div class="pub_title">县蔬菜基地明细</div>
        <div class="content">
          <div class="wrap">
            <ul :class="{ marquee_top: animate }" @mouseenter="scjdEnter" @mouseleave="scjdOut">
              <li v-for="(item, i) in scjdData" :key="item.company_name + i">
                <span>{{ item.company_name }}</span>
                <span>{{ item.palnt_name }}</span>
                <span>{{ item.count }}吨</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="center" @mouseleave="hideAreaTip">
      <div class="centerMenu">
        <div @click="lookList('', 'area')">
          <div class="label">县总数(个)</div>
          <div class="number">{{ countyTotal }}</div>
        </div>
        <div>
          <div class="label">种植面积(亩)</div>
          <div class="number">{{ areaTotal }}</div>
        </div>
        <div>
          <div class="label">种植品种(种)</div>
          <div class="number">{{ varietiesTotal }}</div>
        </div>
        <div>
          <div class="label">产量(吨)</div>
          <div class="number">{{ yieldTotal }}</div>
        </div>
      </div>
      <plant-map has-drop height="83%" county-select @selectCity="mapSelect" @geoMousemove="showAreaTip"
        style="margin-top:10px;" :chart-option="chartOption" :geo-data="zzqkData" @initPage="initPage" ref="plantMapRef"
        @lookDetail="lookDetail"></plant-map>
      <div class="detailBtn" v-show="detailFlag == '饶阳县' || detailFlag == '固安县'" @click="changeDetail(detailFlag)">
        <p>查看详情</p>
      </div>
    </div>
    <div class="right">
      <div class="pub_border top">
        <div class="pub_title" @click="xscclFlag = true">县蔬菜产量</div>
        <div class="content">
          <div class="chart" id="Xsccl"></div>
        </div>
      </div>
      <div class="pub_border middle">
        <div class="pub_title" @click="lsclFlag = true">各品种历史产量</div>
        <div class="chart" id="Lscl"></div>
      </div>
      <div class="pub_border bottom">
        <div class="pub_title">基地品牌</div>
        <div class="content">
          <div class="wrap">
            <img @click="arrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow" alt />
            <el-carousel ref="cardShow" height="205px" arrow="never" indicator-position="none">
              <el-carousel-item v-for="(item, i) in jdppData" :key="item.company_id + i">
                <div class="inner" @click="lookBrandDetail(item)">
                  <h3>{{ item.name }}</h3>
                  <img :src="sysUrl + item.img_url" @error="e => { e.target.src = defaultImg }" alt />
                  <p>{{ item.company_name }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>
            <img @click="arrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 企业列表 -->
    <!--    <enterprise-list ref="enterpriseList"></enterprise-list>-->
    <!-- 品牌列表 -->
    <brand-list v-show="brandFlag" @closeDialog="brandFlag = false"></brand-list>
    <!-- 品牌展示 -->
    <brand-detail ref="brandDetail"></brand-detail>
    <!-- 今日供应列表 -->
    <supply-list ref="supplyList"></supply-list>
    <!-- 种植情况弹框 -->
    <base-dialog width="1200px" height="500px" label="种植情况" :show-close="true" v-show="zzqkFlag"
      @closeDialog="zzqkFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="ZzqkDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
    <!-- 品种面积弹框 -->
    <base-dialog width="800px" height="600px" label="品种面积" :show-close="true" v-show="pzmjFlag"
      @closeDialog="pzmjFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="PzmjDialog" style="width:800px;height:500px;"></div>
      </div>
    </base-dialog>
    <!-- 县蔬菜产量弹框 -->
    <base-dialog width="1200px" height="500px" label="县蔬菜产量" :show-close="true" v-show="xscclFlag"
      @closeDialog="xscclFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="XscclDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
    <!-- 各品种历史产量弹框 -->
    <base-dialog width="1200px" height="500px" label="各品种历史产量" :show-close="true" v-show="lsclFlag"
      @closeDialog="lsclFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="LsclDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
    <div ref="areaTipRef"
      style="display: none;position: fixed;padding:0;width: 280px;background: rgba(2,18,81,0.68);z-index: 99999">
      <div
        style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">
        {{ areaData.areaName }}</div>
      <div style="margin-left: 1em;line-height: 30px">
        <span>
          种植面积：
          <span style="color:#0CFED9;font-size: 18px">{{ areaData.plantnum || 0 }}亩</span>
        </span>
      </div>
      <div style="margin-left: 1em;line-height: 30px">
        <span>
          企业数：
          <span style="color:#0CFED9;font-size: 18px">{{ areaData.comnum || 0 }}家</span>
        </span>
      </div>
    </div>
    <div ref="areaTipRefs" style="display: none;position: fixed;padding:0;width: 280px;background: rgba(2,18,81,0.68);">
      <div
        style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">
        {{ areaDatas.areaName }}</div>
      <div style="margin-left: 1em;line-height: 30px">
        <span>
          种植面积：
          <span style="color:#0CFED9;font-size: 18px">{{ areaDatas.plantnum || 0 }}亩</span>
        </span>
      </div>
      <div style="margin-left: 1em;line-height: 30px">
        <span>
          企业数：
          <span style="color:#0CFED9;font-size: 18px">{{ areaDatas.comnum || 0 }}家</span>
        </span>
      </div>
      <div style="margin-left: 1em;line-height: 30px;text-align: center" v-show="areaDatas.areaName == '饶阳县' || '固安县'">
        <span style="cursor: pointer" @click="changeDetail(areaDatas.areaName)">查看详情</span>
      </div>
    </div>
    <enterprise-list ref="enterpriseList" :z-index="104"></enterprise-list>

    <jiqunList ref="jiqunList"></jiqunList>
    <!-- 企业信息 -->
    <enterprise-info ref="enterpriseInfo" :z-index="105"></enterprise-info>
  </div>
</template>

<script>
import * as api from '@/api/cygk' //接口
import plantMap from '@/components/map/map1'
import gj from '@/components/jiqun/gj' //接口
import jiqunList from '@/components/jiqun/jiqunList.vue' //企业列表
import EnterpriseInfo from '@/components/enterprise' //企业信息
import EnterpriseList from '@/components/enterpriseList' //企业列表
import BrandList from '@/components/brandList' //品牌列表
import BrandDetail from '@/components/brandDetail' //品牌展示
import SupplyList from '@/components/supplyList' //今日供应列表
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import {
  companyList,
} from '@/api/dialog' //接口
export default {
  components: {
    BaseDialog,
    plantMap,
    EnterpriseList,
    BrandList,
    SupplyList,
    BrandDetail,
    jiqunList,
    EnterpriseInfo
  },
  data() {
    return {
      zzqk: '', //种植情况
      zzqkDialog: '', //种植情况弹框
      zzqkData: [], //种植情况
      zzqkDatas: [], //种植情况
      zzqkFlag: false, //种植情况弹框
      currCityObj: {}, //当前地图层级信息
      xsccl: '', //县蔬菜产量
      xscclDialog: '', //县蔬菜产量
      xscclFlag: false, //县蔬菜产量
      xscclData: [], //县蔬菜产量
      lscl: '', //各品种历史产量
      lsclDialog: '', //各品种历史产量
      lsclFlag: false, //各品种历史产量
      pzmj: '', //品种面积
      pzmjDialog: '', //品种面积
      pzmjFlag: false, //品种面积弹框
      scjdData: [], //县蔬菜基地明细
      scjdTimer: '', //县蔬菜基地明细定时器
      jdppData: [], //基地品牌
      areaCode: '', //区域
      // provinceMap:'',//河北省地图
      // option: '',
      // drop: false,
      // hasDrop: false,
      animate: false,
      // imageUrl,
      // qyFlag:false,//企业列表弹框
      brandFlag: false, //品牌列表弹框
      // brandDetailFlag:false,//品牌展示弹框
      // supplyFlag:false,//今日供应列表弹框
      sysUrl,
      defaultImg: require('@/assets/images/default_img.png'),
      areaData: {
        // 地图tooltip数据
        areaName: '',
        plantnum: 0,
        comnum: 0,
      },
      areaDatas: {
        // 地图tooltip数据
        areaName: '',
        plantnum: 0,
        comnum: 0,
      },
      timer: null,
      countyTotal: 0,
      areaTotal: 0,
      varietiesTotal: 0,
      yieldTotal: 0,
      chartOption: {
        layoutSize: '85%',
        tooltip: (params) => {
          let data = params.data
          let html = `<div style="padding:0;width: 280px;height: 120px;background: rgba(2,18,81,0.68);">
                            <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name
            }</div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''
            }</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''
            }</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>供应量：<span style="color:#0CFED9;font-size: 18px">${data.yield || 0
            }</span>吨</span></div>
                            <!--<div style="margin-left: 1em;line-height: 30px"><span>时间：<span style="color:#0CFED9;font-size: 18px">${data.plant_time
              ? data.plant_time.substr(0, 10)
              : ''
            }</span></span></div>-->
                        </div>`
          return html
        },
      },
      form: {  //查询企业列表
        name: '', //名称
        pageNum: 1,
        pageSize: 100000,
        parentAreaCodes: '', //区域id 12位
      },
      detailFlag: ''
    }
  },
  mounted() {
    this.groupCountByAreaCode() //左侧种植面积
    this.getPlatInfoByAreaCode() //左侧种植情况
    this.getVegetablesBaseInfo() //左侧县蔬菜基地明细
    this.getRecoveryCount()  //右侧蔬菜产量
    this.getHisData()        //右侧品种历史产量
    this.getBandList()       //右侧基地品牌
    this.getcountlist()      //上侧个数明细
    this.zzqkInit('zzqk', 'Zzqk')
    this.zzqkInit('zzqkDialog', 'ZzqkDialog')  //种植情况弹框
    this.xscclInit('xsccl', 'Xsccl')
    this.xscclInit('xscclDialog', 'XscclDialog') //蔬菜情况弹框
    this.lsclInit('lscl', 'Lscl')
    this.lsclInit('lsclDialog', 'LsclDialog')  //历史产量弹框
    this.pzmjInit('pzmj', 'Pzmj')
    this.pzmjInit('pzmjDialog', 'PzmjDialog')  //品种面积弹框
  },
  methods: {
    lookList(val, title) {
      console.log(gj)
      //查看企业列表
      // this.qyFlag=true
      // companyTypeId:'3',
      // market:'1',//是否是市场标记 0是；1否永清县、固安县、围场满族蒙古族自治县、乐亭县、玉田县、昌黎县、定兴县、涿州市、青县、滦南县、饶阳县、崇礼区、丰宁满族自治县、清苑区、邯郸经济技术开发区、阜城县、新乐市、馆陶县、望都县、鸡泽县
      if (title == 'gj') {
        this.$refs.jiqunList.show({ title: '国家集群县', list: gj.gj })
      } else if (title == 'sheng') {
        this.$refs.jiqunList.show({ title: '省集群县汇总', list: gj.sheng })
      } else if (title == 'area') {
        let list = [
          { city: '廊坊市', area: '永清县' },
          { city: '廊坊市', area: '固安县' },
          { city: '承德市', area: '围场满族蒙古族自治县' },
          { city: '唐山市', area: '乐亭县' },
          { city: '唐山市', area: '玉田县' },
          { city: '秦皇岛', area: '昌黎县' },
          { city: '保定市', area: '定兴县' },
          { city: '保定市', area: '涿州市' },
          { city: '沧州市', area: '青县' },
          { city: '唐山市', area: '滦南县' },
          { city: '衡水市', area: '饶阳县' },
          { city: '张家口市', area: '崇礼区' },
          { city: '承德市', area: '丰宁满族自治县' },
          { city: '保定市', area: '清苑区' },
          { city: '邯郸市', area: '邯郸经济技术开发区' },
          { city: '衡水市', area: '阜城县' },
          { city: '石家庄市', area: '新乐市' },
          { city: '邯郸市', area: '馆陶县' },
          { city: '保定市', area: '望都县' },
          { city: '邯郸市', area: '鸡泽县' },
        ]
        this.$refs.jiqunList.show({ title: '重点发展县', list })
      } else {
        let obj = {
          type: 1,
          param: {
            // companyTypeId:val,
            typeName: title,
            // market:'1',//是否是市场标记 0是；1否
            // parentAreaCodes:''//区域id 12位
          },
          title,
        }
        this.$refs.enterpriseList.show(obj)
      }
    },
    changeDetail(areaName) {
      if (areaName == '饶阳县') {
        window.open("http://47.94.107.242:35015/vegetableScreen/#/raoyangMode?name=饶阳模式&area=131124&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxMjg1N2JjMTQ3YzI0ZDVlOGEyNThjNzVlNzY2ZmI1NSIsInVzZXJuYW1lIjoiMTg5MDAwMDAwMTAifQ.s6yJ5hMsHcOhPSexzlqd11Yl-9k9YvvT0Ha_d2TCnFU", '_blank');
      } else if (areaName == '固安县') {
        window.open("http://47.94.107.242:35015/vegetable_stats_guan/#/guanMode?name=%E5%9B%BA%E5%AE%89%E6%A8%A1%E5%BC%8F&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkMjkwNjQzZTQ2YTI0Y2EzYmMyMzhmZTQ2YWJhZjQ0YyIsInVzZXJuYW1lIjoi5Zu65a6JLeiUrOiPnCJ9.GiAjyKBO9SP17fMLFoEPUmYnWre0N22rLrsq6t6RBCg", "_blank");
      }
    },
    mapSelect(obj) {
      // 地图市区县选择
      // console.log('地图市区县选择', obj)
      this.currCityObj = obj;
      this.detailFlag = obj.name || '';
      if (obj.code || obj.code == '') {
        this.areaCode = obj.code
        if (!obj.level || obj.level == 2) {
          this.getPlatInfoByAreaCode()
          if (obj.name == '饶阳县' || obj.name == '固安县') {
            this.areaDatas = this.zzqkData.find((item) => item.areaName === obj.name)
            this.$refs.areaTipRef.style.display = 'none'
            this.$refs.areaTipRefs.style.top = obj.pageY + 'px'
            this.$refs.areaTipRefs.style.left = obj.pageX + 'px'
            this.$refs.areaTipRefs.style.display = 'block'
            setTimeout(() => {
              this.$refs.areaTipRefs.style.display = 'none'
            }, 5000)

          }
        }
        // if (obj.level && obj.level == 1) {
        this.getPlatInfoByAreaCode()
        // }
        this.getcountlist()
        this.groupCountByAreaCode()
        this.getVegetablesBaseInfo()
        this.getRecoveryCount()
        this.getHisData()
        this.getBandList()
        this.form.parentAreaCodes = obj.code;
        let list = [
          { city: '廊坊市', area: '永清县' },
          { city: '廊坊市', area: '固安县' },
          { city: '承德市', area: '围场满族蒙古族自治县' },
          { city: '唐山市', area: '乐亭县' },
          { city: '唐山市', area: '玉田县' },
          { city: '秦皇岛', area: '昌黎县' },
          { city: '保定市', area: '定兴县' },
          { city: '保定市', area: '涿州市' },
          { city: '沧州市', area: '青县' },
          { city: '唐山市', area: '滦南县' },
          { city: '衡水市', area: '饶阳县' },
          { city: '张家口市', area: '崇礼区' },
          { city: '承德市', area: '丰宁满族自治县' },
          { city: '保定市', area: '清苑区' },
          { city: '邯郸市', area: '邯郸经济技术开发区' },
          { city: '衡水市', area: '阜城县' },
          { city: '石家庄市', area: '新乐市' },
          { city: '邯郸市', area: '馆陶县' },
          { city: '保定市', area: '望都县' },
          { city: '邯郸市', area: '鸡泽县' },
        ]
        let bool = list.some(item => item.area == obj.name);
        // console.log(bool, 'bool', this.areaDatas);
        if (bool) {
          this.companyList();
          this.hideAreaTip();
        }
      }
    },
    getcountlist() {
      api
        .getcountlist({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log(res,'mianjibbbbbbbbbbbbbbbbbbb');
          this.countyTotal = res.code == 200 ? res.data && res.data.developmentCounty : 0
          this.areaTotal = res.code == 200 ? res.data && res.data.plantnum : 0
          this.varietiesTotal = res.code == 200 ? res.data && res.data.varieties : 0
          this.yieldTotal = res.code == 200 ? res.data && res.data.yield : 0
        })
    },
    groupCountByAreaCode() {
      //品种面积
      api
        .groupCountByAreaCode({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('品种面积')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let arr = []
          res.data.forEach((item) => {
            arr.push({
              value: Number(item.areacount),
              name: item.name,
              percentage: item.percentage,
            })
          })
          this.pzmjReload(arr, 'pzmj')
          this.pzmjReload(arr, 'pzmjDialog')
        })
    },
    getPlatInfoByAreaCode() {
      //种植情况
      api
        .getPlatInfoByAreaCode({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('种植情况', this.areaCode, res.data)
          let xData = []
          let dataC1 = []
          let dataC2 = []
          if (this.areaCode) {
            this.zzqkData.filter(item => item.areaCode * 1000000 == this.areaCode).forEach((item) => {
              xData.push(item.areaName)
              dataC1.push(item.plantnum)
              dataC2.push(item.comnum)
              // if (this.detailFlag) {
              //   let that = this;
              //   setTimeout(() => that.areaTotal = item.plantnum, 0);
              // }
            })
            // console.log(this.zzqkData, '1111111111111111111111111', this.areaCode);
          } else {
            this.zzqkDatas = res.data
            res.data.forEach((item) => {
              xData.push(item.areaName)
              dataC1.push(item.plantnum)
              dataC2.push(item.comnum)
              // if (this.detailFlag) {
              //   let that = this;
              //   setTimeout(() => that.areaTotal = item.plantnum, 0);
              // }
              if (this.zzqkData.length == 0) {

                this.getPlatInfoByAreaCodes(item.areaCode)
              }
            })
          }
          // console.log(dataC2,dataC1,xData)
          this.zzqkReload(xData, dataC1, dataC2, 'zzqk')
          this.zzqkReload(xData, dataC1, dataC2, 'zzqkDialog')
        })
    },
    getPlatInfoByAreaCodes(areaCode) {
      //种植情况
      api.getPlatInfoByAreaCode({ areaCode: areaCode }).then((res) => {
        res.data.forEach((item, index) => {
          item.areaName = item.areaCode == 130104000000 ? '石家庄市桥西区' : (item.areaCode == 130105000000 ? '石家庄市新华区' : (item.areaCode == 130502000000 ? '邢台市桥东区' : (item.areaCode == 130503000000 ? '邢台市桥西区' : (item.areaCode == 130702000000 ? '张家口市桥东区' : (item.areaCode == 130703000000 ? '张家口市桥西区' : (item.areaCode == 130902000000 ? '沧州市新华区' : (item.areaCode == 131101000000 ? '衡水市市辖区' : (item.areaCode == 131001000000 ? '廊坊市市辖区' : (item.areaCode == 130901000000 ? '沧州市市辖区' : (item.areaCode == 130701000000 ? '张家口市市辖区' : (item.areaCode == 130601000000 ? '保定市市辖区' : (item.areaCode == 130501000000 ? '邢台市市辖区' : (item.areaCode == 130401000000 ? '邯郸市市辖区' : (item.areaCode == 130301000000 ? '秦皇岛市市辖区' : (item.areaCode == 130201000000 ? '唐山市市辖区' : (item.areaCode == 130101000000 ? '石家庄市市辖区' : item.areaName))))))))))))))))
          this.zzqkData.push(item)
          this.zzqkDatas.push(item)
          // console.log(this.zzqkData, '222222222222222222222222', this.zzqkDatas);
        })
      })
      let xData = []
      let dataC1 = []
      let dataC2 = []
      this.zzqkData.forEach((item) => {
        xData.push(item.areaName)
        dataC1.push(item.plantnum)
        dataC2.push(item.comnum)
      })
      this.zzqkReload(xData, dataC1, dataC2, 'zzqk')
      this.zzqkReload(xData, dataC1, dataC2, 'zzqkDialog')
    },
    getVegetablesBaseInfo() {
      //县蔬菜基地明细
      api
        .getVegetablesBaseInfo({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('县蔬菜基地明细')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.scjdData = res.data || []
          clearInterval(this.scjdTimer)
          if (this.scjdData.length > 4) {
            this.scjdTimer = setInterval(this.roll, 5000)
          }
        })
    },
    getRecoveryCount() {
      //蔬菜产量
      api
        .getRecoveryCount({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('蔬菜产量')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.xscclData = res.data || []
          let dataX = []
          let data = []
          let maxData = []
          res.data.forEach((item) => {
            dataX.push(item.name)
            data.push(Number(item.count))
          })
          let maxNum = Math.max(...data)
          data.forEach((item) => {
            maxData.push(maxNum)
          })
          this.xscclReload(dataX, data, maxData, 'xsccl')
          this.xscclReload(dataX, data, maxData, 'xscclDialog')
        })
        .catch(() => {
          this.xscclReload([], [], [])
        })
    },
    getHisData(Id) {
      //品种历史产量
      api
        .getHisData({
          areaCode: this.areaCode,
          plantId: Id || '',
        })
        .then((res) => {
          // console.log('品种历史产量')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let xData = res.data.xAxis
          let legendData = res.data.legend
          let seriesData = res.data.series || []
          this.lsclReload(xData, legendData, seriesData, 'lscl')
          this.lsclReload(xData, legendData, seriesData, 'lsclDialog')
        })
    },
    getBandList() {
      //基地品牌
      api
        .getCydxBandList({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('基地品牌')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.jdppData = res.data || []
        })
    },
    pzmjInit(Init, Id) {
      //品种面积
      this[Init] = this.$echarts.init(document.getElementById(Id))
      let color = [
        '#00ffff',
        '#ff3000',
        ,
        '#00cfff',
        '#006ced',
        '#ffe000',
        '#ffa800',
        '#ff5b00',
      ]
      let option = {
        // backgroundColor: '#fff',
        color: color,
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          align: 'left',
          top: '20',
          bottom: '20',
          right: Init == 'pzmj' ? '10' : '50',
          type: 'scroll',
          pageIconColor: '#b8b8b8', //激活的分页按钮颜色
          pageIconInactiveColor: '#435f82', //没激活的分页按钮颜色
          itemWidth: 14,
          textStyle: {
            color: '#fff',
            fontSize: 16,
            align: 'right',
            rich: {
              num1: {
                color: '#00B4FF',
                fontSize: 24,
                padding: [0, 0, 0, 10],
                width: 50,
                // borderColor:'#f00',
                // borderWidth: 2 ,
                align: 'right',
              },
              num2: {
                color: '#00DEFF',
                fontSize: 24,
                padding: [0, 0, 0, 10],
                // width: 60,
                // borderColor:'#f00',
                // borderWidth: 2 ,
                // align:'right',
              },
              unit1: {
                color: '##00B4FF',
                fontSize: 12,
                padding: [0, 10, 0, 0],
              },
              text: {
                width: 60,
                color: '#fff',
                fontSize: 16,
                // borderColor:'#0f0',
                // borderWidth: 2 ,
              },
            },
          },
        },
        graphic: {
          elements: [
            {
              type: 'image',
              z: 4,
              style: {
                image: require('@/assets/images/pzmj_pie.png'),
                width: Init == 'pzmj' ? 123 : 230,
                height: Init == 'pzmj' ? 123 : 230,
              },
              left: Init == 'pzmj' ? '9%' : '7.8%',
              top: 'center',
            },
          ],
        },
        series: [
          {
            name: '品种面积',
            type: 'pie',
            radius: ['50%', '65%'],
            center: ['22%', '50%'],
            data: [
              // { value: 1048, name: '白菜' },
              // { value: 735, name: '西红柿' },
              // { value: 580, name: '黄瓜' },
              // { value: 484, name: '甘蓝类' },
              // { value: 300, name: '白萝卜' },
            ],
            itemStyle: {
              borderColor: 'rgba(14,47,82)',
              borderWidth: 5,
            },
            label: {
              show: false,
              // position: 'center'
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 0,
              lineStyle: {
                // type: 'dashed',
                width: 2,
                // color:'transparent'
              },
            },
          },
        ],
      }
      this[Init].setOption(option)
    },
    pzmjReload(arr, Init) {
      //种品种面积重载
      this[Init].setOption({
        legend: {
          formatter: (item) => {
            let option = this.pzmj.getOption()
            // const data = this.pzmj.getOption.series[0].data;
            const data = option.series[0].data
            let sum = 0
            data.forEach((item) => {
              sum += parseInt(item.value)
            })
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === item) {
                return (
                  `{text|${item}}{num1|${data[i].value}}{unit1|亩}{num2|${data[i].percentage}}{unit2|%}`
                )
              }
            }
          },
        },
        series: [
          {
            data: arr,
          },
        ],
      })
    },
    zzqkInit(Init, Id) {
      //种植情况
      this[Init] = this.$echarts.init(document.getElementById(Id))
      let legendData = ['种植面积', '企业数']
      var fontColor = '#30eee9'
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '60',
          right: '10',
          top: '40',
          bottom: '40',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
          },
          data: [],
        },
        yAxis: {
          name: '亩',
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: '#3585d5',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: Init == 'zzqk' ? 10 : 20,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,168,255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,168,255,0)',
                  },
                ],
              },
            },
            data: [],
          },
          {
            name: legendData[1],
            type: 'bar',

            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: Init == 'zzqk' ? 10 : 20,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(90,231,153,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(90,231,153,0)',
                  },
                ],
              },
            },
            data: [],
          },
        ],
      }
      this[Init].setOption(option)
      if (Init == 'zzqk') {
        this[Init].on('click', (params) => {
          // console.log(params)
          let code = ''
          for (let i = 0; i < this.zzqkDatas.length; i++) {
            if (params.name == this.zzqkDatas[i].areaName) {
              code = this.zzqkDatas[i].areaCode * 1000000
              break
            }
          }
          // console.log(JSON.parse(JSON.stringify(this.currCityObj)))
          let cityCode = this.currCityObj.code && this.currCityObj.code.slice(0, 4) + '00000000';
          let obj = {
            type: 2,
            parentInfo: { code: cityCode },
            param: {
              // companyTypeId:val,
              // market:'1',//是否是市场标记 0是；1否
              parentAreaCodes: code, //区域id 12位
            },
            title: '种植情况',
          }
          this.$refs.enterpriseList.show(obj)
        })
      }
    },
    zzqkReload(xData, dataC1, dataC2, Init) {
      //种植情况重载
      this[Init].setOption({
        xAxis: {
          data: xData,
        },
        series: [
          {
            data: dataC1,
          },
          {
            data: dataC2,
          },
        ],
      })
    },
    showAreaTip(obj) {
      // console.log(obj,'llllllllllllllllllllll');
      let data = this.zzqkData.find((item) => item.areaName === obj.name)
      if (data) {
        // console.log(data,obj.name)
        this.areaData = data
        this.$refs.areaTipRef.style.top = obj.pageY + 'px'
        this.$refs.areaTipRef.style.left = obj.pageX + 'px'
        this.$refs.areaTipRef.style.display = 'block'
      } else {
        this.areaData = {
          areaName: obj.name,
          plantnum: 0,
          comnum: 0,
        }
        this.$refs.areaTipRef.style.display = 'none'
      }
    },
    hideAreaTip() {
      this.$refs.areaTipRef.style.display = 'none';
      this.$refs.areaTipRefs.style.display = 'none';
    },
    xscclInit(Init, Id) {
      //县蔬菜产量
      this[Init] = this.$echarts.init(document.getElementById(Id))
      // let data1 = [84, 65, 72, 95, 120, 110,152]
      let data1 = []
      // let dataX = ['黄瓜','番茄', '茄子', '辣椒', '橄榄', '芹菜', '西红柿']
      let dataX = []
      let option = {
        grid: {
          left: '20',
          right: '40',
          top: '40',
          bottom: '40',
        },
        xAxis: [
          {
            data: dataX,
            show: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 16,
              color: '#B9C3D3',
              interval: 0,
            },
          },
          {
            type: 'category',
            show: true,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              interval: 0,
              verticalAlign: 'top',
              margin: 10,
              color: '#00FFFF',
              fontSize: '20',
            },
            data: data1,
          },
        ],
        yAxis: [
          {
            show: false,
          },
          {
            show: true,
            name: '单位：吨',
            nameTextStyle: {
              align: 'right',
              color: '#BBD6F9',
              padding: [0, 0, 0, 10],
              fontSize: 14,
            },
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            // 三个最低下的圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, 2],
            z: 12,
            data: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
          },
          {
            // 下半截柱状图
            name: '',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            itemStyle: {
              // lenged文本
              opacity: 0.7,
              color: (params) => {
                var a = params.name.slice(0, 2)
                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#94d8e9', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#4892f9', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            data: data1,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 12],
            symbolOffset: [0, -4],
            z: 5,
            itemStyle: {
              opacity: 1,
              color: (params) => {
                var a = params.name.slice(0, 2)

                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#559ff6', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#85ccec', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            symbolPosition: 'end',
            data: data1,
          },
          {
            name: '', //头部
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color: '#366390',
              opacity: 1,
            },
            // data: [150, 150, 150, 150, 150, 150]
            data: [],
          },
          {
            name: '',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            z: 0,
            itemStyle: {
              color: 'rgba(40,78,114,.5)',
              opacity: 0.7,
            },
            // data: [150, 150, 150, 150, 150, 150]
            data: [],
          },
        ],
      }
      this[Init].setOption(option)
      if (Init == 'xsccl') {
        this.xsccl.on('click', (params) => {
          // console.log(params)
          // console.log(JSON.parse(JSON.stringify(this.xscclData)))
          let plantId = ''
          for (let i = 0; i < this.xscclData.length; i++) {
            if (params.name == this.xscclData[i].name) {
              this.getHisData(this.xscclData[i].plant_id)
              plantId = this.xscclData[i].plant_id
              break
            }
          }
          let obj = {
            // companyTypeId:'',
            plantId, //种植品种id
            areaCode: this.currCityObj.code || 130000000000, //区划代码
          }
          this.$refs.supplyList.show(obj)
        })
      }
    },
    xscclReload(dataX, data, maxData, Init) {
      //县蔬菜产量重载
      this[Init].setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,95,182,0.5)',
          textStyle: {
            color: '#fff',
          },
          formatter: function (params) {
            return (
              params[1].marker +
              '' +
              params[1].name +
              '\n' +
              '<div>' +
              "<span style='display:inline-block;border-radius:1px;width:10px;height:10px;background-color:#007AFF;option:0.5'></span>" +
              "<span style=''> " +
              params[1].seriesName +
              ': ' +
              params[1].value +
              '\n' +
              ' </span>' +
              '</div>'
            )
          },
        },
        xAxis: [
          {
            data: dataX,
          },
          {
            data,
          },
        ],
        series: [
          {
            // 三个最低下的圆片
            itemStyle: {
              opacity: 1,
              color: (params) => {
                // var a = params.name.slice(0, 2)
                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#407dfd', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#448eff', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
          },
          {
            // 下半截柱状图
            data,
          },
          {
            data,
          },
          {
            data: maxData,
          },
          {
            data: maxData,
          },
        ],
      })
    },
    lsclInit(Init, Id) {
      //各品种历史产量
      this[Init] = this.$echarts.init(document.getElementById(Id))
      // let dataC1 = [120, 130, 160,120, 130, 160,120, 130];
      // let dataC2 = [110, 210, 501,110, 210, 501,110, 210];
      // let dataC3 = [124, 168, 452,125, 210, 365,110, 210];
      let dataC1 = []
      let dataC2 = []
      let dataC3 = []
      // let xData = ['永清', '固安', '滦南','乐亭','饶阳','肃宁','青县','定兴'];
      let xData = []
      // let legendData = ['西红柿', '黄瓜','茄子'];
      let legendData = []
      // var fontColor = '#30eee9';
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '60',
          right: '10',
          top: '40',
          bottom: '40',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
          },
          data: xData,
        },
        yAxis: {
          name: '亩',
          min: 0,
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            data: dataC1,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#FFD800',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#FFD800',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255,216,0,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,216,0,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: legendData[1],
            data: dataC2,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#00FCFF',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#00FCFF',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,252,255,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,252,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: legendData[2],
            data: dataC3,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#59A4FF',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#59A4FF',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(89,164,255,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(89,164,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      this[Init].setOption(option)
    },
    lsclReload(xData, legendData, seriesData, Init) {
      //各品种历史产量重载
      if (seriesData.length < 3) {
        for (let i = 0; i < 3; i++) {
          if (!seriesData[i]) {
            seriesData.push({ name: '', data: [] })
          }
        }
      }
      this[Init].setOption({
        legend: {
          data: legendData,
        },
        xAxis: {
          data: xData,
        },
        series: seriesData,
      })
    },
    // lookBrand(){//查看品牌列表
    //     this.brandFlag=true
    // },
    lookBrandDetail(v) {
      //查看品牌展示
      let obj = {
        name: v.name, //品牌名称
        company_name: v.company_name, //品牌企业
        number: v.number, //品牌编号
        create_time: v.create_time, //时间
        imgUrl: v.img_url, //时间
        // classify:v.classify,//类型
      }
      this.$refs.brandDetail.show(obj)
    },
    arrowClick(val) {
      //上下切换
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    roll: function () {
      //县蔬菜基地明细滚动
      this.animate = true
      setTimeout(() => {
        this.scjdData.push(this.scjdData[0])
        this.scjdData.shift()
        this.animate = false
      }, 500)
    },
    scjdEnter() {
      //县蔬菜基地明细进入
      // console.log('县蔬菜基地明细进入')
      clearInterval(this.scjdTimer)
    },
    scjdOut() {
      //县蔬菜基地明细移出
      // console.log('县蔬菜基地明细移出')
      if (this.scjdData.length > 4) {
        this.scjdTimer = setInterval(this.roll, 5000)
      }
    },
    companyList() {
      //企业列表
      companyList(this.form)
        .then((res) => {
          // console.log('企业列表', res.rows)

          let data = res.rows.map((item) => {
            return {
              value: [item.longitude, item.latitude],
              ...item,
            }
          })
          this.$refs.plantMapRef.addMarker([
            {
              name: '产业大县',
              url:
                'image://' +
                require('./../../assets/images/qyfb_marker.png'),
              size: [39, 51],
              data: data,
            },
          ])
        })
        .catch(() => { })
    },
    initPage() {
      this.detailFlag = '';
      this.areaCode = '';
      this.zzqkData = [];
      this.zzqkDatas = [];


      this.groupCountByAreaCode() //左侧种植面积
      this.getPlatInfoByAreaCode() //左侧种植情况
      this.getVegetablesBaseInfo() //左侧县蔬菜基地明细
      this.getRecoveryCount()  //右侧蔬菜产量
      this.getHisData()        //右侧品种历史产量
      this.getBandList()       //右侧基地品牌
      this.getcountlist()      //上侧个数明细
      this.zzqkInit('zzqk', 'Zzqk')
      this.zzqkInit('zzqkDialog', 'ZzqkDialog')  //种植情况弹框
      this.xscclInit('xsccl', 'Xsccl')
      this.xscclInit('xscclDialog', 'XscclDialog') //蔬菜情况弹框
      this.lsclInit('lscl', 'Lscl')
      this.lsclInit('lsclDialog', 'LsclDialog')  //历史产量弹框
      this.pzmjInit('pzmj', 'Pzmj')
      this.pzmjInit('pzmjDialog', 'PzmjDialog')  //品种面积弹框
    },
    lookDetail(data) { //查看企业详情
      this.$refs.enterpriseInfo.show(data);
    }
  },
}
</script>
<style lang='scss' scoped>
.cydx {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 30px;
  // border: 3px solid #f00;
  display: flex;
  background: url('~@/assets/images/cygk/cydx_bg.png') no-repeat center/100% 100%;

  .left {
    // border: 3px solid #0f0;
    width: 475px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;

        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }

    .middle {
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;

      .chart {
        flex: 1;
        width: 100%;
      }
    }

    .bottom {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        padding: 20px 30px;
        box-sizing: border-box;
        flex: 1;

        .wrap {
          // border:1px solid #0f0;
          height: 100%;
          overflow: hidden;

          >ul {
            // border:1px solid #0f0;
            height: 170px;
            overflow: hidden;

            >li {
              // border:1px solid #0f0;
              height: 35px;
              display: flex;
              align-items: center;
              background-color: rgba(21, 78, 149, 0.3);
              margin-bottom: 10px;
              padding: 0 30px;
              box-sizing: border-box;
              position: relative;

              span {
                font-size: 16px;
                display: inline-block;
              }

              span:nth-child(1) {
                color: #fff;
                // border:1px solid #f0f;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 1%;
              }

              span:nth-child(2) {
                color: #fff;
                // border:1px solid #0f0;
                width: 19%;
                margin-right: 1%;
              }

              span:nth-child(3) {
                color: #2cc6ff;
                // border:1px solid #0ff;
                width: 19%;
              }

              &::before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                width: 2px;
                height: 15px;
                background-color: #2cc6ff;
              }
            }
          }

          .marquee_top {
            transition: all 0.5s;
            margin-top: -40px;
          }
        }
      }
    }
  }

  .center {
    // border: 2px solid #f0f;
    flex: 1;
    margin: 0 10px;
    position: relative;

    .centerMenu {
      display: flex;
      align-items: center;
      position: absolute;
      justify-content: space-around;
      width: 100%;
      z-index: 99;
      padding: 10px;
      background: rgba(1, 46, 104, 0.5);

      .label {
        color: #aae5ed;
        font-size: 22px;
      }

      .number {
        font-size: 32px;
        margin-top: 10px;
      }

    }

    .detailBtn {
      position: absolute;
      top: 110px;
      right: 10px;
      width: 100px;
      height: 51px;
      background: url('~@/assets/images/cygk/detailBox.png') no-repeat center/100% 100%;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;
    }
  }

  .right {
    // border: 3px solid #0f0;
    width: 475px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;

        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }

    .middle {
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;

      .chart {
        flex: 1;
        width: 100%;
      }
    }

    .bottom {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 10px 50px;
        box-sizing: border-box;
        overflow: hidden;

        .wrap {
          background: url('~@/assets/images/jppp_border.png') no-repeat center/100% 100%;
          height: 200px;
          padding: 0 50px;
          box-sizing: border-box;
          position: relative;

          .inner {
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            h3 {
              font-size: 20px;
              background: url('~@/assets/images/jppp_bg.png') no-repeat center/100% 100%;
              margin-top: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            img {
              max-width: 100%;
              height: 90px;
              margin: 10px 0;
            }

            p {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }

          .left_arrow {
            left: 8px;
          }

          .right_arrow {
            right: 5px;
          }

          ::v-deep.el-carousel__button {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #25b0ff;
          }
        }
      }
    }
  }

  .zzqk_dialog {
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
