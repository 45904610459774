<template>
    <div class="list">
        <base-dialog 
            width="1000px" 
            label="供应列表"
            :show-close="true"
            v-show="dialogFlag"
            @closeDialog="closeDialog"
        >
        <div class="wrap">
            <el-form :inline="true" class="myform">
                <el-form-item label="">
                    <el-input class="pub_input" v-model="form.companyName" placeholder="企业名称" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="supplyList()" class="btn" type="primary" icon="el-icon-search"></el-button>
                </el-form-item>
            </el-form>
            <div class="table_list">
                <el-table :data="tableData" stripe class="pub_table" height="100%">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column prop="companyName" label="企业" min-width="100" align="center"></el-table-column>
                    <el-table-column prop="plantName" label="品种" width="100" align="center"></el-table-column>
                    <el-table-column prop="yield" label="数量" width="100" align="center"></el-table-column>
                    <el-table-column prop="recovery_time" label="时间" width="200" align="center"></el-table-column>
                    <!-- <el-table-column label="详情">
                        <template slot-scope="scope">
                            <span class="look">查看</span>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
            <el-pagination
                class="pub_laypage" 
                :page-size="form.pageSize"
                @current-change="supplyList"
                :current-page.sync="form.pageNum"
                prev-text="上一页"
                next-text="下一页"
                :pager-count="11"
                layout="prev, pager, next,total"
                :total="total">
            </el-pagination>
        </div>
        </base-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import {supplyList} from '@/api/dialog' //接口
export default {
    components:{BaseDialog},
    data(){
        return {
            form:{
                pageSize:15,
                pageNum:1,
                companyName:'',//企业名称模糊
            },
            tableData:[],
            total:0,
            dialogFlag:false,
        }
    },
    methods:{
        supplyList(page){//供应列表
            this.form.pageNum=page||1
            supplyList(this.form).then(res=>{
                // console.log('供应列表')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.tableData=res.rows||[]
                this.total=res.total||0
            }).catch(()=>{
                this.tableData=[]
                this.total=0
            })
        },
        closeDialog(){//关闭弹框
            this.dialogFlag=false
            this.total=0
            this.tableData=[]
            this.form={
                companyName:'',//名称
                pageNum:1,
                pageSize:15,
            }
        },
        show(obj){//
            this.dialogFlag=true
            Object.assign(this.form,obj)
            this.supplyList()
        },
    }
}
</script>

<style lang='scss' scoped>
    .list{
        // border: 1px solid #f00;
        .wrap{
            // border: 3px solid #0f0;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 20px;
            .myform{
                flex: none;
                .btn{
                    font-size: 20px;
                    padding: 0 20px;
                    height: 40px;
                }
            }
            .table_list{
                flex: 1;
                overflow: hidden;
                margin-bottom: 10px;
                .look{
                    color:#00ffff;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .pub_laypage{
                flex: none;
            }
        }
    }
</style>