<template>
    <div>
        <!-- 农资商品页面 -->
        <base-dialog 
            width="1000px" 
            label="白菜一级品"
            :show-close="true"
            v-show="dialogFlag"
            @closeDialog="closeDialog"
        >
            <div class="goods_page">
                <el-scrollbar style="height:100%;">
                    <div class="wrap">
                        <div class="info">
                            <div class="left">
                                <div class="img_wrap">
                                    <img src="@/assets/images/jppp.png" alt="">
                                    <p>白菜-454545</p>
                                </div>
                                <div class="carousel_wrap">
                                    <div class="nav_left" @click="moveCarousel(-1)" :disabled="atHeadOfList">
                                        <i class="el-icon-arrow-left"></i>
                                    </div>
                                    <div class="card_carousel">
                                        <div class="carousel_container">
                                            <div class="carousel_cards" :style="{ transform: 'translateX' + '(' + currOffset + 'px' + ')'}">
                                                <img src="@/assets/images/jppp.png" alt="" v-for="item in items" :key="item.name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nav_right" @click="moveCarousel(1)" :disabled="atEndOfList">
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <h3>白菜一级品  北京一级</h3>
                                <ul>
                                    <li>
                                        <label>价格：</label>
                                        <span>6500<i>元/公斤</i></span>
                                    </li>
                                    <li>
                                        <label>供应量：</label>
                                        <span>6500公斤</span>
                                    </li>
                                    <li>
                                        <label>产地：</label>
                                        <span>河北省巨鹿县</span>
                                    </li>
                                    <li>
                                        <label>规格：</label>
                                        <span>干货</span>
                                    </li>
                                    <li>
                                        <label>品级：</label>
                                        <span>一级</span>
                                    </li>
                                    <li>
                                        <label>联系人：</label>
                                        <span>李明</span>
                                    </li>
                                    <li>
                                        <label>电话：</label>
                                        <span>13354124872</span>
                                    </li>
                                </ul>
                                <!-- <div class="btn_wrap">我要购买</div> -->
                            </div>
                        </div>
                        <div class="detail">
                            <h3>产品介绍</h3>
                            <div class="content">
                                <p>辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因</p>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </base-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
export default {
    components:{BaseDialog},
    data(){
        return {
            currOffset: 0,
            size: 3,
            offsetWidth: 120,//偏移距离
            items: [
                {name: '标题1', tag: "内容1"},
                {name: '标题2', tag: "内容2"},
                {name: '标题3', tag: "内容3"},
                {name: '标题4', tag: "内容4"},
                {name: '标题5', tag: "内容5"},
                {name: '标题6', tag: "内容6"},
                {name: '标题7', tag: "内容7"},
            ],
            dialogFlag:false,
        }
    },
    computed: {
        atEndOfList() {
            return this.currOffset <= (this.offsetWidth * -1) * (this.items.length - this.size);
        },
        atHeadOfList() {
            return this.currOffset === 0;
        },
    },
    methods:{
        closeDialog(){//关闭弹框
            this.dialogFlag=false
        },
        show(obj){//显示
            // this.infoObj=obj
            this.dialogFlag=true
        },
        moveCarousel(direction) {
            if (direction === 1 && !this.atEndOfList) {
                this.currOffset -= this.offsetWidth;
            } else if (direction === -1 && !this.atHeadOfList) {
                this.currOffset += this.offsetWidth;
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .goods_page{
        // border: 5px solid #f00;
        padding: 35px 45px 25px;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        .wrap{
            // border: 5px solid #0f0;
            .info{
                display: flex;
                align-items: center;
                .left{
                    width: 530px;
                    .img_wrap{
                        flex: none;
                        margin: 0 auto;
                        width: 100%;
                        height: 390px;
                        font-size: 0;
                        background: url('~@/assets/images/dialog/qyxx_border.png') no-repeat center/100% 100%;
                        padding: 15px 10px 10px 18px;
                        box-sizing: border-box;
                        position: relative;
                        p{
                            position: absolute;
                            left: 18px;
                            right: 10px;
                            bottom: 10px;
                            font-size: 14px;
                            height: 35px;
                            text-align: center;
                            line-height: 35px;
                            background-color: rgba(0,0,0,.57);
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .carousel_wrap{
                        // border: 1px solid #0f0;
                        height: 100px;
                        margin-top: 10px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 20px 0 40px;
                        color: #666a73;
                        overflow: hidden;
                        .card_carousel {
                            display: flex;
                            justify-content: center;
                            width: 480px;
                            .carousel_container {
                                overflow: hidden;
                                .carousel_cards {
                                    display: flex;
                                    transition: transform 150ms ease-out;
                                    transform: translatex(0px);
                                    img {
                                        margin: 0 10px;
                                        transition: opacity 150ms linear;
                                        user-select: none;
                                        width: 100px;
                                        height: 100px;
                                        &:hover{
                                            opacity: 0.5;
                                        }
                                    }
                                    .inner:first-child {
                                        margin-left: 0;
                                    }
                                    .inner:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        .nav_left, .nav_right {
                            display: inline-block;
                            width: 30px;
                            height: 100%;
                            position: relative;
                            cursor: pointer;
                            background-color: rgba(4,63,112,.62);
                            i{
                                font-size: 30px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        .nav_left[disabled], .nav_right[disabled] {
                            opacity: 0.3;
                        }
                        .nav_left {
                            left: 0;
                        }
                        .nav_right {
                            right: 0;
                        }
                  
                    }
                }
                .right{
                    // border: 2px solid #f0f;
                    flex: 1;
                    margin-left: 50px;
                    box-sizing: border-box;
                    >h3{
                        font-size: 24px;
                        font-weight: normal;
                    }
                    >ul{
                        >li{
                            margin-top: 30px;
                            label{
                                font-size: 18px;
                            }
                            span{
                                font-size: 18px;
                                color: #10c4ff;
                            }
                        }
                        li:first-child{
                            span{
                                font-size: 36px;
                                color: #facc14;
                                i{
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                    .btn_wrap{
                        width: 280px;
                        height: 58px;
                        font-size: 22px;
                        color: #00fcff;
                        text-align: center;
                        line-height: 58px;
                        cursor: pointer;
                        margin-top: 20px;
                        background: url('~@/assets/images/dialog/wlwsb_bg.png') no-repeat center/100% 100%;
                    }
                }
            }
            .detail{
                // border: 1px solid #f00;
                margin-top: 30px;
                box-sizing: border-box;
                >h3{
                    height: 25px;
                    line-height: 25px;
                    width: 415px;
                    padding-left: 40px;
                    box-sizing: border-box;
                    font-size: 20px;
                    background: url('~@/assets/images/dialog/titlebg.png') no-repeat center/100% 100%;
                }
                .content{
                    background-image: linear-gradient(to right, rgba(1,43,119,.58), rgba(1,43,119,0));
                    padding: 25px 30px;
                    box-sizing: border-box;
                    margin-top: 20px;
                    p{

                    }
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
</style>