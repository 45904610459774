<template>
    <div class="map-wrapper" :style="{ weight: '100%', 'height': height }">
        <div class="map" id="detailPageMap"></div>
        <div class="description">
            <p>河北是中国唯一兼有高原、山地、丘陵平原、湖泊和海滨的省份，是全国重要粮棉产区、全国蔬菜产销大省、北方设施蔬菜重点省、供京津蔬菜第一大省。在保障全国尤其是京津蔬菜日常消费和应急供应中发挥着不可替代的重要作用。</p>
            <p>
                2022年，全省蔬菜播种面积1258万亩，总产量5407万吨，居全国第4位，其中设施蔬菜面积达362万亩，是全国为数不多可周年生产蔬菜的省份。在北京批发市场常年占有率在40%左右，其中7-9月张承错季菜占比达70%以上。环京津设施蔬菜产业集群成功入选国家集群建设
            </p>
        </div>
        <transition name="fade">
            <div class="jibei" v-if="jibeiFlag">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
                <div class="line"></div>
                <div class="content">
                    <div class="borderTop"></div>
                    <div class="title">冀北夏季陆地蔬菜产区</div>
                    <div>
                        <img src="@/assets/images/cygk/hlb.png" alt="">
                        <img src="@/assets/images/cygk/blb.png" alt="">
                    </div>
                    <div class="detail">
                        <p>重点县: 沽源县、隆化县、围场县、丰宁县赤城县、尚义县、张北县、平泉市</p>
                        <p>面积: 237万亩 产量: 1024万吨 产值: 354亿元面</p>
                        <p>主栽蔬菜：大白菜、生菜、胡萝卜、架豆、甘蓝白萝卜、芹菜、西兰花、彩椒、香菇</p>
                    </div>
                    <div class="borderBottom"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="huanjingjin" v-if="huanJingJinFlag">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
                <div class="line"></div>
                <div class="content">
                    <div class="borderTop"></div>
                    <div class="title">环京津冬季设施蔬菜产区</div>
                    <div>
                        <img src="@/assets/images/cygk/xihongshi.png" alt="">
                        <img src="@/assets/images/cygk/huanggua.png" alt="">
                    </div>
                    <div class="detail">
                        <p>重点县: 永清县、固安县、定兴县、州市、阜平县、清苑区、定州市、满城区、阜平县、清苑区、定州市、满城区</p>
                        <p>面积：296万亩 产量：1219万吨 产值：402亿元</p>
                        <p>主栽蔬菜：黄瓜、胡萝卜、番茄、甘蓝、茄子、赤松茸、香菇、西瓜、草莓、韭菜</p>
                    </div>
                    <div class="borderBottom"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="jizhong" v-if="jiZhongFlag">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
                <div class="line"></div>
                <div class="content">
                    <div class="borderTop"></div>
                    <div class="title">冀中南春秋设施蔬菜产区</div>
                    <div>
                        <img src="@/assets/images/cygk/yangcong.png" alt="">
                        <img src="@/assets/images/cygk/bocai.png" alt="">
                    </div>
                    <div class="detail">
                        <p>重点县：邯郸市经开区、永年区、肥乡区、馆陶县、蒂城区、新乐市、南和区、宁晋县、临西县</p>
                        <p>面积：296万亩 产量：1483万吨 产值：481亿元</p>
                        <p>主栽蔬菜：芹菜、菠菜、甘蓝、番茄、洋葱、西瓜、大黄瓜、甜瓜、辣椒、羊肚菌、金针菇、白玉菇</p>
                    </div>
                    <div class="borderBottom"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="jidong" v-if="jiDongFlag">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
                <div class="line"></div>
                <div class="content">
                    <div class="borderTop"></div>
                    <div class="title">冀东深冬温室蔬菜产区</div>
                    <div>
                        <img src="@/assets/images/cygk/lajiao.png" alt="">
                        <img src="@/assets/images/cygk/doujiao.png" alt="">
                    </div>
                    <div class="detail">
                        <p>重点县：乐亭县、丰南区、滦南县、滦州市 昌黎县、丰润区、抚宁区、遵化市</p>
                        <p>面积：253万亩 产量：1257万吨 产值：372亿元</p>
                        <p>主栽蔬菜：甜瓜、番茄、大白菜、黄瓜、辣椒、甘蓝、白萝卜、生姜、豆角、香菇</p>
                    </div>
                    <div class="borderBottom"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="hengcang" v-if="hengCangFlag">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
                <div class="line"></div>
                <div class="content">
                    <div class="borderTop"></div>
                    <div class="title">衡沧高品质蔬菜产区</div>
                    <div>
                        <img src="@/assets/images/cygk/qiezi.png" alt="">
                        <img src="@/assets/images/cygk/xigua.png" alt="">
                    </div>
                    <div class="detail">
                        <p>重点县：阜城县、青县、肃宁县、饶阳县、故城县、武邑县、献县、冀州区</p>
                        <p>面积：204万亩 产量：744万吨 产值：235亿元</p>
                        <p>主栽蔬菜：番茄、茄子、西瓜、甜瓜、黄瓜、芹菜、辣椒、食用菌</p>
                    </div>
                    <div class="borderBottom"></div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script type="text/ecmascript-6">
import hebei from '@/assets/map/areas.json'
export default {
    props: {
        height: {
            type: String,
            default: '664px',
        },
        chartOption: {
            type: Object,
            default: () => {
                return {}
            },
        },
        hasCounty: {
            type: Boolean,
            default: false,
        },
        geoData: {
            type: Array,
            default: () => [],
        },
        countySelect: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            proviceList: [
                {
                    name: '石家庄',
                    data: [[114.52, 38.05]],
                },
                {
                    name: '邢台',
                    data: [[114.48, 37.07]],
                },
                {
                    name: '唐山',
                    data: [[118.2, 39.63]],
                },
                {
                    name: '秦皇岛',
                    data: [[119.6, 39.93]],
                },
                {
                    name: '邯郸',
                    data: [[114.48, 36.62]],
                },
                {
                    name: '保定',
                    data: [[115.47, 38.87]],
                },
                {
                    name: '张家口',
                    data: [[114.88, 40.82]],
                },
                {
                    name: '承德',
                    data: [[117.93, 40.97]],
                },
                {
                    name: '沧州',
                    data: [[116.83, 38.3]],
                },
                {
                    name: '廊坊',
                    data: [[116.7, 39.52]],
                },
                {
                    name: '衡水',
                    data: [[115.68, 37.73]],
                },],
            jibeiFlag: false,
            jiZhongFlag: false,
            huanJingJinFlag: false,
            jiDongFlag: false,
            hengCangFlag: false,
        }
    },
    mounted() {
        this.initMap()
        this.setGeoSetting(this.geoData);
        this.markerProvice(this.proviceList);
        this.jibeiFlag = true;
    },
    methods: {
        initMap() {
            this.$echarts.registerMap('河北', hebei)
            this.mapChart = this.$echarts.init(document.getElementById('detailPageMap'))
            this.option = {
                tooltip: {},
                geo: [
                    {
                        map: '河北',
                        layoutCenter: ['55%', '50%'],
                        layoutSize: this.chartOption.layoutSize || '90%',
                        zlevel: 3,
                        label: {
                            normal: {
                                show: false,
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                        },
                        roam: false, //是否允许缩放
                        aspectScale: 0.8,
                        scaleLimit: {
                            min: 1,
                        },
                        // selectedMode: 'single',
                        itemStyle: {
                            normal: {
                                borderColor: '#0066FF',
                                borderWidth: 1,
                                color: '#45A5FE ', // 颜色
                            },
                        },
                        emphasis: {
                            show: false,
                            itemStyle: {
                                borderColor: 'rgba(229,185,8, 0)',
                                borderWidth: 0,
                                areaColor: {
                                    type: 'radial',
                                    x: 0.6,
                                    y: 0.6,
                                    r: 3,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(255,255,255,0)', // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,255,255,0)', // 100% 处的颜色
                                        },
                                    ],
                                    globalCoord: true,
                                },
                            },
                        },
                        tooltip: {},
                        // regions:[]   //对不同的区块进行着色
                    },
                ],
                series: [],
            }

            this.mapChart.setOption(this.option, true)
            this.mapChart.on('click', (params) => {
                console.log(params, this.geoData);
                this.geoData.forEach((item) => {
                    if (item.type == 'jibei') {
                        let index = item.area.findIndex((it) => { return it == params.name })
                        if (index != -1) {
                            this.jibeiFlag = true;
                        } else {
                            this.jibeiFlag = false;
                        }
                    } else if (item.type == 'huanjingjin') {
                        let index = item.area.findIndex((it) => { return it == params.name })
                        if (index != -1) {
                            this.huanJingJinFlag = true;
                        } else {
                            this.huanJingJinFlag = false;
                        }
                    } else if (item.type == 'jizhong') {
                        let index = item.area.findIndex((it) => { return it == params.name })
                        if (index != -1) {
                            this.jiZhongFlag = true;
                        } else {
                            this.jiZhongFlag = false;
                        }
                    } else if (item.type == 'jidong') {
                        let index = item.area.findIndex((it) => { return it == params.name })
                        if (index != -1) {
                            this.jiDongFlag = true;
                        } else {
                            this.jiDongFlag = false;
                        }
                    } else if (item.type == 'hengcang') {
                        let index = item.area.findIndex((it) => { return it == params.name })
                        if (index != -1) {
                            this.hengCangFlag = true;
                        } else {
                            this.hengCangFlag = false;
                        }
                    }
                })
            });
        },
        setGeoSetting(data) {
            let customSetting = []
            if (Array.isArray(data) && data.length) {
                data.forEach((item) => {
                    let color = '';  //区域颜色 
                    let color1 = ''; //边框颜色
                    switch (item.type) {
                        case 'jibei':
                            color = '#0FB43D';
                            color1 = '#21DE56';
                            break;
                        case 'huanjingjin':
                            color = '#A64EFF';
                            color1 = '#C286FF';
                            break;
                        case 'jizhong':
                            color = '#0047FF';
                            color1 = '#7A9FFF';
                            break;
                        case 'jidong':
                            color = '#6E56FF';
                            color1 = '#A798FF';
                            break;
                        case 'hengcang':
                            color = '#00BAC6';
                            color1 = '#13F1FF';
                            break;
                        default:
                            // 默认情况的处理
                            break;
                    }
                    item.area.forEach((it, i) => {
                        customSetting.push({
                            name: it,
                            itemStyle: {
                                borderColor: color1,
                                borderWidth: 1,
                                areaColor: color,
                            },
                            emphasis: {
                                itemStyle: {
                                    borderColor: color1,
                                    borderWidth: 1,
                                    areaColor: color,
                                }
                            },
                        })
                    })
                })
            }
            this.option.geo[0].regions = customSetting;
            this.mapChart.setOption(this.option, true);
        },
        markerProvice(data) {
            let newData = [];
            data.forEach((item) => {
                newData.push({
                    ...item,
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    zlevel: 200,
                    rippleEffect: { //涟漪特效
                        period: 4, //动画时间，值越小速度越快
                        brushType: 'stroke', //波纹绘制方式 stroke, fill
                        scale: 4 //波纹圆环最大限制，值越大波纹越大
                    },
                    symbol: 'circle',
                    symbolSize: function (val) {
                        return 10; //圆环大小
                    },
                    show: true,
                    color: '#2EF2FF',
                    label: {
                        show: true,
                        position: [20, -2],
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: 16,
                        formatter: '{a}' // 文字标签的内容格式
                    },
                    tooltip: {
                        show: false
                    }
                })
            })
            newData.push({
                name: '北京',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 200,
                rippleEffect: { //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: 'stroke', //波纹绘制方式 stroke, fill
                    scale: 4 //波纹圆环最大限制，值越大波纹越大
                },
                symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC60lEQVRYR8WVS2gTURSG/zPJnbZaF1UDtslEu9BMKG6UbroSQbRaUSgiFB+4seBCRBARhCouXBRdCSoKoqgoiJQWQbpw0UUXCgpCycSFlUwSlagVX2nnZuZIqilNTTvJpDazm3vP+f/vnnMfhBp/VGN/VAXAgAKACLC9LqQqACPo35o31lO5Z7UBCIsbDHA0IY8uOQADwgiLj9MVSMiA1zZ4bkEs7Osi+IbyAIptd21I2U+8VMEzgKGJeyDq+WPKd/WEPLhkAOPrUD/liAmA6v8CTNYpsqn1HSYrhfBUgbim7mfCgyIz296np+xHSwJgaOoACHtmmzHjcdS0uv87wFgAjUqD+EIgUQQAlk5WrmzL4EclEDMtiGui3WFe7pqsKFsI6CsVR47T5wAjbhoK0c+IKV/k42YA3jQj6gg1f5Ra3QSqnB9XpLVrw3vEigDyP8ZqrECD+hCEzipNSqczBpG1Duif8L0QUPIUGCH/KRBdBJFvUUCYbTCf0ZO5/rl68x7DWIu/g3w0AKJAVRDMGbZ5bzSdGy25bxYSHw9gzVS9GARRuzcIHq3Lyu7WDD7Ml+96ETHgj2uiH0QnKoFg5ku6KU+7PVKuAAXTmCaGiWhbORDMGI6a1vZyYssCGANURRMTRLSsHFFm/uWYsqkNsNziywKIh3w7WfFV9tw6TqeezD1dFABDE9dA1OsmVjTPfFU35TG3nLIqEAuraQKa/xFjvj49VgqOkdZNK1g1QP6NYKLnc1aXAfOhQomNkH8HiO7MvTN8Od68Pi1fLgThWgFDUy+AcHZGhDHEjnUkmsLn2cKxIFaRot4CYXdhnIHz0YR1rkoA8RpEGwH+RsDxSELeXkgwHhaHHcYVImpk8KtoQm7yDGBoaAGpKWYeUVj2RJJIufU0P/+2GWstIe4D1AG2grqJtKebMBYWvWBu1M3cZQK4HPNZ5VcMzX9SYfoaScqbngDMEBq0JLKVGM+NddNw3YTVmJeTW3OA36BMBTBHLcIWAAAAAElFTkSuQmCC",
                symbolSize: function (val) {
                    return 12; //圆环大小
                },
                show: true,
                color: '#fff',
                label: {
                    show: true,
                    position: [20, 0],
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 16,
                    formatter: '{a}' // 文字标签的内容格式
                },
                tooltip: {
                    show: false
                },
                data: [[116.3, 40.2]],
            })
            newData.push({
                name: '天津',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 200,
                rippleEffect: { //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: 'stroke', //波纹绘制方式 stroke, fill
                    scale: 4 //波纹圆环最大限制，值越大波纹越大
                },
                symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC60lEQVRYR8WVS2gTURSG/zPJnbZaF1UDtslEu9BMKG6UbroSQbRaUSgiFB+4seBCRBARhCouXBRdCSoKoqgoiJQWQbpw0UUXCgpCycSFlUwSlagVX2nnZuZIqilNTTvJpDazm3vP+f/vnnMfhBp/VGN/VAXAgAKACLC9LqQqACPo35o31lO5Z7UBCIsbDHA0IY8uOQADwgiLj9MVSMiA1zZ4bkEs7Osi+IbyAIptd21I2U+8VMEzgKGJeyDq+WPKd/WEPLhkAOPrUD/liAmA6v8CTNYpsqn1HSYrhfBUgbim7mfCgyIz296np+xHSwJgaOoACHtmmzHjcdS0uv87wFgAjUqD+EIgUQQAlk5WrmzL4EclEDMtiGui3WFe7pqsKFsI6CsVR47T5wAjbhoK0c+IKV/k42YA3jQj6gg1f5Ra3QSqnB9XpLVrw3vEigDyP8ZqrECD+hCEzipNSqczBpG1Duif8L0QUPIUGCH/KRBdBJFvUUCYbTCf0ZO5/rl68x7DWIu/g3w0AKJAVRDMGbZ5bzSdGy25bxYSHw9gzVS9GARRuzcIHq3Lyu7WDD7Ml+96ETHgj2uiH0QnKoFg5ku6KU+7PVKuAAXTmCaGiWhbORDMGI6a1vZyYssCGANURRMTRLSsHFFm/uWYsqkNsNziywKIh3w7WfFV9tw6TqeezD1dFABDE9dA1OsmVjTPfFU35TG3nLIqEAuraQKa/xFjvj49VgqOkdZNK1g1QP6NYKLnc1aXAfOhQomNkH8HiO7MvTN8Od68Pi1fLgThWgFDUy+AcHZGhDHEjnUkmsLn2cKxIFaRot4CYXdhnIHz0YR1rkoA8RpEGwH+RsDxSELeXkgwHhaHHcYVImpk8KtoQm7yDGBoaAGpKWYeUVj2RJJIufU0P/+2GWstIe4D1AG2grqJtKebMBYWvWBu1M3cZQK4HPNZ5VcMzX9SYfoaScqbngDMEBq0JLKVGM+NddNw3YTVmJeTW3OA36BMBTBHLcIWAAAAAElFTkSuQmCC",
                symbolSize: function (val) {
                    return 12; //圆环大小
                },
                show: true,
                color: '#fff',
                label: {
                    show: true,
                    position: [20, 0],
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 16,
                    formatter: '{a}' // 文字标签的内容格式
                },
                tooltip: {
                    show: false
                },
                data: [[117.2, 39.13]],
            })
            // console.log(newData, 'ppppppppppppppppppp');
            this.option.series = newData;
            this.mapChart.setOption(this.option, true);
        }
    },
}
</script>
<style scoped lang='scss'>
.map-wrapper {
    position: relative;

    .map {
        height: 100%;
        z-index: 2;
    }

    .description {
        position: absolute;
        right: 22px;
        bottom: 0px;
        width: 448px;
        height: 168px;
        z-index: 10;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #7AAFFF;
        line-height: 16px;
        -webkit-background-clip: text;
        text-indent: 2em;
    }

    @keyframes rippleEffect {
        0% {
            width: 20px;
            height: 20px;
            opacity: 1;
        }

        100% {
            width: 60px;
            height: 60px;
            opacity: 0;
        }
    }

    .jibei {
        position: absolute;
        width: 400px;
        height: 370px;
        left: 40px;
        top: 100px;
        text-align: center;

        img {
            z-index: 3;
        }

        .line {
            width: 160px;
            border-top: 2px solid #1CFFAD;
            transform: rotateZ(-20deg);
            position: absolute;
            top: 65px;
            right: 35px;
            z-index: 3;
        }

        .title {
            color: #1CFFAD;
            margin-top: 20px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .point {
            position: absolute;
            top: 10.5%;
            left: 90%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            border-radius: 50%;
            border: 2px solid #1CFFAD;
            opacity: 1;
            animation: rippleEffect 2.5s ease-out infinite;
            z-index: 5;
        }

        .point:nth-child(1) {
            animation-delay: 0s;
        }

        .point:nth-child(2) {
            animation-delay: 0.5s;
        }

        .point:nth-child(3) {
            animation-delay: 1s;
        }

        .content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 250px;
            height: 276px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;

            .borderTop {
                width: 100%;
                height: 4px;
                background: #1CFFAD;
                box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.8);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                top: 2px;
            }

            img {
                width: 106px;
                height: 62px;
            }

            img:first-child {
                margin-right: 20px;
            }

            .detail {
                padding: 10px;

                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 12px;
                }
            }

            .borderBottom {
                width: 100%;
                height: 1px;
                background: #1CFFAD;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                bottom: 2px;
            }
        }
    }

    .huanjingjin {
        position: absolute;
        width: 510px;
        height: 282px;
        left: 40px;
        top: 270px;
        text-align: center;

        img {
            z-index: 3;
        }

        .line {
            width: 212px;
            border-top: 2px solid #C994FF;
            transform: rotateZ(17deg);
            position: absolute;
            top: 33px;
            right: 40px;
            z-index: 3;
        }

        .title {
            color: #C994FF;
            margin-top: 20px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .point {
            position: absolute;
            top: 22.5%;
            left: 91%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            border-radius: 50%;
            border: 2px solid #C994FF;
            opacity: 1;
            animation: rippleEffect 2.5s ease-out infinite;
            z-index: 5;
        }

        .point:nth-child(1) {
            animation-delay: 0s;
        }

        .point:nth-child(2) {
            animation-delay: 0.5s;
        }

        .point:nth-child(3) {
            animation-delay: 1s;
        }

        .content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 262px;
            height: 282px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;

            .borderTop {
                width: 100%;
                height: 4px;
                background: #C994FF;
                box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.8);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                top: 2px;
            }

            img {
                width: 106px;
                height: 62px;
            }

            img:first-child {
                margin-right: 20px;
            }

            .detail {
                padding: 10px;

                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 12px;
                }
            }

            .borderBottom {
                width: 100%;
                height: 1px;
                background: #C994FF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                bottom: 2px;
            }
        }
    }

    .jizhong {
        position: absolute;
        width: 420px;
        height: 302px;
        left: 40px;
        top: 190px;
        text-align: center;

        img {
            z-index: 3;
        }

        .line {
            width: 290px;
            border-top: 2px solid #4378FF;
            transform: rotateZ(-115deg);
            position: absolute;
            top: 130px;
            right: -45px;
            z-index: 3;
        }

        .title {
            color: #4378FF;
            margin-top: 20px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .point {
            position: absolute;
            top: 86%;
            left: 90%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            border-radius: 50%;
            border: 3px solid #4378FF;
            opacity: 1;
            animation: rippleEffect 2.5s ease-out infinite;
            z-index: 5;
        }

        .point:nth-child(1) {
            animation-delay: 0s;
        }

        .point:nth-child(2) {
            animation-delay: 0.5s;
        }

        .point:nth-child(3) {
            animation-delay: 1s;
        }

        .content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 262px;
            height: 302px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;

            .borderTop {
                width: 100%;
                height: 4px;
                background: #4378FF;
                box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.8);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                top: 2px;
            }

            img {
                width: 106px;
                height: 62px;
            }

            img:first-child {
                margin-right: 20px;
            }

            .detail {
                padding: 10px;

                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 12px;
                }
            }

            .borderBottom {
                width: 100%;
                height: 1px;
                background: #4378FF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                bottom: 2px;
            }
        }
    }

    .jidong {
        position: absolute;
        width: 745px;
        height: 272px;
        left: 40px;
        top: 220px;
        text-align: center;

        img {
            z-index: 3;
        }

        .line {
            width: 460px;
            border-top: 2px solid #9483FF;
            transform: rotateZ(14.5deg);
            position: absolute;
            top: 60px;
            right: 33px;
            z-index: 3;
        }

        .title {
            color: #9483FF;
            margin-top: 20px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .point {
            position: absolute;
            top: 43.5%;
            left: 94.5%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            border-radius: 50%;
            border: 3px solid #9483FF;
            opacity: 1;
            animation: rippleEffect 2.5s ease-out infinite;
            z-index: 5;
        }

        .point:nth-child(1) {
            animation-delay: 0s;
        }

        .point:nth-child(2) {
            animation-delay: 0.5s;
        }

        .point:nth-child(3) {
            animation-delay: 1s;
        }

        .content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 262px;
            height: 272px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;

            .borderTop {
                width: 100%;
                height: 4px;
                background: #9483FF;
                box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.8);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                top: 2px;
            }

            img {
                width: 106px;
                height: 62px;
            }

            img:first-child {
                margin-right: 20px;
            }

            .detail {
                padding: 10px;

                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 12px;
                }
            }

            .borderBottom {
                width: 100%;
                height: 1px;
                background: #9483FF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                bottom: 2px;
            }
        }
    }

    .hengcang {
        position: absolute;
        width: 520px;
        height: 266px;
        left: 40px;
        top: 250px;
        text-align: center;

        img {
            z-index: 3;
        }

        .line {
            width: 284px;
            border-top: 2px solid #00F0FF;
            transform: rotateZ(41deg);
            position: absolute;
            top: 97px;
            right: 9px;
            z-index: 3;
        }

        .title {
            color: #00F0FF;
            margin-top: 20px;
            margin-bottom: 15px;
            font-weight: bold;
        }

        .point {
            position: absolute;
            top: 71.5%;
            left: 91.5%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            border-radius: 50%;
            border: 2px solid #00F0FF;
            opacity: 1;
            animation: rippleEffect 2.5s ease-out infinite;
            z-index: 5;
        }

        .point:nth-child(1) {
            animation-delay: 0s;
        }

        .point:nth-child(2) {
            animation-delay: 0.5s;
        }

        .point:nth-child(3) {
            animation-delay: 1s;
        }

        .content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 262px;
            height: 266px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;

            .borderTop {
                width: 100%;
                height: 4px;
                background: #00F0FF;
                box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.8);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                top: 2px;
            }

            img {
                width: 106px;
                height: 62px;
            }

            img:first-child {
                margin-right: 20px;
            }

            .detail {
                padding: 10px;

                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 12px;
                }
            }

            .borderBottom {
                width: 100%;
                height: 1px;
                background: #00F0FF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                position: absolute;
                bottom: 2px;
            }
        }
    }

    .fade-enter-active {
        transition: opacity 0.8s linear;
    }

    .fade-leave-active {
        transition: opacity 0.4s linear;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
</style>