<template>
  <div class="mapBD">
    <div class="Drag-TC"></div>
    <div id="map" class="bm-view" style="position: absolute;left: 0px;right: 0px;height: 780px;"></div>
    <div id="info" class="ol-popup">
      <div v-if="content2" id="info-content">
        <!-- {{ dataList[0].A.data.showType }} -->
        <!-- 显示多数据列表  v-if="dataList[0].A.data.showType=='示范园区'" -->
        <div class="data-zs-xx" style="width: 320px">
          <div class="qy-xx-list">
            <div style="margin: 10px; max-height: 200px; overflow: auto">

              <table class="table-list">
                <tr v-for="(item, index) in dataList" :key="index">
                  <td>{{ item.A.data.name }}</td>
                  <td @click="handleClick(item.A.data)" @mouseout="item.A.data.isshow = false">
                    查看详情
                    <div class="qy-xxtsk" v-show="item.A.data.isshow">
                      <div class="qy-yq-xx">
                        <ul v-if="item.A.data.showType == '重点项目'">
                          <li>
                            <div class="qy-data-xx">
                              <span>总投入：</span>
                              {{ item.A.data.totalInvestments }}
                              <span>{{ item.A.data.investmentUnit }}</span>
                            </div>
                          </li>
                          <li>
                            <div class="qy-data-xx">
                              <span>2022投入：</span>
                              {{ item.A.data.investments }}
                              <span>{{ item.A.data.investmentUnit }}</span>
                            </div>
                          </li>
                        </ul>
                        <ul v-if="item.A.data.showType == '示范园区'">
                          <li>
                            <div class="qy-data-xx">
                              <span>2021年规模：</span>
                              {{ item.A.data.scaleOne }}
                              <span>{{ item.A.data.scaleUnit }}</span>
                            </div>
                          </li>
                          <li>
                            <div class="qy-data-xx">
                              <span>2025年规模：</span>
                              {{ item.A.data.scaleTwo || '--' }}
                              <span>{{ item.A.data.scaleUnit }}</span>
                            </div>
                          </li>
                          <li>
                            <div class="qy-data-xx">
                              <span>2021年面积：</span>
                              {{ item.A.data.areaOne || '--' }}
                              <span>{{ item.A.data.areaUnit }}</span>
                            </div>
                          </li>
                          <li>
                            <div class="qy-data-xx">
                              <span>2025年面积：</span>
                              {{ item.A.data.areaTwo || '--' }}
                              <span>{{ item.A.data.areaUnit }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="content3" id="info-content">
        <!-- {{ dataList[0].A.data.showType }} -->
        <!-- 显示多数据列表  v-if="dataList[0].A.data.showType=='示范园区'" -->
        <div class="data-zs-xx">

          <div class="qy-name" style="font-size: 14px;font-weight: 100"
            v-show="modelContent.data.showType == '重点项目' || modelContent.data.showType == '示范园区'">
            {{ modelContent.data.name }}
          </div>
          <div class="qy-xx-list" style="padding: 6px;box-sizing: content-box">
            <div style="margin: 4px; overflow: auto">

              <ul v-if="modelContent.data.showType == '示范园区'">
                <li>
                  <div class="aa">
                    <div class="qy-data-xx">
                      <span>2021年规模：</span>
                      {{ modelContent.data.scaleOne }}
                      <span>{{ modelContent.data.scaleUnit }}</span>
                    </div>
                    <div class="qy-data-xx">
                      <span>2021年面积：</span>
                      {{ modelContent.data.areaOne || '--' }}
                      <span>{{ modelContent.data.areaUnit }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="aa" style="background: #2f4983">
                    <div class="qy-data-xx">
                      <span>2025年规模：</span>
                      {{ modelContent.data.scaleTwo || '--' }}
                      <span>{{ modelContent.data.scaleUnit }}</span>
                    </div>
                    <div class="qy-data-xx">
                      <span>2025年面积：</span>
                      {{ modelContent.data.areaTwo || '--' }}
                      <span>{{ modelContent.data.areaUnit }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="modelContent.data.showType == '重点项目'">
                <li>
                  <div class="aa">
                    <div class="qy-data-xx">
                      <span>总投入：</span>
                      {{ modelContent.data.totalInvestments }}
                      <span>{{ modelContent.data.investmentUnit }}</span>
                    </div>
                    <div class="qy-data-xx">
                      <span>2022投入：</span>
                      {{ modelContent.data.investments }}
                      <span>{{ modelContent.data.investmentUnit }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="modelContent.data.showType == '龙头企业'">
                <li>
                  <div class="aa">
                    <div class="qy-data-xx">
                      {{ modelContent.data.name }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="k-Mask" v-if="content"></div>
    <div v-if="content" class="ol-popup2">
      <div id="info-content">
        <!-- v-if="modelContent.data.showType=='示范园区'" -->
        <div class="data-zs-xx" style="padding-bottom: 20px">
          <div class="qy-name" style="font-size: 14px;font-weight: 100">
            {{ modelContent.data.name }}
            <span @click="offModel"></span>
          </div>
          <div class="qy-xx" style="width:96%;">
            <ul v-if="modelContent.data.showType == '重点项目'">
              <li style="width: 100%;">
                <div class="aa">

                  <div class="qy-data-xx" style="width: 300px;">
                    <span>总投入：</span>
                    {{ modelContent.data.totalInvestments }}
                    <span>{{ modelContent.data.investmentUnit }}</span>
                  </div>
                  <div class="qy-data-xx">
                    <span>2022投入：</span>
                    {{ modelContent.data.investments }}
                    <span>{{ modelContent.data.investmentUnit }}</span>
                  </div>
                </div>
              </li>
              <li>
              </li>
            </ul>
            <ul v-if="modelContent.data.showType == '示范园区'">
              <li>
                <div class="qy-data-xx">
                  <span>2021年规模：</span>
                  {{ modelContent.data.scaleOne }}
                  <span>{{ modelContent.data.scaleUnit }}</span>
                </div>
              </li>
              <li>
                <div class="qy-data-xx">
                  <span>2025年规模：</span>
                  {{ modelContent.data.scaleTwo || '--' }}
                  <span>{{ modelContent.data.scaleUnit }}</span>
                </div>
              </li>
              <li>
                <div class="qy-data-xx">
                  <span>2021年面积：</span>
                  {{ modelContent.data.areaOne || '--' }}
                  <span>{{ modelContent.data.areaUnit }}</span>
                </div>
              </li>
              <li>
                <div class="qy-data-xx">
                  <span>2025年面积：</span>
                  {{ modelContent.data.areaTwo || '--' }}
                  <span>{{ modelContent.data.areaUnit }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Enterprise-info ref="enterpriseInfo"></Enterprise-info>
  </div>
</template>

<script>
import axios from 'axios'
// import {
//   queryBrand,
//   queryCulture,
//   queryDemoPark,
//   queryFarmland,
//   queryFarmlandinfo,
//   queryInfo,
//   queryKeyProject,
//   queryMulch,
//   queryPark,
//   queryTapOperation,
//   queryThings,
//   queryGettag,
//   queryCompanylist,
// } from '@/api/layerInterface/index'
// import layer from '@/views/common/layer'
import * as api from '@/api/cygk' //接口
import EnterpriseInfo from '@/components/enterprise' //企业信息
export default {
  // mixins: [layer],
  components: { EnterpriseInfo },
  name: 'chaoTu',
  data() {
    return {
      processEnv: process.env.VUE_APP_STATUS_NAME,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      pageStatus: '首页',
      VideoData: undefined,
      VideoShow: false,
      layerShow: true, //首页
      plantingShow: false, //种植业
      productionSubject: false, //生产主体
      checkAll: false,
      dataListShow: false,
      dataList: [],
      checkedCities: [],
      genbaoShow: '1',
      genbaoData: {},
      zhutiData: {},
      formScreened: {
        areaCode: undefined,
        industry: undefined,
        muYield: undefined,
        plantArea: undefined,
        yield: undefined,
        type: undefined,
        variety: undefined,
      },
      radioScreened: {
        plantArea: undefined,
        yield: undefined,
      },
      zidingyi: {
        plantArea: undefined,
        yield: undefined,
      },
      showType: '',
      myChart: null,
      level: '省',
      timerss: null,
      valid: true,
      map: null, // 地图
      marker: null, // 标注
      formZZGM: {
        dayu: 0,
        xiaoyu: 100,
      },
      formZCL: {
        dayu: 0,
        xiaoyu: 100,
      },
      formMCL: {
        dayu: 0,
        xiaoyu: 100,
      },
      content3: false,
      mapCoordinate: [
        {
          name: '石家庄市',
          value: [114.52, 38.05],
          lng: '114.52',
          lat: '38.05',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/shijiazhuang.png'),
        },
        {
          name: '邢台市',
          value: ['114.48', '37.07'],
          lng: '114.48',
          lat: '37.07',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/xingtai.png'),
        },
        {
          name: '唐山市',
          value: ['118.2', '39.63'],
          lng: '118.2',
          lat: '39.63',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/tangshan.png'),
        },
        {
          name: '秦皇岛市',
          value: ['119.6', '39.93'],
          lng: '119.6',
          lat: '39.93',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/qinhuangdao.png'),
        },
        {
          name: '邯郸市',
          value: ['114.48', '36.62'],
          lng: '114.48',
          lat: '36.62',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/handan.png'),
        },
        {
          name: '保定市',
          value: ['115.47', '38.87'],
          lng: '115.47',
          lat: '38.87',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/baoding.png'),
        },
        {
          name: '张家口市',
          value: ['114.88', '40.82'],
          lng: '114.88',
          lat: '40.82',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/zhangjiakou.png'),
        },
        {
          name: '承德市',
          value: ['117.93', '40.97'],
          lng: '117.93',
          lat: '40.97',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/chengde.png'),
        },
        {
          name: '沧州市',
          value: ['116.83', '38.3'],
          lng: '116.83',
          lat: '38.3',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/cangzhou.png'),
        },
        {
          name: '廊坊市',
          value: ['116.7', '39.52'],
          lng: '116.7',
          lat: '39.52',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/langfang.png'),
        },
        {
          name: '衡水市',
          value: ['115.68', '37.73'],
          lng: '115.68',
          lat: '37.73',
          showType: '城市名称',
          mapImg: require('@/assets/images/index/hengshui.png'),
        },
      ],
      huanjingOption: [],
      map: null,
      // 最低图
      layer: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 3,
      }),
      // 雄安三县
      layer2: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 10,
      }),
      // 中药材
      layer3: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质小麦
      layer4: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质谷子
      layer5: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质油料
      layer6: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      //  沙地梨
      layer7: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      //  优质食用菌
      layer8: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质葡萄
      layer9: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 金品蔬菜
      layer10: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 山地苹果
      layer11: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质生猪
      layer12: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质肉牛
      layer13: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质羊肉
      layer14: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 优质蛋鸡
      layer15: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 高端乳品
      layer16: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),
      // 特色水产
      layer17: new ol.layer.Vector({
        projection: 'EPSG:4326',
        style: null,
        source: null,
        zIndex: 11,
      }),

      vectorSourceCity: new ol.source.Vector(), //城市名称
      vectorSource: new ol.source.Vector(), //
      vectorSource2: new ol.source.Vector(), // 示范园区
      vectorSource7: new ol.source.Vector(), // 重点项目
      vectorSource3: new ol.source.Vector(), // 龙头企业
      vectorSource4: new ol.source.Vector(), // 产业园
      vectorSource5: new ol.source.Vector(), // 生产基地
      vectorSource6: new ol.source.Vector(), // 产业分布

      vectorSource8: new ol.source.Vector(), // 3D
      vectorSource9: new ol.source.Vector(), // VR
      vectorSource10: new ol.source.Vector(), // 普通园区
      vectorSource11: new ol.source.Vector(), // 地膜

      vectorSource12: new ol.source.Vector(), // 物联网
      vectorSource13: new ol.source.Vector(), // 视频监控分布
      vectorSource14: new ol.source.Vector(), // 环境监控

      vectorSource15: new ol.source.Vector(), // 两品一标
      vectorSource16: new ol.source.Vector(), // 绿色食品
      vectorSource17: new ol.source.Vector(), // 有机食品
      vectorSource18: new ol.source.Vector(), // 地标

      vectorSource19: new ol.source.Vector(), // 耕保监测点分布

      vectorSource20: new ol.source.Vector(), // 生产主体 种植
      vectorSource21: new ol.source.Vector(), // 生产主体 养殖植(优质蛋鸡)
      vectorSource22: new ol.source.Vector(), // 生产主体 养殖植(优质肉羊)
      vectorSource23: new ol.source.Vector(), // 生产主体 养殖植(优质肉牛)
      vectorSource24: new ol.source.Vector(), // 生产主体 养殖植(优质生猪)
      vectorSource25: new ol.source.Vector(), // 生产主体 养殖植(高度乳品)

      vectorSource26: new ol.source.Vector(), // 产业链企业
      vectorSource27: new ol.source.Vector(), // 土壤肥力 监测点
      vectorSource28: new ol.source.Vector(), // 土壤肥力 热力图

      vectorSourceClick: new ol.source.Vector(),
      themeSource: null, //图形
      overlay: null,
      content: false,
      content2: false,
      level: '',
      url: '',
      moveDataelse: {
        x: null,
        y: null,
      },
      select: null,
      currentRome: undefined,
      menuName: '',
    }
  },
  mounted() {
    this.inif()
    this.pageStatus = sessionStorage.getItem('pageStatus')
    this.menuName = this.$route.query.menuName
    this.$bus.$on('navAddCyLayer', this.navAddCyLayer)
    // 图层全选
    this.$bus.$on('handleCheckAllChange', this.handleCheckAllChange)
    // 图层单独选中
    this.$bus.$on('handleCheckedChange', this.handleCheckedChange)
    // 资源图层选中
    this.$bus.$on('handleCheckAllChange2', this.handleCheckAllChange2)
    // this.demonstrationpark();
    // this.keyproject();
    // this.listNoPage();
  },
  beforeDestroy() {
    //收尾操作，销毁
    this.$bus.$off('handleCheckedChange') //$off解绑当前组件所用到的事件
  },
  methods: {
    //示范园区
    demonstrationpark() {
      let that = this
      api.demonstrationpark({}).then((res) => {
        // features[i] = new ol.Feature(
        //     new ol.geom.Point([Number(data[i].lng), Number(data[i].lat)])
        //   )
        //   features[i].setProperties({
        //     location: { x: data[i].lng, y: data[i].lat },
        //     address: data[i].name,
        //     data: data[i],
        //     score: i,
        //     filters: [data[i].name],
        //     src: data[i].mapImg,
        //   })
        let { data } = res
        data.forEach((res2) => {
          res2.mapImg = require('@/assets/images/cygk/sfyq_icon.png')
          res2.lat = res2.latitude
          res2.lng = res2.longitude
          // res2.mapImg = res2.mapImg
          res2.showType = '示范园区'
          res2.isshow = false
        })
        that.insertIcon(data, that.vectorSource2)
      })
    },
    keyproject() {
      let that = this
      api.keyproject({}).then((res) => {
        let { data } = res
        data.forEach((res2) => {
          res2.mapImg = require('@/assets/images/cygk/zdxm_icon.png')
          res2.lat = res2.latitude
          res2.lng = res2.longitude
          // res2.mapImg = res2.mapImg
          res2.showType = '重点项目'
          res2.isshow = false
        })
        that.insertIcon(data, that.vectorSource7)
      })
    },
    listNoPage() {
      let that = this
      api
        .listNoPage({
          companyTypeId: 25,
        })
        .then((res) => {
          let { data } = res
          data.forEach((res2) => {
            res2.mapImg = require('@/assets/images/cygk/ltqy_icon.png')
            res2.lat = res2.latitude
            res2.lng = res2.longitude
            // res2.mapImg = res2.mapImg
            res2.showType = '龙头企业'
            res2.isshow = false
          })
          console.log(data)
          that.insertIcon(data, that.vectorSource3)
        })
    },
    // 进入二级页插入图层  清楚原来数据重新赋值
    isIndustryQcTwoYM() { },

    // 筛查接口   养殖
    queryCulture(form, vectorSource) {
      var that = this
      queryCulture(form).then((response) => {
        let data = response.data
        data.forEach((res2) => {
          res2.mapImg = require('@/assets/images/index/map/ioin/shengcanzhuti.png')
          res2.showType = '养殖主体'
        })

        that.insertIcon(data, vectorSource)
      })
    },
    handleCheckAllChange(checkAll, list) {
      this.checkAll = checkAll
      if (this.checkAll) {
        list.forEach((res) => {
          this.map.removeLayer(this[res.layerName])
        })
        list.forEach((res) => {
          res.checked = true
          this.handleCheckedChange(res)
        })
      } else {
        list.forEach((res) => {
          res.checked = false
          this.map.removeLayer(this[res.layerName])
        })
      }
    },
    handleCheckedChange(value) {
      console.log(value, 'value=======')
      let that = this
      if (value.checked) {
        that.insertLayerCYY(value.layerSrc, that[value.layerName], value.color)
        if (value.name == '示范园区') {
          that.demonstrationpark()
        } else if (value.name == '重点项目') {
          that.keyproject()
        } else if (value.name == '龙头企业') {
          that.listNoPage()
        }
      } else if (!value.checked) {
        that.map.removeLayer(that[value.layerName])
        if (value.name == '示范园区') {
          that.vectorSource2.clear()
        } else if (value.name == '重点项目') {
          that.vectorSource7.clear()
        } else if (value.name == '龙头企业') {
          that.vectorSource3.clear()
        }
      }
    },
    // 点击页面导航添加产业图层
    navAddCyLayer(name) { },

    handleCheckAllChange2(value) {
      if (value.name == '示范园区' && !value.checked) {
        this.vectorSource2.clear()
      } else if (value.name == '龙头企业' && !value.checked) {
        this.vectorSource3.clear()
      } else if (value.name == '产业园' && !value.checked) {
        this.vectorSource4.clear()
        this.vectorSource8.clear()
        this.vectorSource9.clear()
        this.vectorSource10.clear()
      }
    },
    handleCheckedChange2(value) {
      console.log(value)
      if (value.name == '产业园') {
        this.vectorSource4.clear()
        this.vectorSource8.clear()
        this.vectorSource9.clear()
        this.vectorSource10.clear()
        let form = {
          areaCode: this.formMapData.adcode,
        }
        this.interfaceDockingCyy(value, form)
      }
    },
    //  移动
    mouseDownHandleelse(event) {
      this.moveDataelse.x = event.pageX - this.$refs.kongtiao.offsetLeft
      this.moveDataelse.y = event.pageY - this.$refs.kongtiao.offsetTop
      event.currentTarget.style.cursor = 'move'
      window.onmousemove = this.mouseMoveHandleelse
    },
    mouseMoveHandleelse(event) {
      let moveLeft = event.pageX - this.moveDataelse.x + 'px'
      let moveTop = event.pageY - this.moveDataelse.y + 'px'
      this.$refs.kongtiao.style.left = moveLeft
      this.$refs.kongtiao.style.top = moveTop
    },
    mouseUpHandleelse(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
      console.log('鼠标松开了')
    },
    //  移动
    mouseDownHandleelse2(event) {
      this.moveDataelse.x = event.pageX - this.$refs.kongtiao2.offsetLeft
      this.moveDataelse.y = event.pageY - this.$refs.kongtiao2.offsetTop
      event.currentTarget.style.cursor = 'move'
      window.onmousemove = this.mouseMoveHandleelse2
    },
    mouseMoveHandleelse2(event) {
      let moveLeft = event.pageX - this.moveDataelse.x + 'px'
      let moveTop = event.pageY - this.moveDataelse.y + 'px'
      this.$refs.kongtiao2.style.left = moveLeft
      this.$refs.kongtiao2.style.top = moveTop
    },
    mouseUpHandleelse2(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
      console.log('鼠标松开了')
    },

    // 用于 产业园  两品一标  物联网
    interfaceDockingCyy(res, form) {
      var that = this
      if (res.name == '产业园') {
        res.children.forEach((res2) => {
          if (res2.name == '普通园区' && res2.checked) {
            let form2 = {
              areaCode: form.areaCode,
              industry: undefined,
              type: res2.type,
            }
            queryPark(form2).then((response) => {
              let chanyeyuan = response.data
              chanyeyuan.forEach((res3) => {
                res3.mapImg = res2.mapImg
                res3.showType = '普通园区'
              })
              that.insertIcon(chanyeyuan, that.vectorSource10)
            })
          }
        })
      }
    },
    inif() {
      var that = this
      var container = document.getElementById('info')
      that.overlay = new ol.Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 350,
        },
        offset: [10, -10],
      })
      let style = new ol.style.Style({
        fill: new ol.style.Fill({
          //矢量图层填充颜色，以及透明度
          color: 'rgba(254,181,0,1)',
        }),
        stroke: new ol.style.Stroke({
          //边界样式
          color: 'rgba(254,181,0,1)',
          width: 1,
        }),
        text: new ol.style.Text({
          //文本样式
          font: '12px Calibri,sans-serif',
          fill: new ol.style.Fill({
            color: '#000',
          }),
          stroke: new ol.style.Stroke({
            color: '#fff',
            width: 3,
          }),
        }),
      })
      var highlightLayer = new ol.layer.Vector({
        source: that.vectorSourceClick,
        zIndex: 10,
        style: new ol.style.Style({
          fill: new ol.style.Fill({
            //矢量图层填充颜色，以及透明度
            color: 'rgba(254,181,0,1)',
          }),
          stroke: new ol.style.Stroke({
            //边界样式
            color: 'rgba(254,181,0,1)',
            width: 1,
          }),
          text: new ol.style.Text({
            //文本样式
            font: '12px Calibri,sans-serif',
            fill: new ol.style.Fill({
              color: '#000',
            }),
            stroke: new ol.style.Stroke({
              color: '#fff',
              width: 3,
            }),
          }),
        }),
      })

      that.map = new ol.Map({
        target: 'map',
        controls: ol.control
          .defaults({ attributionOptions: { collapsed: false } })
          .extend([new ol.supermap.control.Logo()]),
        view: new ol.View({
          center: [116.85, 39.79],
          zoom: 7.5,
          projection: 'EPSG:4326',
          multiWorld: true,
        }),
        layers: [
          new ol.layer.Tile({
            source: new ol.source.Tianditu({
              layerType: 'img',
              key: '1d109683f4d84198e37a38c442d68311',
            }),
          }),
          // new ol.layer.Tile({
          //   zIndex: 20,
          //   source: new ol.source.Tianditu({
          //     layerType: 'img',
          //     zIndex: 10,
          //     isLabel: true,
          //     key: '1d109683f4d84198e37a38c442d68311',
          //   }),
          // }),
          // new ol.layer.Tile({
          //   source: new ol.source.TileSuperMapRest({
          //     zoom: 7.5,
          //     // url: 'http://10.32.7.131:8090/iserver/services/map-zxytiles-hbzxyimg0151/rest/maps/hb-zxy-img-0-15',
          //     url: 'http://10.32.7.131:8090/iserver/services/map-hbs_rost_level_2019/rest/maps/rost_at_level_2019'
          //   }),
          //   projection: 'EPSG:4326'
          // }),
          // new ol.layer.Tile({
          //   source: new ol.source.SuperMapCloud()
          // })
        ],
        overlays: [that.overlay],
        interactions: new ol.interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      })
      // http://10.32.7.131:8090/iserver/services/map-zxytiles-hbzxyimg0151/rest/maps/hb-zxy-img-0-15.ol3
      // that.map.getTargetElement().style.cursor = 'pointer';  // 修改鼠标样式
      // 监听singleclick事件
      that.map.on('singleclick', function (e) {
        // if (that.map.hasFeatureAtPixel(e.pixel)) {
        //   var feature = that.map.getFeaturesAtPixel(e.pixel);
        //   let zoom = that.map.getView().getZoom();
        //   let pixel = that.map.getEventPixel(e.originalEvent);
        //   that.map.forEachFeatureAtPixel(pixel, function (feature) {
        //     let coodinate = e.coordinate;
        //     //引用区域高亮方法
        //     addHighlightLayer(feature);
        //   });
        //   function addHighlightLayer (feature) {
        //     //每次执行点击事件需清除之前的
        //     that.vectorSourceClick.clear();
        //     console.log(feature);
        //     //引入中国地图的geojson格式数据
        //     let jsounUrl = '';
        //     if (that.level == '省') {
        //       jsounUrl = '../data.json';
        //     } else {
        //       jsounUrl = '../geo/' + feature.A.parent.adcode + '.json';
        //     }
        //     $.getJSON(jsounUrl, function (data) {
        //       var features = (new ol.format.GeoJSON()).readFeatures(data);
        //       features.forEach(function (element) {
        //         let jsonName = element.get("name");
        //         //如果点击的区域名称等于jeojson数据中的名称，则高亮显示
        //         if (jsonName === feature.A.name) {
        //           that.vectorSourceClick.addFeature(element);
        //         }
        //       });
        //     });
        //   }
        // } else {
        //   that.vectorSourceClick.clear();
        // }
      })
      that.map.on('dblclick', function (e) {
        if (that.map.hasFeatureAtPixel(e.pixel)) {
          var feature = that.map.getFeaturesAtPixel(e.pixel)
          if (feature[0].A.adcode) {
            that.isIndustryQcTwoYM() //清楚产业图层
            that.$bus.$emit('isIndustry2')
            that.$bus.$emit('getAreaCode', feature[0].A.adcode)
            that.insertLayerTwo(
              window.location.origin + '/geo/' + feature[0].A.adcode + '.json',
              feature[0].A
            )
          }
        }
        // var pixel = that.map.getEventPixel(e.originalEvent);
        // var geocoder = new BMapGL.Geocoder();
        // var point = new BMapGL.Point(e.coordinate[0], e.coordinate[1]);
        // geocoder.getLocation(point, function (geocoderResult, LocationOptions) {
        //   let City = that.mapCoordinate.map(time => time.name);
        //   if (City.indexOf(geocoderResult.addressComponents.city) != -1) {
        //     that.level = '市';
        //     that.insertLayerCity(e.coordinate);
        //   }
        // });
      })

      that.map.on('pointermove', (e) => {
        let pixel = that.map.getEventPixel(e.originalEvent)
        let feature = that.map.forEachFeatureAtPixel(pixel, (feature) => {
          return feature
        })
        // 线、面要素不做鼠标移入样式修改
        if (
          feature == undefined ||
          feature.getGeometry().getType() != 'Point'
        ) {
          that.map.getTargetElement().style.cursor = 'auto'
        } else {
          that.map.getTargetElement().style.cursor = 'pointer'

          if (that.timerss) {
            clearTimeout(that.timerss)
          }
          that.timerss = setTimeout(() => {
            that.moveClick(feature)
          }, 500)
          // if (!that.valid) {
          //   return false
          // } else {
          //   that.valid = false
          //   setTimeout(() => {
          //     that.moveClick(feature)
          //     that.valid = true
          //   }, 1000)
          // }
        }
      })

      //定义select控制器，点击标注后的事件
      that.select = new ol.interaction.Select()
      //map加载该控件，默认是激活可用的
      that.map.addInteraction(that.select)
      that.ioinClick()

      // that.insertLayer('../data.json', '省');
      that.insertLayer(window.location.origin + '/data.json', '省')
      that.insertIcon2(that.mapCoordinate, that.vectorSourceCity)
    },
    //func是需要进行防抖的函数，delay是防抖的等待时间
    debounce(func, delay) {
      //设定一个定时器
      let timer = null;
      //返回进行防抖处理的函数
      return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    // 首次插入图层
    insertLayer(url, level) {
      var style = new ol.style.Style({
        fill: new ol.style.Fill({
          //矢量图层填充颜色，以及透明度
          color: 'rgba(0, 55, 196, 0.4)',
        }),
        stroke: new ol.style.Stroke({
          //边界样式
          color: '#00ddff',
          width: 3,
        }),
        text: new ol.style.Text({
          //文本样式
          font: '12px Calibri,sans-serif',
          fill: new ol.style.Fill({
            color: '#000',
          }),
          stroke: new ol.style.Stroke({
            color: '#fff',
            width: 3,
          }),
        }),
      })
      var that = this
      that.vectorSourceClick.clear()
      this.formMapData = {
        adcode: '13000000',
      }
      that.url = url
      that.layer2.setStyle(style)
      // that.layer.setSource(new ol.source.Vector({
      //   url: that.url,     // 地图来源 使用本地url 离线加载会报跨域问题
      //   format: new ol.format.GeoJSON(),    // 解析矢量地图的格式化类
      // }));
      that.layer2.setSource(
        new ol.source.Vector({
          url: that.url, // 地图来源 使用本地url 离线加载会报跨域问题
          format: new ol.format.GeoJSON(), // 解析矢量地图的格式化类
        })
      )

      that.map.removeLayer(that.layer2)
      // that.map.addLayer(that.layer);
      that.map.addLayer(that.layer2)
      that.level = level
      // layer.setVisible(false);   隐藏地图图层
      // view.animate({zoom: 11}, {center: [116.383572, 39.914714]});

      that.map.getView().animate({
        // 只设置需要的属性即可
        // center: [116.383572, 39.914714], // 中心点
        center: [116.85, 39.79],
        zoom: 7.1, // 缩放级别
        // rotation: undefined, // 缩放完成view视图旋转弧度
        duration: 1000, // 缩放持续时间，默认不需要设置
      })
      let form = {
        areaCode: '13000000',
      }
      // that.interfaceDocking(form); // 页面首次加载查询接口
    },
    insertLayerCYY(url, layer, color) {
      var that = this
      let style = new ol.style.Style({
        fill: new ol.style.Fill({
          //矢量图层填充颜色，以及透明度
          color: color,
        }),
        stroke: new ol.style.Stroke({
          //边界样式
          color: color,
          width: 1,
        }),
        text: new ol.style.Text({
          //文本样式
          font: '12px Calibri,sans-serif',
          fill: new ol.style.Fill({
            color: '#000',
          }),
          stroke: new ol.style.Stroke({
            color: '#fff',
            width: 1,
          }),
        }),
      })
      layer.setStyle(style)
      layer.setSource(
        new ol.source.Vector({
          url: url, // 地图来源 使用本地url 离线加载会报跨域问题
          format: new ol.format.GeoJSON(), // 解析矢量地图的格式化类
        })
      )
      that.map.addLayer(layer)

      // that.layer3.setStyle(style2);
      // that.layer3.setSource(new ol.source.Vector({
      //   url: '../zyc.json',     // 地图来源 使用本地url 离线加载会报跨域问题
      //   format: new ol.format.GeoJSON(),    // 解析矢量地图的格式化类
      // }));
      // that.map.addLayer(that.layer3);
    },
    // 点击插入二级  三级 图层
    insertLayerTwo(url, data) {
      let that = this
      that.vectorSourceClick.clear()
      var style = new ol.style.Style({
        fill: new ol.style.Fill({
          //矢量图层填充颜色，以及透明度
          color: 'rgba(0, 55, 196, 0.3)',
        }),
        stroke: new ol.style.Stroke({
          //边界样式
          color: '#00ddff',
          width: 3,
        }),
        text: new ol.style.Text({
          //文本样式
          font: '12px Calibri,sans-serif',
          fill: new ol.style.Fill({
            color: '#000',
          }),
          stroke: new ol.style.Stroke({
            color: '#fff',
            width: 3,
          }),
        }),
      })

      if (data.level == 'city') {
        that.mapData = data
      }
      that.formMapData = data
      that.map.removeLayer(that.layer2)
      that.url = url
      // that.layer = new ol.layer.Vector({
      //   projection: 'EPSG:4326',
      //   style: style,
      //   source: new ol.source.Vector({
      //     url: that.url,     // 地图来源 使用本地url 离线加载会报跨域问题
      //     format: new ol.format.GeoJSON(),    // 解析矢量地图的格式化类
      //   }),
      // });
      that.layer2.setStyle(style)
      that.layer2.setSource(
        new ol.source.Vector({
          url: that.url, // 地图来源 使用本地url 离线加载会报跨域问题
          format: new ol.format.GeoJSON(), // 解析矢量地图的格式化类
        })
      )
      // that.map.removeLayer(that.layer2);
      that.map.addLayer(that.layer2)
      that.level = data.level
      // layer.setVisible(false);   隐藏地图图层
      // view.animate({zoom: 11}, {center: [116.383572, 39.914714]});

      that.map.getView().animate({
        // 只设置需要的属性即可
        center: data.centroid, // 中心点
        zoom: data.level == 'district' ? 10 : 9, // 缩放级别
        rotation: undefined, // 缩放完成view视图旋转弧度
        duration: 1000, // 缩放持续时间，默认不需要设置
      })
      let form = {
        areaCode: data.adcode,
      }
      // that.interfaceDocking(form); // 页面首次加载查询接口
    },

    // 插入图标
    insertIcon(data, vectorSource) {
      console.log(process)
      var that = this
      // 插入图标
      that.vectorLayer = new ol.layer.Vector({
        source: vectorSource,
        zIndex: 9999,
      })

      // that.vectorLayer.setSource(vectorSource);
      // that.map.addLayer(that.vectorLayer);

      var clusterSource = new ol.source.Cluster({
        distance: 40,
        // source: new ol.source.Vector(),
        source: vectorSource,
        // wrapX: false
      })
      var clusterLayer = new ol.layer.Vector({
        name: 'Cluster',
        source: clusterSource,
        animationDuration: 700,
        zIndex: 9999,
        style: getStyle,
      })
      that.map.addLayer(clusterLayer)
      function addFeatures() {
        var features = []
        for (var i = 0; i < data.length; ++i) {
          features[i] = new ol.Feature(
            new ol.geom.Point([Number(data[i].lng), Number(data[i].lat)])
          )
          features[i].setProperties({
            location: { x: data[i].lng, y: data[i].lat },
            address: data[i].name,
            data: data[i],
            score: i,
            filters: [data[i].name],
            src: data[i].mapImg,
          })
          features[i].setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                anchor: [0.2, 0.5],
                scale: 1.0,
                src: data[i].mapImg,
                transparent: true,
              }),
            })
          )
          features[i].set('id', i)
        }
        clusterSource.getSource().clear()
        clusterSource.getSource().addFeatures(features)
      }

      addFeatures()

      function getStyle(feature) {
        var styleCache = {}
        var size = feature.get('features').length
        if (size > 0) {
          var style = styleCache[size]
          if (!style) {
            style = [
              new ol.style.Style({
                image: new ol.style.Icon({
                  anchor: [0.2, 0.5],
                  scale: 1.0,
                  src: feature.get('features')[0].A.data.mapImg,
                }),
                text: new ol.style.Text({
                  text: size.toString(),
                  offsetX: 25,
                  offsetY: 10, //调整相对位置
                  fill: new ol.style.Fill({
                    color: '#fff',
                  }),
                }),
              }),
            ]
          }
          return style
        }
      }
      var features = []
      data.map(function (item, index) {
        var feature = new ol.Feature()
        feature.setGeometry(new ol.geom.Point([item.lng, item.lat]))
        feature.setProperties({
          location: { x: item.lng, y: item.lat },
          address: item.name,
          data: item,
          score: index,
          filters: [item.name],
          src: item.mapImg,
        })
        feature.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.2, 0.5],
              scale: 1.0,
              src: item.mapImg,
              // transparent: true
            }),
          })
        )
        features.push(feature)
      })
      // 事件绑定
      // vectorSource.on("mousemove", function (e) {
      //   console.log('鼠标移入');
      //   that.content = true;
      // });

      // var pointerInteraction = new ol.interaction.Pointer({
      //   handleMoveEvent: function (event) {
      //     // vectorSource.fire('mousemove', event);
      //   }
      // });
      // that.map.addInteraction(pointerInteraction);
      // vectorSource.addFeatures(features);
    },
    // 插入图标
    insertIcon2(data, vectorSource) {
      var that = this
      // 插入图标
      that.vectorLayer = new ol.layer.Vector({
        source: vectorSource,
        zIndex: 200,
      })
      that.map.addLayer(that.vectorLayer)
      var features = []
      data.map(function (item, index) {
        var feature = new ol.Feature()
        feature.setGeometry(new ol.geom.Point([item.lng, item.lat]))
        feature.setProperties({
          location: { x: item.lng, y: item.lat },
          address: item.name,
          data: item,
          score: index,
          filters: [item.name],
          src: item.mapImg,
        })
        feature.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.2, 0.5],
              scale: 1.0,
              src: item.mapImg,
              // transparent: true
            }),
          })
        )
        features.push(feature)
      })
      vectorSource.addFeatures(features)
    },
    handleClick(item) {
      if (item.showType == '示范园区') {
        api.demonstrationparkById({ id: item.id }).then((res) => {
          let { data } = res
          item = { ...item, ...data }
        })
        item.isshow = true
      } else if (item.showType == '重点项目') {
        api.keyprojectById({ id: item.id }).then((res) => {
          let { data } = res
          item = { ...item, ...data }
        })
        item.isshow = true
      } else if (item.showType == '龙头企业') {
        item.company_id = item.id
        that.$refs.enterpriseInfo.show(item)
      }
    },

    // 图标点击事件
    moveClick(e) {
      const that = this

      that.content = false
      /*当前选择的图标变化，在此仅改变了图片路径以显示不同的图标，可以根据自己的需要进行设置*/
      that.currentRomeRow = e
      that.content2 = false
      that.content3 = false
      that.dataListShow = false
      console.log(this.currentRomeRow, "123currentRomeRow")
      if (that.currentRomeRow && that.currentRomeRow.A.features) {
        if (that.currentRomeRow.A.features.length > 1) {
          that.content2 = true
          that.dataListShow = true
          that.dataList = that.currentRomeRow.A.features
          that.overlay.setPosition(
            e.getGeometry().getCoordinates()
          )
          that.currentRome = that.currentRomeRow.A.features[0]
          that.currentRome.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                anchor: [0.2, 0.5],
                src: that.currentRome.A.src,
              }),
            })
          )
          setTimeout(() => {

            that.content2 = false
            that.dataListShow = false
          }, 2000)
          return false
        }
        that.currentRome = that.currentRomeRow.A.features[0]
        that.currentRome.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.2, 0.5],
              src: that.currentRome.A.src,
            }),
          })
        )

        that.modelContent = that.currentRome.A
        if (that.modelContent.data.showType == '示范园区') {
          api
            .demonstrationparkById({ id: that.modelContent.data.id })
            .then((res) => {
              that.overlay.setPosition(e.getGeometry().getCoordinates())
              let { data } = res
              that.modelContent.data = { ...that.modelContent.data, ...data }
              that.content3 = true
              setTimeout(() => {
                that.content3 = false
              }, 2000)
            })
        } else if (that.modelContent.data.showType == '重点项目') {
          api
            .keyprojectById({ id: that.modelContent.data.id })
            .then((res) => {
              that.overlay.setPosition(e.getGeometry().getCoordinates())

              let { data } = res
              that.modelContent.data = { ...that.modelContent.data, ...data }
              that.content3 = true
              setTimeout(() => {
                that.content3 = false
              }, 2000)
            })
        } else if (that.modelContent.data.showType == '龙头企业') {
          // that.modelContent.data.company_id = that.modelContent.data.id
          // that.$refs.enterpriseInfo.show(that.modelContent.data)
          console.log(that.modelContent.data, '123龙头')
          that.overlay.setPosition(e.getGeometry().getCoordinates())

          that.modelContent.data = { ...that.modelContent.data }
          that.content3 = true
          setTimeout(() => {
            that.content3 = false
          }, 2000)
        }
      } else if (that.overlay) {
        that.content3 = false
        that.content2 = false
        that.overlay.setPosition(undefined)
      }
    },
    // 图标点击事件
    ioinClick() {
      const that = this
      that.select.on('select', function (e) {
        that.content = false
        /*当前选择的图标变化，在此仅改变了图片路径以显示不同的图标，可以根据自己的需要进行设置*/
        that.currentRomeRow = e.selected[0]
        that.content2 = false
        that.content = false
        that.dataListShow = false
        if (that.currentRomeRow && that.currentRomeRow.A.features) {
          if (that.currentRomeRow.A.features.length > 1) {
            that.content2 = true
            that.dataListShow = true
            that.dataList = that.currentRomeRow.A.features
            that.overlay.setPosition(
              e.selected[0].getGeometry().getCoordinates()
            )
            that.currentRome = that.currentRomeRow.A.features[0]
            that.currentRome.setStyle(
              new ol.style.Style({
                image: new ol.style.Icon({
                  anchor: [0.2, 0.5],
                  src: that.currentRome.A.src,
                }),
              })
            )
            return false
          }
          that.currentRome = that.currentRomeRow.A.features[0]
          that.currentRome.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                anchor: [0.2, 0.5],
                src: that.currentRome.A.src,
              }),
            })
          )

          that.modelContent = that.currentRome.A
          if (that.modelContent.data.showType == '示范园区') {
            api
              .demonstrationparkById({ id: that.modelContent.data.id })
              .then((res) => {
                let { data } = res
                that.modelContent.data = { ...that.modelContent.data, ...data }
                that.content = true
              })
          } else if (that.modelContent.data.showType == '重点项目') {
            api
              .keyprojectById({ id: that.modelContent.data.id })
              .then((res) => {
                let { data } = res
                that.modelContent.data = { ...that.modelContent.data, ...data }
                that.content = true
              })
          } else if (that.modelContent.data.showType == '龙头企业') {
            that.modelContent.data.company_id = that.modelContent.data.id
            that.$refs.enterpriseInfo.show(that.modelContent.data)
          }
        } else if (that.overlay) {
          that.content = false
          that.content2 = false
          that.overlay.setPosition(undefined)
        }
      })
    },

    offModel() {
      this.overlay.setPosition(undefined)
      this.content = false
      this.content2 = false
      this.select = new ol.interaction.Select()
      this.map.addInteraction(this.select)
      this.ioinClick()
    },
    //插入三角形 圆形  正方形
    insertGraphical() {
      var that = this
      that.map.once('postrender', function () {
        var features = []
        for (var i = 0, len = that.mapCoordinate.length; i < len; i++) {
          var provinceInfo = that.mapCoordinate[i]
          var geo = new ol.geom.Point([
            provinceInfo.value[0],
            provinceInfo.value[1],
          ])
          //ThemeFeature 格式类型
          // var fea = new ol.supermap.ThemeFeature(geo, attrs);
          //支持传入 ol.Feature 格式类型：
          var fea = new ol.Feature({
            geometry: geo,
            NAME: provinceInfo.name,
            // CON2009: provinceInfo.symbol
            CON2009: '3000',
          })

          features.push(fea)
        }

        // 创建一个圆形符号专题图层
        that.themeSource = new ol.source.RankSymbol(
          'RankSymbolLayer',
          'Circle',
          {
            map: that.map,
            attributions: ' ',
            themeField: 'CON2009',
            // 配置图表参数
            symbolSetting: {
              //必设参数
              codomain: [0, 40000], // 允许图形展示的值域范围，此范围外的数据将不制作图图形
              //圆最大半径 默认100
              maxR: 100,
              //圆最小半径 默认0
              minR: 0,
              // 圆形样式
              circleStyle: { fillOpacity: 0.8 },
              // 符号专题图填充颜色
              fillColor: '#FFA500',
              // 专题图hover 样式
              circleHoverStyle: { fillOpacity: 1 },
            },
          }
        )
        that.themeSource.addFeatures(features)
        // 事件绑定
        that.themeSource.on('mousemove', function (e) {
          console.log('鼠标移入')
          that.content = true
        })

        var pointerInteraction = new ol.interaction.Pointer({
          handleMoveEvent: function (event) {
            that.themeSource.fire('mousemove', event)
          },
        })
        that.map.addInteraction(pointerInteraction)

        that.map.addLayer(
          new ol.layer.Image({
            source: that.themeSource,
          })
        )
        that.themeSource.setOpacity(0.1)
      })
    },
  },
}
</script>
<!-- scoped="scoped" -->
<style lang="scss" >
.mapBD {
  width: 100%;
  height: calc(100% - 113px);
  position: relative;
}

.ol-popup2 {
  position: absolute;
  left: 50%;
  /* 定位父级的50% */
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 300px;
  z-index: 16;
}

.bm-view {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.input-group {
  margin: 8px 0;
}

.control {
  position: absolute;
  top: 10px;
  right: 10px;
}

.TC-ioin2 {
  position: absolute;
  top: 180px;
  left: 65%;
  z-index: 15;

  span {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0px 15px;
    cursor: pointer;
  }

  span.fh-sy {
    // background: url('../../assets/images/index/map/1_03.png') no-repeat center;
    background-size: cover;
  }

  span.fh-sc {
    // background: url('../../assets/images/index/map/1_05.png') no-repeat center;
    background-size: cover;
  }

  span.show-sy {
    // background: url('../../assets/images/index/map/1_07.png') no-repeat center;
    background-size: cover;
  }

  span.show-zzy {
    // background: url('../../assets/images/index/map/1_07.png') no-repeat center;
    background-size: cover;
  }

  span:hover {
    border: 1px solid rgb(179, 196, 31);
  }
}

.font-class {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 8px 15px rgb(0 79 203 / 61%);
  letter-spacing: 3px;
}

.lyric-enter,
.lyric-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.lyric-enter-to,
.lyric-leave {
  opacity: 1;
}

.lyric-enter-active,
.lyric-leave-active {
  transition: all 0.5s;
}

.data-zs-xx {
  // background: #0d4783;
  // background: #343f75 url('../../assets/images/index/map/model/1.png') no-repeat center;
  background-size: 100% 100%;

  .qy-xx-list {
    width: 100%;
    padding: 20px 10px;
    border-radius: 3px;

    .table-list {
      border-collapse: collapse;
      width: 100%;

      tr {
        td {
          border: 1px solid #156c6c;
          font-size: 12px;
          color: #ffffff;
          padding: 10px;
          text-align: center;
        }

        td:nth-child(1) {
          width: calc(100% - 80px);
        }

        td:nth-child(2) {
          width: 80px;
          cursor: pointer;

          .qy-xxtsk {
            // background: #343f75 url('../../assets/images/index/map/model/1.png') no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            text-align: left;
            left: 315px;
            top: -22px;

            .qy-yq-xx {
              width: 100%;
              padding: 10px 0px;
              border-radius: 3px;

              ul {
                padding: 10px;
                margin: 0px 10px;
                // background: #2f4983;
                border-radius: 3px;

                li {
                  width: 100%;
                  padding: 0px 10px;
                  color: #ffffff;
                  font-size: 14px;

                  .qy-data-xx-name {
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 20px;
                  }

                  .qy-data-xx {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    color: #ffffff;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;

                    span {
                      display: inline-block;
                      height: 42px;
                      line-height: 42px;
                      color: #bddfff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      tr:hover {
        background: #2f4983;
      }
    }
  }

  .qy-name {
    // background: url('../../assets/images/index/map/model/2.png') no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-weight: 600;
    text-align: center;
    font-size: 25px;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
    letter-spacing: 3px;

    span {
      position: absolute;
      right: 10px;
      top: 13px;
      cursor: pointer;
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 2px;
      // background: url('../../assets/images/index/map/1_13.png') no-repeat center;
      background-size: auto;
    }
  }

  .tab-btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 15px 0;

    >div {
      width: 180px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      // background: url('../../assets/images/index/map/model/7.png') no-repeat center;
      background-size: auto;
    }

    >div:nth-child(2) {
      margin: 0 20px;
    }

    >div:hover {
      // background: url('../../assets/images/index/map/model/8.png') no-repeat center;
      background-size: auto;
    }

    .tab-btn-isstatus {
      // background: url('../../assets/images/index/map/model/8.png') no-repeat center;
      background-size: auto;
    }
  }

  .qy-yq-xx {
    width: 100%;
    padding: 10px 0px;
    border-radius: 3px;

    ul {
      padding: 10px;
      margin: 0px 10px;
      // background: #2f4983;
      border-radius: 3px;

      li {
        width: 100%;
        padding: 0px 10px;
        color: #ffffff;
        font-size: 14px;

        .qy-data-xx-name {
          color: #ffffff;
          font-size: 16px;
          line-height: 20px;
        }

        .qy-data-xx {
          width: 100%;
          height: 30px;
          line-height: 30px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span {
            display: inline-block;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }
      }
    }
  }

  .qy-xx {
    width: 100%;

    .qy-zhuti {
      width: 100%;
      display: flex;

      .zhuti-no1 {
        width: 50%;
        padding: 15px 15px 0;

        .qy-xx-fzr {
          display: flex;
          flex-wrap: wrap;

          div {
            color: #ffffff;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
            margin: 5px;

            span {
              display: inline-block;
              color: #9ca7b8;
              margin: 0px 5px;
            }

            img {
              display: inline-block;
              vertical-align: middle;
              width: 20px;
            }
          }
        }

        .qy-xx-gsjj {
          height: 100px;
          overflow: auto;
          font-size: 12px;
          color: #ffffff;
          margin: 5px;
        }

        .qy-ry-xx {
          height: 250px;
          font-size: 12px;
          color: #ffffff;
          background: rgba(0, 147, 163, 0.3);
          margin: 5px;

          .ry-title {
            // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_13.png') no-repeat center;
            background-size: 100% 100%;
            padding-left: 15px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
          }

          .ry-lunbo {
            width: 250px;
            margin: 20px auto;
            padding: 5px;
            height: 180px;
            position: relative;
            // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_17.png') no-repeat center;
            background-size: 100% 100%;

            .left-btn {
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
              left: -36px;
              top: 40%;
              // background: url('../../assets/images/index/map/model/zhutiiocn/left.png') no-repeat center;
              background-size: 100% 100%;
              cursor: pointer;
            }

            .right-btn {
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
              right: -36px;
              top: 40%;
              // background: url('../../assets/images/index/map/model/zhutiiocn/right.png') no-repeat center;
              background-size: 100% 100%;
              cursor: pointer;
            }

            .left-btn:hover {
              width: 30px;
              height: 30px;
              // background: url('../../assets/images/index/map/model/zhutiiocn/lefthover.png');
              background-size: 100% 100%;
            }

            .right-btn:hover {
              width: 30px;
              height: 30px;
              // background: url('../../assets/images/index/map/model/zhutiiocn/righthover.png');
              background-size: 100% 100%;
            }

            .el-carousel__indicators--horizontal {
              display: none !important;
            }

            .el-carousel__item h3 {
              color: #475669;
              font-size: 14px;
              opacity: 0.75;
              line-height: 150px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
        }
      }

      .zhuti-no2 {
        width: 50%;
        padding: 15px 15px 0;

        .qy-cp-xx {
          .cp-xx-list {
            border-collapse: collapse;
            width: 100%;

            header {
              tr {
                width: 100%;
                display: inline-flex;

                td {
                  text-align: center;
                  width: 25%;
                  background: rgba(0, 156, 254, 0.2);
                  color: #02fafb;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 5px 0;
                }
              }
            }

            body {
              height: 145px;
              overflow: auto;

              tr {
                width: 100%;
                display: inline-flex;

                td {
                  text-align: center;
                  width: 25%;
                  color: #ffffff;
                  font-size: 12px;
                  padding: 5px 0;
                  overflow: hidden;
                }
              }

              tr:nth-child(2n) {
                td {
                  background: rgba(0, 156, 254, 0.1);
                }
              }
            }
          }
        }

        .qy-jk-xx {
          height: 250px;
          font-size: 12px;
          color: #ffffff;
          background: rgba(0, 147, 163, 0.3);
          margin: 5px;

          .jk-title {
            // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_13.png') no-repeat center;
            background-size: 100% 100%;
            padding-left: 15px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
          }

          .jk-sp {
            display: flex;

            .jk-shipin {
              width: 250px;
              padding: 5px;
              margin: 20px 5px;
              height: 180px;
              position: relative;
              // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_19.png') no-repeat center;
              background-size: 100% 100%;
            }

            .jk-list {
              width: calc(100% - 250px);
              padding: 5px;
              margin: 20px 5px;
              height: 180px;
              overflow: auto;

              ul {
                padding: 0;
                margin: 0;

                li {
                  padding: 0;
                  margin: 10px 0;
                  background: transparent !important;
                  display: inline-block;
                  text-align: center;
                  cursor: pointer;

                  .img-show {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_22.png') no-repeat center;
                    background-size: 100% 100%;
                  }

                  .imgHover {
                    // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_33.png') no-repeat center;
                    background-size: 100% 100%;
                  }

                  p {
                    text-align: center;
                    font-size: 12px;
                  }
                }

                li:hover {
                  .img-show {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    // background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_33.png') no-repeat center;
                    background-size: 100% 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    ul {
      padding: 10px 20px 10px;

      li {
        width: 100%;
        padding: 0px 10px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;

        .qy-data-xx {
          width: 100%;
          height: 42px;
          line-height: 42px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span:nth-child(1) {
            display: inline-block;
            width: 100px;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }

        .qy-jyfw {
          display: flex;
          flex-wrap: wrap;

          span {
            display: inline-block;
            width: 100px;
            height: 45px;
            line-height: 45px;
            color: #bddfff;
          }

          p {
            color: #ffffff;
            width: calc(100% - 100px);
            line-height: 25px;
            padding: 10px 0;
          }
        }
      }

      li:nth-child(2n) {
        background: #2f4983;
      }
    }

    .gd-jc-data {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      div {
        width: 140px;
        height: 80px;
        // background: url('../../assets/images/index/map/model/3.png') no-repeat center;
        background-size: 100% 100%;
        margin: 5px 10px;
        padding: 20px 10px;

        p:nth-child(1) {
          color: #ffffff;
          font-size: 12px;
        }

        p:nth-child(2) {
          color: #00ffff;
          font-size: 18px;
          margin-top: 5px;
        }
      }
    }

    .qy-jyxk {
      width: 100%;
      display: flex;
      justify-content: center;

      div {
        width: 180px;
        height: 90px;
        // background: url('../../assets/images/index/map/model/3.png') no-repeat center;
        background-size: auto;
        margin: 0px 10px;
        padding: 20px 5px;
        text-align: center;
        font-size: 14px;

        p:nth-child(1) {
          color: #bddfff;
        }

        p:nth-child(2) {
          color: #00ffff;
          font-size: 24px;
          margin-top: 5px;
        }
      }
    }

    .qy-sc-xx {
      padding: 10px 20px 10px;

      .table-ys {
        border-collapse: collapse;
        width: 100%;

        tr {
          td {
            border: 1px solid #156c6c;
            font-size: 12px;
            color: #ffffff;
            padding: 10px;
            text-align: center;
          }

          td:nth-child(1) {
            width: 20%;
          }

          td:nth-child(2) {
            width: 26%;
          }

          td:nth-child(3) {
            width: 26%;
          }

          td:nth-child(4) {
            width: 26%;
          }
        }
      }

      .qy-cp-xx {
        display: flex;
        flex-wrap: wrap;

        .qy-data-xx {
          width: 100%;
          height: 42px;
          line-height: 42px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span:nth-child(1) {
            display: inline-block;
            width: 100px;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }
      }
    }
  }

  .qy-jjxx {
    height: 400px;
    padding: 15px;
    background: #2f4983;
    margin: 20px;
    border-radius: 2px;
    color: #b8d9fa;
  }

  .tz-btn {
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 500px;
    height: 100px;
    line-height: 100px;
    padding: 0px 10px;

    a {
      display: inline-block;
      width: 100%;
      text-decoration: none;
    }

    span {
      display: inline-block;
      width: 100%;
      height: 60px;
      line-height: 60px;
      // background: url('../../assets/images/index/map/model/5.png') no-repeat center;
      background-size: 100%;
      color: #00fefe;
      font-size: 18px;

      img {
        margin-left: 15px;
        vertical-align: middle;
      }
    }
  }
}

.ol-popup2 {
  position: absolute;
  left: 50%;
  /* 定位父级的50% */
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 300px;
  z-index: 16;
}

.data-zs-xx {
  // background: #0d4783;
  background: #343f75 url('../../assets/images/index/map/model/1.png') no-repeat center;
  background-size: 100% 100%;

  .qy-xx-list {
    width: 100%;
    padding: 20px 10px;
    border-radius: 3px;

    .table-list {
      border-collapse: collapse;
      width: 97%;

      tr {
        td {
          border: 1px solid #156c6c;
          font-size: 12px;
          color: #ffffff;
          padding: 10px;
          text-align: center;
        }

        td:nth-child(1) {
          width: calc(100% - 80px);
        }

        td:nth-child(2) {
          width: 80px;
          cursor: pointer;

          .qy-xxtsk {
            background: #343f75 url('../../assets/images/index/map/model/1.png') no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            text-align: left;
            left: 315px;
            top: -22px;

            .qy-yq-xx {
              width: 100%;
              padding: 10px 0px;
              border-radius: 3px;

              ul {
                padding: 10px;
                margin: 0px 10px;
                // background: #2f4983;
                border-radius: 3px;

                li {
                  width: 100%;
                  padding: 0px 10px;
                  color: #ffffff;
                  font-size: 14px;

                  .qy-data-xx-name {
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 20px;
                  }

                  .qy-data-xx {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    color: #ffffff;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;

                    span {
                      display: inline-block;
                      height: 42px;
                      line-height: 42px;
                      color: #bddfff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      tr:hover {
        background: #2f4983;
      }
    }
  }

  .aa {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;

    .qy-data-xx {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      /*font-size: 1px;*/
      span {
        display: inline-block;
        height: 42px;
        line-height: 42px;
        color: #bddfff;
      }
    }
  }

  .qy-name {
    background: url('../../assets/images/index/map/model/2.png') no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-weight: 600;
    text-align: center;
    font-size: 25px;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
    letter-spacing: 3px;

    span {
      position: absolute;
      right: 10px;
      top: 13px;
      cursor: pointer;
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 2px;
      background: url('../../assets/images/index/map/1_13.png') no-repeat center;
      background-size: auto;
    }
  }

  .tab-btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 15px 0;

    >div {
      width: 180px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      background: url('../../assets/images/index/map/model/7.png') no-repeat center;
      background-size: auto;
    }

    >div:nth-child(2) {
      margin: 0 20px;
    }

    >div:hover {
      background: url('../../assets/images/index/map/model/8.png') no-repeat center;
      background-size: auto;
    }

    .tab-btn-isstatus {
      background: url('../../assets/images/index/map/model/8.png') no-repeat center;
      background-size: auto;
    }
  }

  .qy-yq-xx {
    width: 100%;
    padding: 10px 0px;
    border-radius: 3px;

    ul {
      padding: 10px;
      margin: 0px 10px;
      // background: #2f4983;
      border-radius: 3px;

      li {
        width: 100%;
        padding: 0px 10px;
        color: #ffffff;
        font-size: 14px;

        .qy-data-xx-name {
          color: #ffffff;
          font-size: 16px;
          line-height: 20px;
        }

        .qy-data-xx {
          width: 100%;
          height: 30px;
          line-height: 30px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span {
            display: inline-block;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }
      }
    }
  }

  .qy-xx {
    width: 100%;

    .qy-zhuti {
      width: 100%;
      display: flex;

      .zhuti-no1 {
        width: 50%;
        padding: 15px 15px 0;

        .qy-xx-fzr {
          display: flex;
          flex-wrap: wrap;

          div {
            color: #ffffff;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
            margin: 5px;

            span {
              display: inline-block;
              color: #9ca7b8;
              margin: 0px 5px;
            }

            img {
              display: inline-block;
              vertical-align: middle;
              width: 20px;
            }
          }
        }

        .qy-xx-gsjj {
          height: 100px;
          overflow: auto;
          font-size: 12px;
          color: #ffffff;
          margin: 5px;
        }

        .qy-ry-xx {
          height: 250px;
          font-size: 12px;
          color: #ffffff;
          background: rgba(0, 147, 163, 0.3);
          margin: 5px;

          .ry-title {
            background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_13.png') no-repeat center;
            background-size: 100% 100%;
            padding-left: 15px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
          }

          .ry-lunbo {
            width: 250px;
            margin: 20px auto;
            padding: 5px;
            height: 180px;
            position: relative;
            background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_17.png') no-repeat center;
            background-size: 100% 100%;

            .left-btn {
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
              left: -36px;
              top: 40%;
              background: url('../../assets/images/index/map/model/zhutiiocn/left.png') no-repeat center;
              background-size: 100% 100%;
              cursor: pointer;
            }

            .right-btn {
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
              right: -36px;
              top: 40%;
              background: url('../../assets/images/index/map/model/zhutiiocn/right.png') no-repeat center;
              background-size: 100% 100%;
              cursor: pointer;
            }

            .left-btn:hover {
              width: 30px;
              height: 30px;
              background: url('../../assets/images/index/map/model/zhutiiocn/lefthover.png');
              background-size: 100% 100%;
            }

            .right-btn:hover {
              width: 30px;
              height: 30px;
              background: url('../../assets/images/index/map/model/zhutiiocn/righthover.png');
              background-size: 100% 100%;
            }

            .el-carousel__indicators--horizontal {
              display: none !important;
            }

            .el-carousel__item h3 {
              color: #475669;
              font-size: 14px;
              opacity: 0.75;
              line-height: 150px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
        }
      }

      .zhuti-no2 {
        width: 50%;
        padding: 15px 15px 0;

        .qy-cp-xx {
          .cp-xx-list {
            border-collapse: collapse;
            width: 100%;

            header {
              tr {
                width: 100%;
                display: inline-flex;

                td {
                  text-align: center;
                  width: 25%;
                  background: rgba(0, 156, 254, 0.2);
                  color: #02fafb;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 5px 0;
                }
              }
            }

            body {
              height: 145px;
              overflow: auto;

              tr {
                width: 100%;
                display: inline-flex;

                td {
                  text-align: center;
                  width: 25%;
                  color: #ffffff;
                  font-size: 12px;
                  padding: 5px 0;
                  overflow: hidden;
                }
              }

              tr:nth-child(2n) {
                td {
                  background: rgba(0, 156, 254, 0.1);
                }
              }
            }
          }
        }

        .qy-jk-xx {
          height: 250px;
          font-size: 12px;
          color: #ffffff;
          background: rgba(0, 147, 163, 0.3);
          margin: 5px;

          .jk-title {
            background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_13.png') no-repeat center;
            background-size: 100% 100%;
            padding-left: 15px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
          }

          .jk-sp {
            display: flex;

            .jk-shipin {
              width: 250px;
              padding: 5px;
              margin: 20px 5px;
              height: 180px;
              position: relative;
              background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_19.png') no-repeat center;
              background-size: 100% 100%;
            }

            .jk-list {
              width: calc(100% - 250px);
              padding: 5px;
              margin: 20px 5px;
              height: 180px;
              overflow: auto;

              ul {
                padding: 0;
                margin: 0;

                li {
                  padding: 0;
                  margin: 10px 0;
                  background: transparent !important;
                  display: inline-block;
                  text-align: center;
                  cursor: pointer;

                  .img-show {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_22.png') no-repeat center;
                    background-size: 100% 100%;
                  }

                  .imgHover {
                    background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_33.png') no-repeat center;
                    background-size: 100% 100%;
                  }

                  p {
                    text-align: center;
                    font-size: 12px;
                  }
                }

                li:hover {
                  .img-show {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    background: url('../../assets/images/index/map/model/zhutiiocn/ditutk_33.png') no-repeat center;
                    background-size: 100% 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    ul {
      padding: 10px 20px 10px;

      li {
        width: 100%;
        padding: 0px 10px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;

        .qy-data-xx {
          width: 100%;
          height: 42px;
          line-height: 42px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span:nth-child(1) {
            display: inline-block;
            width: 100px;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }

        .qy-jyfw {
          display: flex;
          flex-wrap: wrap;

          span {
            display: inline-block;
            width: 100px;
            height: 45px;
            line-height: 45px;
            color: #bddfff;
          }

          p {
            color: #ffffff;
            width: calc(100% - 100px);
            line-height: 25px;
            padding: 10px 0;
          }
        }
      }

      li:nth-child(2n) {
        background: #2f4983;
      }
    }

    .gd-jc-data {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      div {
        width: 140px;
        height: 80px;
        background: url('../../assets/images/index/map/model/3.png') no-repeat center;
        background-size: 100% 100%;
        margin: 5px 10px;
        padding: 20px 10px;

        p:nth-child(1) {
          color: #ffffff;
          font-size: 12px;
        }

        p:nth-child(2) {
          color: #00ffff;
          font-size: 18px;
          margin-top: 5px;
        }
      }
    }

    .qy-jyxk {
      width: 100%;
      display: flex;
      justify-content: center;

      div {
        width: 180px;
        height: 90px;
        background: url('../../assets/images/index/map/model/3.png') no-repeat center;
        background-size: auto;
        margin: 0px 10px;
        padding: 20px 5px;
        text-align: center;
        font-size: 14px;

        p:nth-child(1) {
          color: #bddfff;
        }

        p:nth-child(2) {
          color: #00ffff;
          font-size: 24px;
          margin-top: 5px;
        }
      }
    }

    .qy-sc-xx {
      padding: 10px 20px 10px;

      .table-ys {
        border-collapse: collapse;
        width: 100%;

        tr {
          td {
            border: 1px solid #156c6c;
            font-size: 12px;
            color: #ffffff;
            padding: 10px;
            text-align: center;
          }

          td:nth-child(1) {
            width: 20%;
          }

          td:nth-child(2) {
            width: 26%;
          }

          td:nth-child(3) {
            width: 26%;
          }

          td:nth-child(4) {
            width: 26%;
          }
        }
      }

      .qy-cp-xx {
        display: flex;
        flex-wrap: wrap;

        .qy-data-xx {
          width: 100%;
          height: 42px;
          line-height: 42px;
          color: #ffffff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          span:nth-child(1) {
            display: inline-block;
            width: 100px;
            height: 42px;
            line-height: 42px;
            color: #bddfff;
          }
        }
      }
    }
  }

  .qy-jjxx {
    height: 400px;
    padding: 15px;
    background: #2f4983;
    margin: 20px;
    border-radius: 2px;
    color: #b8d9fa;
  }

  .tz-btn {
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 500px;
    height: 100px;
    line-height: 100px;
    padding: 0px 10px;

    a {
      display: inline-block;
      width: 100%;
      text-decoration: none;
    }

    span {
      display: inline-block;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background: url('../../assets/images/index/map/model/5.png') no-repeat center;
      background-size: 100%;
      color: #00fefe;
      font-size: 18px;

      img {
        margin-left: 15px;
        vertical-align: middle;
      }
    }
  }
}

.ol-popup {
  position: absolute;
  bottom: 12px;
  left: -50px;
  min-width: 300px;
  z-index: 999;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  // left: 300px;
}

.ol-popup:after {
  border-top-color: #0d4783;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #0d4783;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.k-Mask {
  position: relative;
  width: 100%;
  height: 1080px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 16;
}

.ol-control {
  display: none;
}

.kongtiaojifang {
  // transition: 0.1s;
  width: 320px;
  position: fixed;
  left: 1500px;
  top: 900px;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  cursor: pointer;
  z-index: 15;

  .Drag-TC {
    .Drag-TC-no1 {
      position: relative;
      padding-left: 30px;
      width: 100%;
      height: 35px;
      line-height: 35px;
      // background: url('../../assets/images/index/map/1_17.png') no-repeat center;
      background-size: cover;

      span {
        position: absolute;
        right: 0px;
        top: 0px;
        display: inline-block;
        width: 25px;
        height: 25px;
        // background: url('../../assets/images/index/map/1_13.png') no-repeat center;
        background-size: cover;
      }
    }

    .Drag-TC-no2 {
      width: 100%;
      height: 600px;
      background: rgba(1, 17, 54, 0.8);
      border: 1px solid rgba(1, 17, 54, 1);
      padding: 15px 8px;

      .el-tree {
        color: #ffffff;
        background: transparent;
      }

      .el-tree-node__content:hover {
        background-color: #306ac1;
      }

      .el-tree-node:focus>.el-tree-node__content {
        background: #306ac1 !important;
      }

      .el-tree-node.is-current>.el-tree-node__content {
        background: #306ac1 !important;
        color: #ffffff !important;
      }

      .children-item-tree {
        height: 265px;
        overflow: auto;
      }

      .layer-title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        position: relative;
        // background: url('../../assets/images/index/map/1_21.png') no-repeat center;
        background-size: cover;

        .xzk {
          display: inline-block;
          height: 25px;
          color: #ffffff;
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }

      .el-checkbox__label {
        color: #ffffff;
      }

      .el-checkbox__inner {
        background: #0d4783;
        border: 1px solid #02ffff;
      }

      .el-checkbox__inner::after {
        border: 2px solid #02ffff; // 是改这里的颜色
        border-left: 0;
        border-top: 0;
      }

      .children-item {
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;
          padding: 0px 8px;

          li {
            width: 50%;
            line-height: 30px;
          }
        }
      }

      .children-item2 {
        overflow: auto;
        height: 600px;

        ul {
          margin: 0px;
          padding: 0px 8px;

          li {
            width: 100%;
            // height: 30px;
            line-height: 30px;

            ul {
              display: flex;
              flex-wrap: wrap;

              li {
                width: 50%;
                height: 30px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .children-item3 {
        .el-input--medium .el-input__icon {
          line-height: 25px !important;
        }

        margin-bottom: 10px;

        .el-radio-group {
          padding: 10px 20px;
        }

        .el-radio {
          width: 50%;
          margin: 5px 0;
          color: #ffffff;
        }

        .zdy-data {
          width: 100%;
          padding: 0px 20px;
          font-size: 14px;

          span {
            display: inline-block;

            input {
              width: 70px;
              border-radius: 3px;
              height: 20px;
              line-height: 20px;
              margin: 2px 8px;
              padding-left: 8px;
              background: #262667;
              border: 1px solid #bddfff;
              color: #ffffff;
              outline: none;
            }

            button {
              width: 40px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              border-radius: 3px;
              cursor: pointer;
              outline: none;
              border: none;
              padding: 0;
              margin-left: 10px;
              color: #1d3898;
              background: #02ffff;
            }

            .btn-no1 {
              background: #02ffff;
            }

            .btn-no1:hover {
              background: #1792f3;
            }

            .btn-no2 {
              background: #1792f3;
            }

            .btn-no2:hover {
              background: #02ffff;
            }
          }
        }

        ul {
          margin: 0px;
          padding: 0px 8px;

          li {
            width: 100%;
            // height: 30px;
            line-height: 30px;

            ul {
              display: flex;
              flex-wrap: wrap;

              li {
                width: 50%;
                height: 30px;
                line-height: 30px;
              }

              li:nth-child(5),
              li:nth-child(6) {
                width: 100%;

                span {
                  display: inline-block;

                  input {
                    width: 70px;
                    border-radius: 3px;
                    height: 20px;
                    line-height: 20px;
                    margin-right: 8px;
                    padding-left: 8px;
                    background: #262667;
                    border: 1px solid #bddfff;
                    color: #ffffff;
                    outline: none;
                  }

                  button {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    border-radius: 3px;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    padding: 0;
                    margin-left: 10px;
                    color: #1d3898;
                    background: #02ffff;
                  }

                  .btn-no1 {
                    background: #02ffff;
                  }

                  .btn-no1:hover {
                    background: #1792f3;
                  }

                  .btn-no2 {
                    background: #1792f3;
                  }

                  .btn-no2:hover {
                    background: #02ffff;
                  }
                }
              }
            }
          }
        }
      }

      .xian {
        width: 100%;
        height: 30px;
        // background: url('../../assets/images/index/map/1_76.png') no-repeat center;
        background-size: auto;
      }
    }

    .Drag-TC-no2 {
      height: 300px;

      .children-item2 {
        height: 210px;
      }
    }
  }
}

.children-item3 {
  .el-checkbox__label {
    font-size: 12px;
  }
}
</style>

