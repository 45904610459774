<template>
  <div class="cygk">
    <component :is="curr"></component>
    <div class="menu">
      <ul>
        <li :class="{ active: curr == item.url }" @click="curr = item.url" v-for="item in navList" :key="item.title">
          {{ item.title }}</li>
        <!-- <li>产业大县</li>
                <li class="active">概况</li>
        <li>供京基地</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import Gk from '@/views/cygk/gk'
import Cydx from '@/views/cygk/cydx'
import Bjjd from '@/views/cygk/bjjd'
export default {
  name: 'Cygk',
  components: { Gk, Cydx, Bjjd },
  data() {
    return {
      curr: 'Gk',
      navList: [
        {
          title: '概况',
          url: 'Gk',
        },
        {
          title: '产业大县',
          url: 'Cydx',
        },

        {
          title: '供京基地',
          url: 'Bjjd',
        },
      ],
    }
  },
  mounted() { },
  methods: {},
}
</script>
<style lang='scss' scoped>
.cygk {
  width: 100%;
  height: 100%;
  position: relative;

  .menu {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    ul {
      // border: 1px solid #f00;
      display: flex;

      li {
        width: 175px;
        height: 51px;
        font-size: 24px;
        color: #32eeff;
        text-align: center;
        line-height: 0;
        background: url('~@/assets/images/gk_nav.png') no-repeat center/100% 100%;
        cursor: pointer;
      }

      li:nth-child(2) {
        margin: 0 45px;
      }

      .active {
        font-weight: bold;
        color: #fff;
        background: url('~@/assets/images/gk_nav_active.png') no-repeat center/100% 100%;
      }
    }
  }
}
</style>