<template>
  <div class="list">
    <base-dialog width="1200px" :label="title || '企业列表'" v-show="dialogFlag" :show-close="true" :z-index="zIndex"
      @closeDialog="closeDialog">
      <div class="wrap">
        <el-form :inline="true" class="myform">
          <el-form-item label>
            <el-select v-model="city" placeholder="市" class="pub_input" style="margin:0 10px;" @change="selectCity">
              <el-option label="全部" value></el-option>
              <el-option v-for="item in cityData" :key="item.cityId" :label="item.name" :value="item.cityId"></el-option>
            </el-select>
            <el-select v-model="county" placeholder="县/区" class="pub_input">
              <el-option label="全部" value></el-option>
              <el-option v-for="item in countyData" :key="item.cityId" :label="item.name"
                :value="item.cityId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label>
            <el-input class="pub_input" v-model="form.name" placeholder="名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" @click="companyList()" type="primary" icon="el-icon-search"></el-button>
          </el-form-item>
        </el-form>
        <div class="table_list">
          <el-table :data="tableData" stripe class="pub_table" height="100%">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="企业名称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="contacts" label="联系人" width="100" align="center"></el-table-column>
            <el-table-column prop="phone" label="联系方式" width="130" align="center"></el-table-column>
            <el-table-column prop="address" label="地址" min-width="100"></el-table-column>
            <el-table-column label="详情" width="100" align="center">
              <template slot-scope="scope">
                <span class="look" @click="lookDetail(scope.row)">查看</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="pub_laypage" :page-size="form.pageSize" @current-change="companyList"
          :current-page.sync="form.pageNum" prev-text="上一页" next-text="下一页" :pager-count="11"
          layout="prev, pager, next,total" :total="total"></el-pagination>
      </div>
    </base-dialog>
    <!-- 企业信息 -->
    <Enterprise-info ref="enterpriseInfo" :z-index="zIndex + 1"></Enterprise-info>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import {
  companyList,
  cityList,
  listByLable,
  getCompanysByLableid,
} from '@/api/dialog' //接口
import EnterpriseInfo from '@/components/enterprise' //企业信息
export default {
  components: { BaseDialog, EnterpriseInfo },
  props: {
    zIndex: {
      type: Number,
      default: 103,
    },
  },
  data() {
    return {
      form: {
        name: '', //名称
        pageNum: 1,
        pageSize: 10,
        parentAreaCodes: '', //区域id 12位
      },
      cityData: [], //市
      countyData: [], //县
      city: '', //市
      county: '', //县
      total: 0,
      tableData: [],
      dialogFlag: false,
      title: '', //标题
      apiType: 1, //接口类型
    }
  },
  mounted() {
    this.cityList(1, 130000000000)
  },
  methods: {
    companyList(page) {
      //企业列表
      this.form.pageNum = page || 1
      this.form.parentAreaCodes = this.county || this.city
      let api = ''
      if (this.apiType == 3) {
        //服务企业进入
        api = listByLable
      } else if (this.apiType == 4) {
        //产业服务-产业企业分布进入
        api = getCompanysByLableid
      } else {
        api = companyList
      }
      // console.log(this.form, 'form数据');
      api(this.form)
        .then((res) => {
          // console.log('企业列表', res.rows);
          // console.log(JSON.parse(JSON.stringify(res.rows)))
          this.tableData = res.rows || []
          this.total = res.total || 0
        })
        .catch(() => {
          this.tableData = []
          this.total = 0
        })
    },
    cityList(type, parentId) {
      //地区列表
      cityList({
        type,
        parentId: parentId || '',
        pageSize: 9999,
      })
        .then((res) => {
          // console.log(JSON.parse(JSON.stringify(res.rows)))
          if (type == 1) {
            //市
            this.cityData = res.rows || []
          } else if (type == 2) {
            //县
            this.countyData = res.rows || []
          }
        })
        .catch(() => {
          if (type == 1) {
            //市
            this.cityData = []
          } else if (type == 2) {
            //县
            this.countyData = []
          }
        })
    },
    selectCity(val) {
      //选择市
      if (val) {
        this.cityList(2, val)
      } else {
        this.countyData = []
      }
      this.county = ''
    },
    closeDialog() {
      //关闭弹框
      // console.log('关闭弹框')
      this.dialogFlag = false
      this.countyData = []
      this.city = ''
      this.county = ''
      this.form = {
        name: '', //名称
        pageNum: 1,
        pageSize: 10,
        parentAreaCodes: '', //区域id 12位
      }
      // this.$emit('closeDialog')
    },
    show(obj) {
      //显示
      // console.log('显示', obj)
      // console.log(JSON.parse(JSON.stringify(obj)))
      this.dialogFlag = true
      this.title = obj.title
      this.apiType = obj.type
      if (obj.type == 2) {
        //点击地区传入
        let flag = false
        for (let i = 0; i < this.cityData.length; i++) {
          if (obj.param.parentAreaCodes == this.cityData[i].cityId) {
            flag = true
            break
          }
        }
        if (flag) {
          //是市级
          // console.log('是市级')
          this.city = obj.param.parentAreaCodes
          this.cityList(2, this.city)
        } else {
          //是县级
          // console.log('是县级',obj)
          this.county = obj.param.parentAreaCodes
          this.city = Number(obj.parentInfo.code)
          this.cityList(2, obj.parentInfo.code)
        }
      }
      if (obj.param && Object.keys(obj.param).length != 0) {
        Object.assign(this.form, obj.param)
        this.companyList()
      } else {
        this.tableData = []
        this.total = 0
      }
    },
    lookDetail(val) {
      //查看企业详情
      // console.log('查看企业详情')
      // console.log(val)
      this.$refs.enterpriseInfo.show(val)
    },
  },
}
</script>

<style lang='scss' scoped>
.list {

  // border: 1px solid #f00;
  .wrap {
    // border: 3px solid #0f0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .myform {
      flex: none;

      .btn {
        font-size: 20px;
        padding: 0 20px;
        height: 40px;
      }
    }

    .table_list {
      flex: 1;
      overflow: hidden;
      margin-bottom: 10px;

      .look {
        color: #00ffff;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .pub_laypage {
      flex: none;
    }
  }
}
</style>