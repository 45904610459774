<template>
  <div class="gk">
    <div class="left">
      <!-- 全省概况 -->
      <div class="pub_border top">
        <div class="pub_title">全省概况</div>
        <ul class="content">
          <li>
            <div @click="lookList('', 'gj')">
              10
              <span>个</span>
            </div>
            <p>国家集群县</p>
          </li>
          <li>
            <div @click="lookList('', 'sheng')">
              24
              <span>个</span>
            </div>
            <p>省集群县汇总</p>
          </li>
          <li>
            <div @click="lookList('', 'area')">
              {{ qsgkObj.developmentCounty }}
              <span>个</span>
            </div>
            <p>重点发展县</p>
          </li>
          <li>
            <div @click="lookList(12, '重点企业')">
              {{ qsgkObj.enterprise }}
              <span>家</span>
            </div>
            <p>重点企业</p>
          </li>
          <li>
            <div @click="lookList(2, '供京基地')">
              {{ qsgkObj.baojingBase }}
              <span>家</span>
            </div>
            <p>供京基地</p>
          </li>
          <li>
            <div @click="lookList(7, '直供基地')">
              {{ qsgkObj.zhigongBase }}
              <span>家</span>
            </div>
            <p>直供基地</p>
          </li>
          <li>
            <div @click="lookList(1, '直属基地')">
              {{ qsgkObj.zhishuBase }}
              <span>家</span>
            </div>
            <p>直属基地</p>
          </li>
          <li>
            <div @click="lookBrand()">
              {{ qsgkObj.lvseBase }}
              <span>个</span>
            </div>
            <p>品牌数量</p>
          </li>
        </ul>
      </div>
      <!-- 播种面积及产量 -->
      <div class="pub_border middle">
        <div class="pub_title">
          <span @click="lookMjcl">播种面积及产量</span>
          <el-select v-model="selectYear" class="pub_select" placeholder="请选择" @change="yearChange">
            <el-option v-for="item in yearData" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="content">
          <!-- <ul> -->
          <el-carousel arrow="never">
            <el-carousel-item v-for="(page, i) in totalPage" :key="i">
              <ul class="list">
                <li :class="{ first: i == 0 && j == 0, second: i == 0 && j == 1, third: i == 0 && j == 2 }" class="item"
                  v-for="(item, j) in bzmjData.slice((page - 1) * 5, page * 5)" :key="i + j">
                  <span>{{ item.cityName }}</span>
                  <span>{{ item.area * 15 }}亩</span>
                  <span>{{ item.yield }}吨</span>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 销售量 -->
      <div class="pub_border bottom">
        <div class="pub_title" @click="xslFlag = true">销售量</div>
        <div class="chart" id="Xsl"></div>
        <img src="@/assets/images/cygk/pieBg.png" class="pie_bg" alt />
      </div>
    </div>
    <div class="center">
      <ul class="info">
        <li>
          <span>
            总面积
            <i>(万亩)</i>
          </span>
          <p>{{ ztgkObj.zmj || 0 }}</p>
        </li>
        <li>
          <span>
            总产量
            <i>(万吨)</i>
          </span>
          <p>{{ ztgkObj.zcl || 0 }}</p>
        </li>
        <li>
          <span>
            总单产
            <i>(吨/亩)</i>
          </span>
          <p>{{ ztgkObj.zcz || 0 }}</p>
        </li>
        <li>
          <span>
            设施蔬菜总面积
            <i>(万亩)</i>
          </span>
          <p>{{ ztgkObj.vegetablesArea || 0 }}</p>
        </li>
        <li>
          <span>
            设施蔬菜总产量
            <i>(万吨)</i>
          </span>
          <p>{{ ztgkObj.vegetablesYield || 0 }}</p>
        </li>
      </ul>
      <chaoTu ref="mapgis" v-if="!detailFlag"></chaoTu>
      <!-- 图例 -->
      <div class="pub_legend" v-if="!detailFlag">
        <div class="legend_title">
          <div>图例</div>
          <div>
            <el-checkbox @change="handleChange(checkedAll, 'all')" v-model="checkedAll"
              class="pub_checkbox"></el-checkbox>
            <span>全选</span>
          </div>
        </div>
        <ul class="legend_list">
          <li v-for="(item, i) in layerList" :key="item.id">
            <div v-if="i < 4">
              <el-checkbox @change="handleChange(item, i)" v-model="item.checked" class="pub_checkbox"></el-checkbox>
              <span></span>
              {{ item.name }}
            </div>
            <div v-else>
              <el-checkbox @change="handleChange(item, i)" v-model="item.checked" class="pub_checkbox"></el-checkbox>
              <img style="vertical-align: middle;" :src="item.imgSrc" alt />
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
      <!-- 图例 -->
      <div class="pub_legend1" v-show="layerList[4].checked || layerList[5].checked || layerList[6].checked"
        v-if="!detailFlag">
        <ul>
          <li v-show="layerList[4].checked">
            <div class="tit">示范园区</div>
            <div class="lis">
              <div>2021年规模：{{ sumInfo1.scaleOne }}</div>
              <div>2025年规模：{{ sumInfo1.scaleTwo }}</div>
              <div>数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量：{{ sumInfo1.num }}个</div>
              <div>2021年面积：{{ sumInfo1.areaOne }}</div>
              <div>2025年面积：{{ sumInfo1.areaTwo }}</div>
            </div>
          </li>

          <li v-show="layerList[5].checked">
            <div class="tit">重点项目</div>
            <div class="lis">
              <div>数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量：{{ sumInfo2.num }}个</div>
              <div>总&nbsp;&nbsp;投&nbsp;&nbsp; 入：{{ sumInfo2.totalInvestments }}</div>
              <div>2022投入：{{ sumInfo2.investments }}</div>
            </div>
          </li>
          <li v-show="layerList[6].checked">
            <div class="tit">龙头企业</div>
            <div class="lis">数&nbsp;&nbsp;&nbsp;量：{{ sumInfo3.num }}个</div>
          </li>
        </ul>
      </div>
      <div class="detailBtn" @click="toDetail" v-if="detailFlag">
        <p>详情</p>
      </div>
      <div class="backHome" @click="backHome" v-if="!detailFlag">
        <img src="@/assets/images/cygk/backImg.png" alt="">
        <span>返回</span>
      </div>
      <detailPage height="690px" :geo-data="areaType" v-if="detailFlag" class="detailPage"></detailPage>

    </div>
    <div class="right">
      <div class="pub_border top">
        <div class="pub_title">产业人才</div>
        <div class="content">
          <ul class="list">
            <li>
              <div>产业专家</div>
              <span @click="lookExpert()">
                36
                <i>人</i>
              </span>
            </li>
            <li>
              <div>技术</div>
              <span>
                152
                <i>人</i>
              </span>
            </li>
          </ul>
          <div class="chart" id="Cyrc"></div>
        </div>
      </div>
      <div class="pub_border middle">
        <div class="pub_title" @click="fwqyFlag = true">服务企业</div>
        <div class="content">
          <div class="info">
            <p>农资服务企业</p>
            <span>
              {{ fwqyTotal }}
              <i>家</i>
            </span>
          </div>
          <div class="wrap">
            <ul class="right_middle_list" :class="{ marquee_top: animate }" @mouseenter="fwqyEnter" @mouseleave="fwqyOut">
              <li v-for="item in fwqyData" :key="item.label_id">
                <label @click="lookServeList(item.lable_name)">{{ item.lable_name }}</label>
                <div class="rank">
                  <div class="percent" :style="{ width: item.percentage + '%' }"></div>
                </div>
                <span>
                  {{ item.company_count }}
                  <i>家</i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pub_border bottom">
        <div class="pub_title">精品品牌</div>
        <div class="content">
          <div class="wrap">
            <img @click="arrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow" alt />
            <el-carousel ref="cardShow" height="205px" arrow="never" indicator-position="none">
              <el-carousel-item v-for="(item, i) in jpppData" :key="item.company_id + i">
                <div class="inner" @click="lookBrandDetail(item)">
                  <h3>{{ item.name }}</h3>
                  <img :src="sysUrl + item.img_url" @error="e => { e.target.src = defaultImg }" alt />
                  <p>{{ item.company_name }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>
            <img @click="arrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 企业信息 -->
    <!-- <Enterprise-info ref="enterpriseInfo"></Enterprise-info> -->
    <!-- 专家列表 -->
    <expert-list ref="expertList"></expert-list>
    <!-- 专家详情 -->
    <!-- <expert-detail v-show="dialogFlag" @closeDialog="dialogFlag=false"></expert-detail> -->
    <!-- 视频详情 -->
    <!-- <video-detail v-show="dialogFlag" @closeDialog="dialogFlag=false"></video-detail> -->
    <!-- 农资商品页面 -->
    <!-- <goods-page v-show="dialogFlag" @closeDialog="dialogFlag=false"></goods-page> -->
    <!-- 企业列表 -->
    <enterprise-list ref="enterpriseList" :z-index="104"></enterprise-list>
    <!-- 图文详情 -->
    <!-- <article-detail v-show="articleFlag" @closeDialog="articleFlag=false"></article-detail> -->
    <!-- 品牌列表 -->
    <brand-list ref="brandList"></brand-list>
    <jiqunList ref="jiqunList"></jiqunList>
    <!-- 品牌展示 -->
    <brand-detail ref="brandDetail"></brand-detail>
    <!-- 今日供应列表 -->
    <!-- <supply-list ref="supplyList"></supply-list> -->
    <!-- 年播种面积及产量弹框 -->
    <base-dialog width="1200px" height="500px" :label="selectYear + '年播种面积及产量'" :show-close="true" v-show="mjclFlag"
      @closeDialog="mjclFlag = false">
      <div class="mjcl_dialog">
        <div class="chart" id="Mjcl"></div>
      </div>
    </base-dialog>
    <!-- 服务企业弹框 -->
    <base-dialog width="600px" height="800px" label="服务企业" :show-close="true" v-show="fwqyFlag"
      @closeDialog="fwqyFlag = false">
      <div class="fwqy_dialog">
        <ul class="right_middle_list">
          <li v-for="item in fwqyDataDialog" :key="item.label_id">
            <label @click="lookServeList(item.lable_name)">{{ item.lable_name }}</label>
            <div class="rank">
              <div class="percent" :style="{ width: item.percentage + '%' }"></div>
            </div>
            <span>
              {{ item.company_count }}
              <i>家</i>
            </span>
          </li>
        </ul>
      </div>
    </base-dialog>
    <!-- 销售量 -->
    <base-dialog width="800px" height="800px" label="销售量" :show-close="true" v-show="xslFlag"
      @closeDialog="xslFlag = false">
      <div class="xsl_dialog">
        <div class="chart" id="XslDialog"></div>
        <img src="@/assets/images/cygk/pieBg.png" class="pie_bg" alt />
      </div>
    </base-dialog>
  </div>
</template>

<script>
// import EnterpriseInfo from '@/components/enterprise' //企业信息
import ArticleDetail from '@/components/articleDetail' //图文详情
import ExpertList from '@/components/expert/list' //专家列表
import ExpertDetail from '@/components/expert/detail' //专家详情
import VideoDetail from '@/components/videoDetail' //视频详情
import GoodsPage from '@/components/goodsPage' //农资商品页面
import EnterpriseList from '@/components/enterpriseList' //企业列表
import jiqunList from '@/components/jiqun/jiqunList.vue' //企业列表
import BrandList from '@/components/brandList' //品牌列表
import BrandDetail from '@/components/brandDetail' //品牌展示
import SupplyList from '@/components/supplyList' //今日供应列表
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import 'echarts-gl'
import * as api from '@/api/cygk' //接口
import gj from '@/components/jiqun/gj' //接口
import chaoTu from '@/components/mapgis/chaotu.vue' // 地图插进
import detailPage from '@/components/map/detailPage.vue' // 地图插进
// import { imageUrl } from '@/utils/config'
export default {
  components: {
    ExpertList,
    ExpertDetail,
    VideoDetail,
    GoodsPage,
    EnterpriseList,
    BrandList,
    BaseDialog,
    SupplyList,
    BrandDetail,
    ArticleDetail,
    jiqunList,
    chaoTu,
    detailPage
  },
  data() {
    return {
      qsgkObj: {}, //全省概况
      fwqyData: [], //服务企业
      fwqyDataDialog: [], //服务企业
      fwqyFlag: false, //服务企业弹框
      animate: false,
      fwqyTimer: '', //服务企业定时器
      fwqyTotal: 0, //服务企业数量
      jpppData: [], //精品品牌
      yearData: [], //播种面积及产量--年份列表
      bzmjData: [], //播种面积及产量
      selectYear: '', //选中年份
      ztgkObj: {}, //总体概况

      cyrc: '', //产业人才
      xsl: '', //销售量
      xslDialog: '', //销售量
      xslFlag: false, //销售量弹框
      // checked:true,
      hjjCheck: true, //环京津棚室产区复选框
      hjjList: [
        //环京津棚室产区复选框列表
        { title: '三河市精品蔬菜示范区' },
        { title: '香河县精品蔬菜示范区' },
        { title: '永清县精品蔬菜示范区' },
        { title: '固安县精品蔬菜示范区' },
        { title: '涿州市精品蔬菜示范区' },
        { title: '' },
        { title: '定兴县精品蔬菜示范区' },
        { title: '清苑区精品蔬菜示范区' },
        { title: '蠡县精品蔬菜示范区' },
        { title: '肃宁县精品蔬菜示范区' },
        { title: '献县精品蔬菜示范区' },
        { title: '青县精品蔬菜示范区' },
      ],

      jdCheck: true, //冀东日光温室产区
      jdList: [
        //冀东日光温室产区列表
        { title: '玉田县精品蔬菜示范区' },
        { title: '丰润区精品蔬菜示范区' },
        { title: '丰南区精品蔬菜示范区' },
        { title: '迁安市精品蔬菜示范区' },
        { title: '滦州市精品蔬菜示范区' },
        { title: '滦南县精品蔬菜示范区' },
        { title: '昌黎县精品蔬菜示范区' },
        { title: '乐亭县精品蔬菜示范区' },
        { title: '抚宁区精品蔬菜示范区' },
      ],

      jznCheck: true, //冀中南棚室蔬菜产区
      jznList: [
        //冀中南棚室蔬菜产区列表
        { title: '正定县精品蔬菜示范区' },
        { title: '新乐市精品蔬菜示范区' },
        { title: '藁城市精品蔬菜示范区' },
        { title: '无极县精品蔬菜示范区' },
        { title: '定州市精品蔬菜示范区' },
        { title: '辛集市精品蔬菜示范区' },
        { title: '饶阳县精品蔬菜示范区' },
        { title: '阜城县精品蔬菜示范区' },
        { title: '武邑县精品蔬菜示范区' },
        { title: '故城县精品蔬菜示范区' },
        { title: '南和区精品蔬菜示范区' },
        { title: '鸡泽县精品蔬菜示范区' },
        { title: '永年区精品蔬菜示范区' },
        { title: '邯郸经济技术开发区精品蔬菜示范区' },
        { title: '肥乡区精品蔬菜示范区' },
        { title: '成安县精品蔬菜示范区' },
        { title: '魏县精品蔬菜示范区' },
        { title: '大名县精品蔬菜示范区' },
      ],

      jbCheck: true, //冀北露地错季菜产区
      jbList: [
        //冀北露地错季菜产区列表
        { title: '康保县精品蔬菜示范区' },
        { title: '尚义县精品蔬菜示范区' },
        { title: '张北县精品蔬菜示范区' },
        { title: '沽源县精品蔬菜示范区' },
        { title: '赤城县精品蔬菜示范区' },
        { title: '丰宁满族自治县精品蔬菜示范区' },
        { title: '滦平县精品蔬菜示范区' },
        { title: '隆化县精品蔬菜示范区' },
        { title: '围场满族蒙古族自治县精品蔬菜示范区' },
      ],

      sfyqCheck: true, //示范园区
      zdxmCheck: true, //重大项目
      zdxmList: [
        //重大项目列表
        { title: '涞源宏福现代农业产业园项目' },
        { title: '塔元庄同福乡村振兴示范区项目' },
        { title: '西瓜小镇项目' },
        { title: '龙安国际田园综合体起步区项目' },
        { title: '海通定兴现代智慧设施农业项目' },
        { title: '清苑区现代设施农业项目' },
        { title: '冀中（肃宁）农产品交易物流中心项目' },
        { title: '北京新发地沧州（青县）农副产品智慧物流暨城市安全保供仓项目' },
        {
          title: '北京新发地衡水（冀州）农副产品智慧物流园暨城市安全保供仓项目',
        },
        { title: '邢台市（南和）新发地农副产品智慧物流园项目' },
        { title: '馆陶天鸿智慧农业产业园项目' },
      ],

      ltqyCheck: true, //龙头企业
      ltqyList: [
        //龙头企业列表
        { title: '河北亚雄现代农业股份有限公司' },
        { title: '石家庄市惠康食品有限公司' },
        { title: '河北企美农业科技有限公司' },
        { title: '鸡泽县湘君府味业有限责任公司，鸡泽县天下红辣椒有限公司' },
        { title: '晨光生物科技集团股份有限公司' },
        { title: '高碑店市碧照轩观光园有限公司' },
        { title: '唐山广野食品集团有限公司' },
        { title: '承德绿丰生态农业科技发展有限公司' },
        { title: '唐山市鼎新蔬菜出口加工有限公司' },
        { title: '昌黎县嘉诚实业集团有限公司' },
      ],
      checkedAll: true,
      layerList: [
        {
          id: '1',
          name: '环京津棚室产区',
          imgSrc: require('@/assets/images/index/map/1_58.png'),
          layerSrc: window.location.origin + '/layer/环京津棚室产区.json',
          layerName: 'layer11',
          color: '#2797FF',
          checked: true,
        },
        {
          id: '2',
          name: '冀东日光温室产区',
          imgSrc: require('@/assets/images/index/map/1_58.png'),
          layerSrc: window.location.origin + '/layer/冀东日光温室产区.json',
          layerName: 'layer12',
          color: '#FFE600',
          checked: true,
        },
        {
          id: '3',
          name: '冀中南棚室蔬菜产区',
          imgSrc: require('@/assets/images/index/map/1_58.png'),
          layerSrc: window.location.origin + '/layer/冀中南棚室蔬菜产区.json',
          layerName: 'layer13',
          color: '#00FFD9',
          checked: true,
        },
        {
          id: '4',
          name: '冀北露地错季菜产区',
          imgSrc: require('@/assets/images/index/map/1_58.png'),
          layerSrc: window.location.origin + '/layer/冀北露地错季菜产区.json',
          layerName: 'layer14',
          color: '#27E000',
          checked: true,
        },
        {
          id: '5',
          name: '示范园区',
          imgSrc: require('@/assets/images/cygk/sfyq_icon.png'),
          layerName: 'layer15',
          color: '#ff5d5d',
          checked: true,
        },
        {
          id: '6',
          name: '重点项目',
          imgSrc: require('@/assets/images/cygk/zdxm_icon.png'),
          layerName: 'layer16',
          color: '#ff5d5d',
          checked: true,
        },
        {
          id: '7',
          name: '龙头企业',
          imgSrc: require('@/assets/images/cygk/ltqy_icon.png'),
          layerName: 'layer17',
          color: '#ff5d5d',
          checked: true,
        },
      ],
      mjclFlag: false, //播种面积及产量弹框
      mjcl: '', //播种面积及产量

      dialogFlag: true,
      qyFlag: false, //企业列表弹框
      // articleFlag:false,//图文详情弹框
      // brandFlag:false,//品牌列表弹框
      // brandDetailFlag:false,//品牌展示弹框
      // expertFlag:false,//专家列表弹框
      sysUrl,
      defaultImg: require('@/assets/images/default_img.png'),
      sumInfo1: {},
      sumInfo2: {},
      sumInfo3: {},
      detailFlag: true,
      areaType: [  //区域颜色
        {
          type: 'jibei',
          area: ['康保县', '沽源县', '丰宁满族自治县', '围场满族蒙古族自治县', '隆化县', '尚义县', '张北县', '平泉市', '崇礼区', '赤城县', '承德县'],
        }, {
          type: 'huanjingjin',
          area: ['涞水县', '涿州市', '高碑店市', '固安县', '永清县', '定兴县', '徐水区', '满城区', '清苑区', '望都县', '定州市',],
        }, {
          type: 'jizhong',
          area: ['新乐市', '正定县', '藁城区', '长安区', '裕华区', '栾城区', '元氏县', '高邑县', '宁津县', '隆尧县', '任县', '南和县', '永年区', '鸡泽县', '曲周县', '宁晋县', '丛台区', '复兴区', '肥乡区', '成安县', '临漳县', '广平县', '魏县', '临西县', '馆陶县', '大名县'],
        }, {
          type: 'jidong',
          area: ['遵化市', '玉田县', '丰润区', '丰南区', '滦南县', '乐亭县', '昌黎县', '滦州市', '抚宁区', '海港区', '路北区'],
        }, {
          type: 'hengcang',
          area: ['青县', '肃宁县', '饶阳县', '献县', '武强县', '武邑县', '阜城县', '枣强县', '冀州区', '故城县'],
        }
      ]
    }
  },
  computed: {
    totalPage() {
      //总页数
      return Math.ceil(this.bzmjData.length / 5)
    },
  },
  mounted() {
    // this.$refs.enterpriseInfo.show(340)
    this.getYearList()
    this.listByYear()
    this.labelsCount()
    this.getBandList()
    this.countExpertData()

    this.saleListByYear()
    // this.ztListByYear()

    this.cyrcInit()
    this.mjclInit()
    this.intLayer()
    this.sumInfo(1)
    this.sumInfo(2)
    this.sumInfo(3)
  },
  methods: {
    handleChange(item, i) {
      if (i == 'all') {
        if (item) {
          this.intLayer(1)
        } else {
          this.intLayer(0)
        }
      }
      this.$bus.$emit('handleCheckedChange', item)
    },
    sumInfo(i) {
      let that = this
      api
        .sumInfo({
          type: i,
        })
        .then((res) => {
          let { data } = res
          if (i == 1) {
            this.sumInfo1 = data
          } else if (i == 2) {
            this.sumInfo2 = data
          } else {
            this.sumInfo3 = data
          }
        })
    },
    intLayer(v = 1) {
      //0全不选，1全选
      this.layerList.forEach((e) => {
        if (v == 0) {
          e.checked = false
        } else {
          e.checked = true
        }
        this.$bus.$emit('handleCheckedChange', e)
      })
    },
    listByYear() {
      //全省概况表
      api.listByYear({}).then((res) => {
        // console.log('全省概况表')
        // console.log(JSON.parse(JSON.stringify(res.data)))
        this.qsgkObj = res.data || {}
      })
    },
    labelsCount() {
      //服务企业
      api.labelsCount({}).then((res) => {
        // console.log('服务企业')
        // console.log(JSON.parse(JSON.stringify(res.data)))
        this.fwqyData = res.data.balelList || []
        this.fwqyDataDialog = [...this.fwqyData]
        this.fwqyTotal = res.data.total || 0
        clearInterval(this.fwqyTimer)
        if (this.fwqyData.length > 4) {
          this.fwqyTimer = setInterval(this.roll, 5000)
        }
      })
    },
    getBandList() {
      //精品品牌https://123.56.205.166:35100/countyfarm/demonstrationpark/page
      api.getBandList({}).then((res) => {
        // console.log('精品品牌')
        // console.log(JSON.parse(JSON.stringify(res.data)))
        this.jpppData = res.data || []
      })
    },
    getYearList() {
      //年份列表接口
      api.getYearList({}).then((res) => {
        // console.log('年份列表接口')
        // console.log(JSON.parse(JSON.stringify(res.data)))
        this.yearData = res.data || []
        if (this.yearData.length != 0) {
          this.selectYear = this.yearData[0]
          this.yieldListByYear()
        }
      })
    },
    yieldListByYear() {
      //面积及产量列表
      api
        .yieldListByYear({
          year: this.selectYear,
        })
        .then((res) => {
          // console.log('面积及产量列表')
          // console.log(JSON.parse(JSON.stringify(res.rows)))
          this.bzmjData = res.rows || []
          for (let i = 0; i < this.bzmjData.length; i++) {
            if (this.bzmjData[i].cityName == '河北省') {
              this.ztgkObj = {
                zmj: ((this.bzmjData[i].area || 0) / 10000 * 15).toFixed(2),
                zcl: ((this.bzmjData[i].yield || 0) / 10000).toFixed(2),
                zcz: ((this.bzmjData[i].perunit || 0) / 10000 / 15).toFixed(2),
                vegetablesArea: (
                  (this.bzmjData[i].vegetablesArea || 0) / 10000 * 15
                ).toFixed(2),
                vegetablesYield: (
                  (this.bzmjData[i].vegetablesYield || 0) / 10000
                ).toFixed(2),
              }
              this.bzmjData.splice(i, 1)
              break
            }
          }
        })
    },
    yearChange(val) {
      //面积及产量列表-年份变化
      this.yieldListByYear()
    },
    saleListByYear() {
      //销售量
      api
        .saleListByYear({
          year: '',
        })
        .then((res) => {
          // console.log('销售量')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let arr = [
            {
              name: res.data.name1,
              value: Number(res.data.value1),
              itemStyle: {
                color: '#21F6C1',
              },
            },
            {
              name: res.data.name2,
              value: Number(res.data.value2),
              itemStyle: {
                color: '#FFB00A',
              },
            },
            {
              name: res.data.name3,
              value: Number(res.data.value3),
              itemStyle: {
                color: '#00C0FF',
              },
            },
            {
              name: res.data.name4,
              value: Number(res.data.value4),
              itemStyle: {
                color: '#FF6467',
              },
            },
          ]
          this.xslInit(arr, 'xsl', 'Xsl')
          this.xslInit(arr, 'xslDialog', 'XslDialog')
        })
    },
    xslInit(arr, Init, Id) {
      //销售量
      this[Init] = this.$echarts.init(document.getElementById(Id))
      // 传入数据生成 option
      let option = this.getPie3D(arr, 0.56)
      // let option = this.getPie3D(
      // [
      //     {
      //     name: '产地直销',
      //     value: 53.47,
      //     itemStyle: {
      //         color: '#21F6C1',
      //     }
      // }, {
      //     name: '销地',
      //     value: 53.47,
      //     itemStyle: {
      //         color: '#FFB00A'
      //     }
      // },
      // {
      //     name: '供京直供',
      //     value: 33.47,
      //     itemStyle: {
      //         color: '#00C0FF'
      //     }
      // },
      // {
      //     name: '基地直销',
      //     value: 43.47,
      //     itemStyle: {
      //         color: '#FF6467'
      //     }
      // }], 0.56);
      // if (Init=='xslDialog') {
      //     // let ref=document.getElementById(Id)
      //     // let width=ref.clientWidth
      //     // let height=ref.clientHeight
      //     this[Init].resize({
      //         width:800,
      //         height:740,
      //     });
      // }
      this[Init].setOption(option)
    },
    lookList(val, title) {
      console.log(gj)
      //查看企业列表
      // this.qyFlag=true
      // companyTypeId:'3',
      // market:'1',//是否是市场标记 0是；1否永清县、固安县、围场满族蒙古族自治县、乐亭县、玉田县、昌黎县、定兴县、涿州市、青县、滦南县、饶阳县、崇礼区、丰宁满族自治县、清苑区、邯郸经济技术开发区、阜城县、新乐市、馆陶县、望都县、鸡泽县
      if (title == 'gj') {
        this.$refs.jiqunList.show({ title: '国家集群县', list: gj.gj })
      } else if (title == 'sheng') {
        this.$refs.jiqunList.show({ title: '省集群县汇总', list: gj.sheng })
      } else if (title == 'area') {
        let list = [
          { city: '廊坊市', area: '永清县' },
          { city: '廊坊市', area: '固安县' },
          { city: '承德市', area: '围场满族蒙古族自治县' },
          { city: '唐山市', area: '乐亭县' },
          { city: '唐山市', area: '玉田县' },
          { city: '秦皇岛', area: '昌黎县' },
          { city: '保定市', area: '定兴县' },
          { city: '保定市', area: '涿州市' },
          { city: '沧州市', area: '青县' },
          { city: '唐山市', area: '滦南县' },
          { city: '衡水市', area: '饶阳县' },
          { city: '张家口市', area: '崇礼区' },
          { city: '承德市', area: '丰宁满族自治县' },
          { city: '保定市', area: '清苑区' },
          { city: '邯郸市', area: '邯郸经济技术开发区' },
          { city: '衡水市', area: '阜城县' },
          { city: '石家庄市', area: '新乐市' },
          { city: '邯郸市', area: '馆陶县' },
          { city: '保定市', area: '望都县' },
          { city: '邯郸市', area: '鸡泽县' },
        ]
        this.$refs.jiqunList.show({ title: '重点发展县', list })
      } else {
        let obj = {
          type: 1,
          param: {
            // companyTypeId:val,
            typeName: title,
            // market:'1',//是否是市场标记 0是；1否
            // parentAreaCodes:''//区域id 12位
          },
          title,
        }
        this.$refs.enterpriseList.show(obj)
      }
    },
    lookServeList(title) {
      //查看服务企业列表
      let obj = {
        type: 3,
        param: {
          // companyTypeId:val,
          // market:'1',//是否是市场标记 0是；1否
          // parentAreaCodes:''//区域id 12位
          labelName: title,
        },
        title: '服务企业-' + title,
      }
      this.$refs.enterpriseList.show(obj)
    },
    lookBrand() {
      //查看品牌列表
      this.$refs.brandList.show()
    },
    lookBrandDetail(v) {
      //查看品牌展示
      let obj = {
        name: v.name, //品牌名称
        company_name: v.company_name, //品牌企业
        number: v.number, //品牌编号
        create_time: v.create_time, //时间
        imgUrl: v.img_url, //时间
        // classify:v.classify,//类型
      }
      this.$refs.brandDetail.show(obj)
    },
    lookMjcl() {
      //查看播种面积及产量
      this.mjclFlag = true
      this.$nextTick(() => {
        this.mjclReload()
      })
    },
    lookExpert() {
      //查看专家列表
      let obj = {
        teamid:
          'f0da74a918c844f983933d37c8b717303,f0da74a918c844f983933d37c8b7173032',
      }
      this.$refs.expertList.show(obj)
    },
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2

      let startRadian = startRatio * Math.PI * 2
      let endRadian = endRatio * Math.PI * 2
      let midRadian = midRatio * Math.PI * 2

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== 'undefined' ? k : 1 / 3

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u)
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u)
          }
          return Math.sin(v) > 0 ? 1 : -1
        },
      }
    },
    // 生成模拟 3D 饼图的配置项
    getPie3D(pieData, internalDiameterRatio) {
      let series = []
      let sumValue = 0
      let startValue = 0
      let endValue = 0
      let legendData = []
      let k =
        typeof internalDiameterRatio !== 'undefined'
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value

        let seriesItem = {
          name:
            typeof pieData[i].name === 'undefined'
              ? `series${i}`
              : pieData[i].name,
          type: 'surface',
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
        }

        if (typeof pieData[i].itemStyle != 'undefined') {
          let itemStyle = {}

          typeof pieData[i].itemStyle.color != 'undefined'
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null
          typeof pieData[i].itemStyle.opacity != 'undefined'
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null

          seriesItem.itemStyle = itemStyle
        }
        series.push(seriesItem)
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value

        series[i].pieData.startRatio = startValue / sumValue
        series[i].pieData.endRatio = endValue / sumValue
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          true,
          false,
          0.25
        )

        startValue = endValue

        legendData.push(series[i].name)
      }

      // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      series.push({
        name: 'mouseoutSeries',
        type: 'surface',
        parametric: true,
        wireframe: {
          show: false,
        },
        clockwise: false,
        itemStyle: {
          opacity: 0,
          color: 'rgba(18,236,252,.2)',
        },
        parametricEquation: {
          u: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },
          v: {
            min: 0,
            max: Math.PI,
            step: Math.PI / 1.4,
          },
          x: function (u, v) {
            return Math.sin(v) * Math.sin(u) + Math.sin(u)
          },
          y: function (u, v) {
            return Math.sin(v) * Math.cos(u) + Math.cos(u)
          },
          z: function (u, v) {
            return Math.cos(v) > 0 ? 0.1 : -0.1
          },
        },
      })

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        //animation: false,
        legend: {
          bottom: 30,
          textStyle: {
            color: '#fff',
            fontSize: 16,
            rich: {
              num1: {
                color: '#32EEFF',
                fontSize: 18,
                // padding: [0, 0, 0, 10],
                // width: 60,
                // borderColor:'#f00',
                // borderWidth: 2 ,
                // align:'right',
              },
            },
          },
          formatter: function (name) {
            // const data = option.series[0].pieData
            for (let i = 0; i < option.series.length; i++) {
              if (option.series[i].name === name) {
                return `${name} {num1|${option.series[i].pieData.value}万吨}`
              }
            }
          },
          data: legendData,
        },
        // tooltip: {
        //     formatter: params => {
        //         if (params.seriesName !== 'mouseoutSeries') {
        //             return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
        //         }
        //     }
        // },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 10,
          top: '-30%',
          left: '0',
          bottom: '50%',
          viewControl: {
            //3d效果可以放大、旋转等，请自己去查看官方配置
            alpha: 20,
            beta: 40,
            rotateSensitivity: 0,
            zoomSensitivity: 0,
            panSensitivity: 0,
            autoRotate: true,
            //   autoRotateSpeed: 5,
            //   autoRotateAfterStill: 10
          },
        },
        series: series,
      }
      return option
    },
    mjclInit() {
      //播种面积及产量
      this.mjcl = this.$echarts.init(document.getElementById('Mjcl'))
      let dataC1 = []
      let dataC2 = []
      let xData = []
      let legendData = ['面积', '产量']
      var fontColor = '#30eee9'
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '100',
          right: '10',
          top: '50',
          bottom: '30',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
            interval: 0,
          },
          data: xData,
        },
        yAxis: {
          name: '亩/吨',
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: '#3585d5',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: 20,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,168,255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,168,255,0)',
                  },
                ],
              },
            },
            data: dataC1,
          },
          {
            name: legendData[1],
            type: 'bar',

            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: 20,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(90,231,153,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(90,231,153,0)',
                  },
                ],
              },
            },
            data: dataC2,
          },
        ],
      }
      this.mjcl.setOption(option)
    },
    mjclReload() {
      //播种面积及产量重载
      let ref = document.getElementById('Mjcl')
      let width = ref.clientWidth
      let height = ref.clientHeight
      this.mjcl.resize({
        width: width,
        height: height,
      })
      let xdata = []
      let data1 = []
      let data2 = []
      this.bzmjData.forEach((item) => {
        xdata.push(item.cityName)
        data1.push(item.area * 15)
        data2.push(item.yield)
      })
      this.mjcl.setOption({
        xAxis: {
          data: xdata,
        },
        series: [
          {
            data: data1,
          },
          {
            data: data2,
          },
        ],
      })
    },
    countExpertData() {
      //产业人才
      api
        .countExpertData(
          {
            teamid:
              'f0da74a918c844f983933d37c8b717303,f0da74a918c844f983933d37c8b7173032',
          },
          {
            token: window.sessionStorage.token,
            autp: 2,
          }
        )
        .then((res) => {
          // console.log('产业人才')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let xData = []
          let legendData = []
          let seriesData = []
          res.data.forEach((item) => {
            if (xData.indexOf(item.membertype) == -1) {
              xData.push(item.membertype)
            }
            if (legendData.indexOf(item.teamname) == -1) {
              legendData.push(item.teamname)
            }
          })
          legendData.forEach((item) => {
            let arr = []
            res.data.forEach((subItem) => {
              if (subItem.teamname == item) {
                let index = xData.indexOf(subItem.membertype)
                arr[index] = subItem.val
              }
            })
            seriesData.push({
              name: item,
              val: arr,
            })
          })
          this.cyrcReload(xData, legendData, seriesData)
        })
    },
    cyrcInit() {
      //产业人才
      this.cyrc = this.$echarts.init(document.getElementById('Cyrc'))
      // let dataC1 = [120, 130, 160];
      // let dataC2 = [110, 210, 501];
      // let xData = ['首席', '岗位', '站长'];
      // let legendData = ['露地蔬菜专家', '设施蔬菜专家'];
      let dataC1 = []
      let dataC2 = []
      let xData = []
      let legendData = []
      var fontColor = '#30eee9'
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '50',
          right: '10',
          top: '50',
          bottom: '30',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          right: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
          },
          data: xData,
        },
        yAxis: {
          name: '人',
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: '#3585d5',
            },
          },
          min: 0,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          // {
          //     name: legendData[0],
          //     type: 'bar',
          //     smooth: true,
          //     symbol: 'none',
          //     showSymbol: false,
          //     symbolSize: 8,
          //     barWidth: 10,
          //     itemStyle: {
          //         color: {x: 0,y: 0,x2: 0,y2: 1,
          //             type: 'linear',
          //             global: false,
          //             colorStops: [
          //                 {
          //                     offset: 0,
          //                     color: 'rgba(0,168,255,1)',
          //                 },
          //                 {
          //                     offset: 1,
          //                     color: 'rgba(0,168,255,0)',
          //                 },
          //             ],
          //         },
          //     },
          //     data: dataC1,
          // },
          // {
          //     name: legendData[1],
          //     type: 'bar',
          //     smooth: true,
          //     symbol: 'none',
          //     showSymbol: false,
          //     symbolSize: 8,
          //     barWidth: 10,
          //     itemStyle: {
          //         color: {x: 0,y: 0,x2: 0,y2: 1,
          //             type: 'linear',
          //             global: false,
          //             colorStops: [
          //                 {
          //                     offset: 0,
          //                     color: 'rgba(90,231,153,1)',
          //                 },
          //                 {
          //                     offset: 1,
          //                     color: 'rgba(90,231,153,0)',
          //                 },
          //             ],
          //         },
          //     },
          //     data: dataC2,
          // },
        ],
      }
      this.cyrc.setOption(option)
    },
    cyrcReload(xData, legendData, seriesData) {
      //产业人才重载
      let arr = []
      let color = [
        [
          {
            offset: 0,
            color: 'rgba(0,168,255,1)',
          },
          {
            offset: 1,
            color: 'rgba(0,168,255,0)',
          },
        ],
        [
          {
            offset: 0,
            color: 'rgba(90,231,153,1)',
          },
          {
            offset: 1,
            color: 'rgba(90,231,153,0)',
          },
        ],
      ]
      seriesData.forEach((item, i) => {
        arr.push({
          name: item.name,
          type: 'bar',

          smooth: true,
          symbol: 'none',
          showSymbol: false,
          symbolSize: 8,
          barWidth: 10,
          itemStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              type: 'linear',
              global: false,
              colorStops: color[i],
            },
          },
          data: item.val,
        })
      })
      this.cyrc.setOption({
        legend: {
          data: legendData,
        },
        xAxis: {
          data: xData,
        },
        series: arr,
      })
    },
    arrowClick(val) {
      //上下切换
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    roll: function () {
      //服务企业滚动
      this.animate = true
      setTimeout(() => {
        this.fwqyData.push(this.fwqyData[0])
        this.fwqyData.shift()
        this.animate = false
      }, 500)
    },
    fwqyEnter() {
      //服务企业进入
      // console.log('县蔬菜基地明细进入')
      clearInterval(this.fwqyTimer)
    },
    fwqyOut() {
      //服务企业移出
      // console.log('县蔬菜基地明细移出')
      if (this.fwqyData.length > 4) {
        this.fwqyTimer = setInterval(this.roll, 5000)
      }
    },
    toDetail() {
      this.detailFlag = false;
      setTimeout(() => {
        this.handleChange(this.checkedAll, 'all');
      }, 0)
    },
    backHome() {
      this.detailFlag = true;
    }
  },
}
</script>
<style lang='scss' scoped>
.gk {
  // border: 3px solid #f00;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 30px;
  position: relative;

  .left {
    // border: 2px solid #0f0;
    width: 400px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 225px;
      flex: none;

      // border: 2px solid #0f0;
      .content {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;

        >li {
          width: 25%;
          box-sizing: border-box;
          text-align: center;
          margin-top: 25px;

          >div {
            font-size: 24px;
            cursor: pointer;

            // font-weight: 600;
            span {
              font-size: 14px;
            }
          }

          p {
            margin-top: 5px;
            color: #b9cce5;
            font-size: 16px;
            // font-weight: 600;
          }
        }

        li:nth-child(1) {
          >div {
            color: #32eeff;
          }
        }

        li:nth-child(2) {
          >div {
            color: #ffca28;
          }
        }

        li:nth-child(4) {
          >div {
            color: #ff8611;
          }
        }

        li:nth-child(5) {
          >div {
            color: #2cc6ff;
          }
        }

        li:nth-child(6) {
          >div {
            color: #5ae799;
          }
        }
      }
    }

    .middle {
      // border: 2px solid #f00;
      height: 300px;
      flex: none;
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      .pub_title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span {
          cursor: pointer;
        }
      }

      .content {
        // border: 1px solid #f00;
        flex: 1;
        overflow: hidden;

        .el-carousel {
          padding: 20px 30px;
          box-sizing: border-box;
          height: 100%;

          ::v-deep.el-carousel__container {
            height: 100%;
          }
        }

        ::v-deep.el-carousel__indicators {
          bottom: 5px;

          .el-carousel__indicator--horizontal {
            padding: 0 4px;

            .el-carousel__button {
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }
        }

        .list {
          height: 100%;

          .item {
            // border:1px solid #0f0;
            height: 35px;
            display: flex;
            align-items: center;
            background-color: rgba(21, 78, 149, 0.3);
            margin-bottom: 10px;
            padding: 0 15px;
            box-sizing: border-box;
            position: relative;

            span {
              font-size: 16px;
              display: inline-block;
            }

            span:nth-child(1) {
              color: #fff;
              // border:1px solid #f0f;
              width: 30%;
            }

            span:nth-child(2) {
              color: #2cc6ff;
              // border:1px solid #0f0;
              width: 40%;
            }

            span:nth-child(3) {
              color: #2cc6ff;
              // border:1px solid #0ff;
              width: 30%;
            }

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              left: 0;
              width: 2px;
              height: 15px;
              background-color: #2cc6ff;
            }
          }

          .first {
            background-color: rgba(154, 85, 18, 0.2);

            &::before {
              background-color: #ff8611;
            }
          }

          .second {
            background-color: rgba(148, 115, 16, 0.2);

            &::before {
              background-color: #ffca28;
            }
          }

          .third {
            background-color: rgba(22, 174, 90, 0.2);

            &::before {
              background-color: #5ae799;
            }
          }
        }
      }
    }

    .bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;

      .pie_bg {
        position: absolute;
        position: absolute;
        bottom: 100px;
        left: 38px;
        z-index: 0;
        transform: rotateX(40deg);
      }

      .chart {
        flex: 1;
        z-index: 1;
        // border: 1px solid #f00;
      }
    }
  }

  .center {
    // border: 2px solid #f0f;
    flex: 1;
    margin: 0 10px;
    // background: url('~@/assets/images/cygk/gk_map.png') no-repeat center/100%
    //   100%;
    position: relative;

    .info {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-around;
      z-index: 10;
      background: #012e68;
      width: 100%;
      padding: 10px;

      li {
        text-align: center;
        margin: 0 25px;
        // border: 1px solid #0f0;
        flex: none;

        span {
          color: #aae5ed;
          font-size: 22px;

          i {
            font-size: 18px;
          }
        }

        p {
          font-size: 32px;
          font-weight: 700;
          margin-top: 10px;
        }
      }
    }

    .pub_legend {
      .legend_title {
        display: flex;
        justify-content: space-between;
      }

      .legend_list {
        padding: 10px;
        box-sizing: border-box;

        >li {
          font-size: 16px;
          // border: 1px solid #0f0;
          height: 30px;
          display: flex;
          align-items: center;
          // padding-right: 0px;
          box-sizing: border-box;
          cursor: pointer;

          span {
            display: inline-block;
            width: 22px;
            height: 13px;
            box-sizing: border-box;
            margin-right: 10px;
          }

          >img {
            height: 25px;
            text-align: center;
            margin-right: 10px;
          }

          .pub_checkbox {
            margin-right: 10px;
          }
        }

        >li:nth-child(1) {
          span {
            background-color: #2797ff;
            border: 1px dashed #b3fe00;
          }
        }

        >li:nth-child(2) {
          span {
            background-color: #ffe600;
            border: 1px dashed #ffe600;
          }
        }

        >li:nth-child(3) {
          span {
            background-color: #00ffd9;
            border: 1px dashed #ffeb0a;
          }
        }

        >li:nth-child(4) {
          span {
            background-color: #27e000;
            border: 1px dashed #14fd42;
          }
        }

        >li:nth-child(7) {
          img {
            position: relative;
            left: 5px;
          }
        }
      }
    }

    .pub_legend1 {
      padding: 10px;
      background-color: rgba(0, 23, 55, 0.43);
      border: 2px solid #00eaff;
      position: absolute;
      right: 20px;
      top: 120px;
      width: 200px;

      .tit {
        font-size: 17px;
        font-weight: 600;
        color: #32eeff;
      }

      .lis {
        // display: flex;
        margin: 10px 0;
        // flex-wrap: wrap;
        line-height: 20px;
        padding-left: 10px;
      }
    }

    .gk_boat {
      position: absolute;
      bottom: 370px;
      right: 300px;
      // animation: cloudFlow 10s 0.2s linear infinite;
    }

    .gk_cloud1 {
      position: absolute;
      bottom: 230px;
      left: 80px;
      animation: cloudFlow 10s 0.2s linear infinite;
    }

    @keyframes cloudFlow {
      0% {
        transform: translateX(0);
      }

      25% {
        transform: translateX(-20px);
      }

      75% {
        transform: translateX(20px);
      }

      100% {
        transform: translateX(0);
      }
    }

    .gk_cloud2 {
      position: absolute;
      top: 160px;
      right: 180px;
      animation: cloudFlow 10s 0.2s linear infinite;
    }

    .map_marker {
      position: absolute;
    }

    .hjj_map {
      top: 325px;
      left: 390px;
    }

    .jd_map {
      top: 310px;
      right: 220px;
    }

    .jzn_map {
      bottom: 230px;
      left: 290px;
    }

    .jb_map {
      top: 140px;
      left: 250px;
    }

    .sfyq {
      .sfyq_jb {
        img:nth-child(1) {
          top: 150px;
          left: 295px;
        }

        img:nth-child(2) {
          top: 203px;
          left: 250px;
        }

        img:nth-child(3) {
          top: 198px;
          left: 310px;
        }

        img:nth-child(4) {
          top: 175px;
          left: 405px;
        }

        img:nth-child(5) {
          top: 220px;
          left: 440px;
        }

        img:nth-child(6) {
          top: 185px;
          left: 520px;
        }

        img:nth-child(7) {
          top: 225px;
          left: 620px;
        }

        img:nth-child(8) {
          top: 180px;
          left: 625px;
        }

        img:nth-child(9) {
          top: 120px;
          left: 635px;
        }
      }

      .sfyq_jd {
        img:nth-child(1) {
          top: 300px;
          right: 380px;
        }

        img:nth-child(2) {
          top: 300px;
          right: 340px;
        }

        img:nth-child(3) {
          top: 335px;
          right: 330px;
        }

        img:nth-child(4) {
          top: 285px;
          right: 285px;
        }

        img:nth-child(5) {
          top: 310px;
          right: 285px;
        }

        img:nth-child(6) {
          top: 340px;
          right: 295px;
        }

        img:nth-child(7) {
          top: 315px;
          right: 245px;
        }

        img:nth-child(8) {
          top: 345px;
          right: 245px;
        }

        img:nth-child(9) {
          top: 285px;
          right: 220px;
        }
      }

      .sfyq_jzn {
        img:nth-child(1) {
          bottom: 400px;
          left: 280px;
        }

        img:nth-child(2) {
          bottom: 410px;
          left: 310px;
        }

        img:nth-child(3) {
          bottom: 380px;
          left: 310px;
        }

        img:nth-child(4) {
          bottom: 390px;
          left: 330px;
        }

        img:nth-child(5) {
          bottom: 410px;
          left: 340px;
        }

        img:nth-child(6) {
          bottom: 375px;
          left: 362px;
        }

        img:nth-child(7) {
          bottom: 390px;
          left: 410px;
        }

        img:nth-child(8) {
          bottom: 365px;
          left: 475px;
        }

        img:nth-child(9) {
          bottom: 355px;
          left: 440px;
        }

        img:nth-child(10) {
          bottom: 325px;
          left: 445px;
        }

        img:nth-child(11) {
          bottom: 290px;
          left: 300px;
        }

        img:nth-child(12) {
          bottom: 285px;
          left: 315px;
        }

        img:nth-child(13) {
          bottom: 270px;
          left: 290px;
        }

        img:nth-child(14) {
          bottom: 260px;
          left: 285px;
        }

        img:nth-child(15) {
          bottom: 255px;
          left: 310px;
        }

        img:nth-child(16) {
          bottom: 242px;
          left: 295px;
        }

        img:nth-child(17) {
          bottom: 232px;
          left: 315px;
        }

        img:nth-child(18) {
          bottom: 235px;
          left: 340px;
        }
      }

      .sfyq_hjj {
        img:nth-child(1) {
          bottom: 535px;
          left: 565px;
        }

        img:nth-child(2) {
          bottom: 515px;
          left: 555px;
        }

        img:nth-child(3) {
          bottom: 490px;
          left: 505px;
        }

        img:nth-child(4) {
          bottom: 490px;
          left: 470px;
        }

        img:nth-child(5) {
          bottom: 500px;
          left: 440px;
        }

        img:nth-child(6) {
          bottom: 485px;
          left: 430px;
        }

        img:nth-child(7) {
          bottom: 465px;
          left: 415px;
        }

        img:nth-child(8) {
          bottom: 440px;
          left: 390px;
        }

        img:nth-child(9) {
          bottom: 420px;
          left: 400px;
        }

        img:nth-child(10) {
          bottom: 400px;
          left: 430px;
        }

        img:nth-child(11) {
          bottom: 390px;
          left: 465px;
        }

        img:nth-child(12) {
          bottom: 410px;
          left: 520px;
        }
      }
    }

    .zdxm {
      img:nth-child(1) {
        top: 350px;
        left: 295px;
      }

      img:nth-child(2) {
        top: 425px;
        left: 272px;
      }

      img:nth-child(3) {
        top: 415px;
        left: 305px;
      }

      img:nth-child(4) {
        top: 345px;
        left: 420px;
      }

      img:nth-child(5) {
        top: 355px;
        left: 400px;
      }

      img:nth-child(6) {
        top: 385px;
        left: 390px;
      }

      img:nth-child(7) {
        top: 415px;
        left: 415px;
      }

      img:nth-child(8) {
        top: 410px;
        left: 520px;
      }

      img:nth-child(9) {
        top: 480px;
        left: 380px;
      }

      img:nth-child(10) {
        top: 535px;
        left: 295px;
      }

      img:nth-child(11) {
        top: 570px;
        left: 340px;
      }
    }

    .ltqy {
      img:nth-child(1) {
        top: 230px;
        left: 310px;
      }

      img:nth-child(2) {
        top: 435px;
        left: 290px;
      }

      img:nth-child(3) {
        top: 560px;
        left: 290px;
      }

      img:nth-child(4) {
        top: 560px;
        left: 315px;
      }

      img:nth-child(5) {
        top: 570px;
        left: 335px;
      }

      img:nth-child(6) {
        top: 350px;
        left: 445px;
      }

      img:nth-child(7) {
        top: 260px;
        left: 635px;
      }

      img:nth-child(8) {
        top: 200px;
        left: 685px;
      }

      img:nth-child(9) {
        top: 325px;
        left: 685px;
      }

      img:nth-child(10) {
        top: 305px;
        left: 795px;
      }
    }

    .detailBtn {
      position: absolute;
      top: 120px;
      left: 20px;
      width: 86px;
      height: 51px;
      background: url('~@/assets/images/cygk/detailBox.png') no-repeat center/100% 100%;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;
    }

    .backHome {
      position: absolute;
      top: 120px;
      left: 20px;
      width: 86px;
      height: 51px;
      background: url('~@/assets/images/cygk/detailBox.png') no-repeat center/100% 100%;
      font-size: 20px;
      z-index: 10;

      img {
        width: 24px;
        height: 24px;
      }

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .detailPage {
      position: relative;
      top: 99px;
      width: 100%;
    }

  }

  .right {
    // border: 2px solid #ff0;
    width: 400px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      // border: 2px solid #f00;
      height: 350px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        // border: 1px solid #0f0;
        .list {
          display: flex;

          >li {
            flex: 1;
            height: 45px;
            background: url('~@/assets/images/cyrc_bg.png') no-repeat center/100% 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;
            justify-content: space-between;

            >div {
              color: #b9cce5;
              font-size: 16px;
            }

            span {
              color: #32eeff;
              font-size: 24px;
              cursor: pointer;

              i {
                font-size: 14px;
              }
            }
          }

          li:first-child {
            margin-right: 20px;
          }
        }

        .chart {
          flex: 1;
          // border: 1px solid #f00;
        }
      }
    }

    .middle {
      margin: 10px 0;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        overflow: hidden;
        // border: 2px solid #f0f;
        display: flex;
        flex-direction: column;
        padding: 10px 20px 20px;
        box-sizing: border-box;

        .info {
          // border: 2px solid #f00;
          box-sizing: border-box;
          background: url('~@/assets/images/cxtd_bg.png') no-repeat center/100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;

          p {
            font-size: 16px;
            color: #b9cce5;
            margin-right: 40px;
          }

          span {
            color: #32eeff;
            font-size: 24px;

            i {
              font-size: 14px;
            }
          }
        }

        .wrap {
          overflow: hidden;
          flex: 1;
          // border: 1px solid #0f0;
        }

        .marquee_top {
          transition: all 1s;
          margin-top: -40px;
        }

        ::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .bottom {
      // border: 2px solid #ff0;
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 10px 50px;
        box-sizing: border-box;
        overflow: hidden;

        .wrap {
          background: url('~@/assets/images/jppp_border.png') no-repeat center/100% 100%;
          height: 200px;
          padding: 0 50px;
          box-sizing: border-box;
          position: relative;

          .inner {
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            h3 {
              font-size: 20px;
              background: url('~@/assets/images/jppp_bg.png') no-repeat center/100% 100%;
              margin-top: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            img {
              max-width: 100%;
              height: 90px;
              margin: 10px 0;
            }

            p {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }

          .left_arrow {
            left: 8px;
          }

          .right_arrow {
            right: 5px;
          }

          ::v-deep.el-carousel__button {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #25b0ff;
          }
        }
      }
    }
  }

  .mjcl_dialog {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;

    .chart {
      width: 100%;
      height: 100%;
      // border: 5px solid #f00;
    }
  }

  .right_middle_list {
    // >ul{
    height: 100%;
    // border: 2px solid #0f0;
    box-sizing: border-box;
    // overflow-y: auto;
    overflow: hidden;
    margin-top: 10px;

    >li {
      display: flex;
      align-items: center;
      margin-top: 10px;

      // border: 1px solid #0f0;
      label {
        color: #e1edfa;
        font-size: 16px;
        display: inline-block;
        width: 80px;
        flex: none;
        // border: 1px solid #0f0;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .rank {
        background: rgba(17, 67, 175, 0.29);
        transform: skew(-40deg);
        // border: 1px solid #f0f;
        flex: 1;
        margin: 0 10px;
        box-sizing: border-box;

        .percent {
          height: 14px;
          background: linear-gradient(90deg, rgba(3, 131, 251, 0), #0383fb);
          position: relative;

          &:before {
            content: '';
            position: absolute;
            right: 0;
            width: 2px;
            height: 11px;
            background: linear-gradient(0deg, #d7ffff, #abffff);
          }
        }
      }

      span {
        font-size: 16px;
        color: #32eeff;
        display: inline-block;
        width: 60px;
        flex: none;

        // border: 1px solid #0f0;
        i {
          font-size: 14px;
        }
      }
    }

    li:first-child {
      margin-top: 0;
    }

    // }
  }

  .fwqy_dialog {
    // border: 3px solid #f00;
    padding: 20px;
    box-sizing: border-box;
  }

  .xsl_dialog {
    // border: 3px solid #f00;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .chart {
      width: 800px;
      height: 740px;
      // border: 3px solid #0f0;
      position: relative;
      z-index: 1;
    }

    .pie_bg {
      position: absolute;
      bottom: 300px;
      width: 800px;
      left: 10px;
      z-index: 0;
      transform: rotateX(40deg);
    }
  }
}
</style>