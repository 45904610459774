<template>
  <div class="bjjd">
    <div class="left">
      <div class="pub_border top">
        <div class="pub_title" @click="bjjdgkFlag = true">供京基地概况</div>
        <div class="chart" id="Bjjdgk"></div>
      </div>
      <div class="pub_border middle">
        <div class="pub_title">供京基地各品种种植情况</div>
        <div class="content">
          <ul class="list">
            <el-select v-model="currZzqk" class="zz_select" placeholder="请选择" @change="zzqkSelect">
              <el-option v-for="item in zzqkData" :label="item.name" :value="item.id" :key="item.id"></el-option>
            </el-select>
            <img src="./../../assets/images/pzzz_circle.png" alt />
            <li>
              <div>
                <p>本年面积</p>
                <span>
                  {{ currZzqkObj.area || 0 }}
                  <i>吨</i>
                </span>
              </div>
              <img src="./../../assets/images/pzzz_bottom.png" alt />
            </li>
            <li>
              <div>
                <p>本年产量</p>
                <span>
                  {{ currZzqkObj.yearYield || 0 }}
                  <i>吨</i>
                </span>
              </div>
              <img src="./../../assets/images/pzzz_bottom.png" alt />
            </li>
            <li>
              <div>
                <p>当月产量</p>
                <span>
                  {{ currZzqkObj.monthYield || 0 }}
                  <i>吨</i>
                </span>
              </div>
              <img src="./../../assets/images/pzzz_bottom.png" alt />
            </li>
            <li>
              <div>
                <p>今日产量</p>
                <span>
                  {{ currZzqkObj.todayYield || 0 }}
                  <i>吨</i>
                </span>
              </div>
              <img class="bottom_img" src="./../../assets/images/pzzz_bottom.png" alt />
            </li>
            <li>
              <img :src="sysUrl + currZzqkObj.url" alt />
            </li>
          </ul>
        </div>
      </div>
      <div class="pub_border bottom">
        <div class="pub_title">供京基地近期供应情况</div>
        <div class="content">
          <div class="wrap">
            <ul :class="{ marquee_top: animate }" @mouseenter="scjdEnter" @mouseleave="scjdOut">
              <li v-for="(item, i) in todaySupply">
                <span>{{ item.company_name }}</span>
                <span>供应</span>
                <span>
                  {{ item.plant_name }}
                  <i>{{ item.yield }}吨</i>
                </span>
              </li>
              <!-- <li>
                                <span>定兴县蔬菜专</span>
                                <span>供应</span>
                                <span>黄瓜<i>195吨</i></span>
                            </li>
                            <li>
                                <span>石家庄市</span>
                                <span>供应</span>
                                <span>黄瓜<i>195吨</i></span>
                            </li>
                            <li>
                                <span>定兴县蔬菜专业合作社</span>
                                <span>供应</span>
                                <span>黄瓜<i>195吨</i></span>
              </li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="centerMenu">
        <div>
          <div class="label">县总数(个)</div>
          <div class="number">{{ countyTotal }}</div>
        </div>
        <div>
          <div class="label">种植面积(亩)</div>
          <div class="number">{{ areaTotal }}</div>
        </div>
        <div>
          <div class="label">种植品种(种)</div>
          <div class="number">{{ varietiesTotal }}</div>
        </div>
        <div>
          <div class="label">产量(吨)</div>
          <div class="number">{{ yieldTotal }}</div>
        </div>
        <div>
          <div class="label">企业总数(个)</div>
          <div class="number">{{ qiyeTotal }}</div>
        </div>
      </div>
      <plant-map ref="plantMapRef" has-drop height="83%" :hasZoom="true" :chart-option="chartOption" @selectCity="mapSelect"
        @selectMarker="markerSelect" class="plantMap"></plant-map>
    </div>
    <div class="right">
      <div class="pub_border top">
        <div class="pub_title" @click="bjgyqkFlag = true">供京基地品种近期供应情况</div>
        <div class="chart" id="Bjgyqk"></div>
      </div>
      <div class="pub_border middle">
        <div class="pub_title" @click="pzgyzsFlag = true">品种供应走势</div>
        <div class="chart" id="Pzgyzs"></div>
      </div>
      <div class="pub_border bottom">
        <div class="pub_title">品牌建设</div>
        <div class="content">
          <div class="wrap">
            <img @click="arrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow" alt />
            <el-carousel ref="cardShow" height="205px" arrow="never" indicator-position="none">
              <el-carousel-item v-for="(item, i) in ppjsData" :key="item.company_id + i">
                <div class="inner" @click="lookBrandDetail(item)">
                  <h3>{{ item.name }}</h3>
                  <img :src="sysUrl + item.img_url" @error="e => { e.target.src = defaultImg }" alt />
                  <p>{{ item.company_name }}</p>
                </div>
              </el-carousel-item>
              <!-- <el-carousel-item v-for="item in 3" :key="item">
                                    <div class="inner">
                                        <h3>“西红柿”中国驰名商标</h3>
                                        <img src="./../../assets/images/jppp.png" alt="">
                                        <p>公司名称公司名称</p>
                                    </div>
              </el-carousel-item>-->
            </el-carousel>
            <img @click="arrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 企业详情 -->
    <Enterprise-info ref="enterpriseRef"></Enterprise-info>
    <!-- 企业列表 -->
    <enterprise-list ref="enterpriseList"></enterprise-list>
    <!-- 今日供应列表 -->
    <supply-list ref="supplyList"></supply-list>
    <!-- 品牌列表 -->
    <!-- <brand-list ref="brandList"></brand-list> -->
    <!-- 品牌展示 -->
    <brand-detail ref="brandDetail"></brand-detail>
    <!-- 供京基地概况弹框 -->
    <base-dialog width="1200px" height="500px" label="供京基地概况" :show-close="true" v-show="bjjdgkFlag"
      @closeDialog="bjjdgkFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="BjjdgkDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
    <!-- 供京基地品种今日供应情况弹框 -->
    <base-dialog width="1200px" height="500px" label="供京基地品种今日供应情况" :show-close="true" v-show="bjgyqkFlag"
      @closeDialog="bjgyqkFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="BjgyqkDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
    <!-- 保品种供应走势弹框 -->
    <base-dialog width="1200px" height="500px" label="品种供应走势" :show-close="true" v-show="pzgyzsFlag"
      @closeDialog="pzgyzsFlag = false">
      <div class="zzqk_dialog">
        <div class="chart" id="PzgyzsDialog" style="width:1200px;height:400px;"></div>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import plantMap from '@/components/map/mapCopy'
import * as api from '@/api/cygk' //接口
// import { imageUrl } from '@/utils/config'
import EnterpriseList from '@/components/enterpriseList' //企业列表
import SupplyList from '@/components/supplyList' //今日供应列表
import BrandList from '@/components/brandList' //品牌列表
import BrandDetail from '@/components/brandDetail' //品牌展示
import { cityList } from '@/api/dialog' //接口
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import EnterpriseInfo from '@/components/enterprise' //企业信息

export default {
  components: {
    plantMap,
    EnterpriseList,
    SupplyList,
    BrandList,
    BrandDetail,
    BaseDialog,
    EnterpriseInfo,
  },
  data() {
    return {
      qiyeTotal: 0,
      countyTotal: 0,
      areaTotal: 0,
      varietiesTotal: 0,
      yieldTotal: 0,
      bjjdgk: '', //供京基地概况
      bjjdgkFlag: '', //供京基地概况
      bjjdgkDialog: '', //供京基地概况
      pzgyzs: '', //品种供应走势
      pzgyzsFlag: '', //品种供应走势
      pzgyzsDialog: '', //品种供应走势
      bjgyqk: '', //供京基地品种今日供应情况
      bjgyqkFlag: '', //供京基地品种今日供应情况
      bjgyqkDialog: '', //供京基地品种今日供应情况
      currCityObj: {}, //当前地图层级信息
      bjgyqkData: [], //供京基地品种今日供应情况
      ppjsData: [], //品牌建设
      zzqkData: [], //各品种种植情况
      currZzqk: '', //选中的各品种种植情况
      currZzqkObj: {}, //选中的各品种种植情况
      todaySupply: [], //供京基地每日供应情况
      todayTimer: '', //每日供应情况定时器
      animate: false,
      areaCode: '', //区域
      // imageUrl,
      // qyFlag:false,//企业列表弹框
      supplyFlag: false, //今日供应列表弹框
      // brandFlag:false,//品牌列表弹框
      brandDetailFlag: false, //品牌展示弹框
      sysUrl,
      defaultImg: require('@/assets/images/default_img.png'),
      chartOption: {
        layoutSize: '85%',
        tooltip: (params) => {
          let data = params.data
          let html = `<div style="padding:0;width: 280px;height: 120px;background: rgba(2,18,81,0.68);">
                            <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name
            }</div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''
            }</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''
            }</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>供应量：<span style="color:#0CFED9;font-size: 18px">${data.yield || 0
            }</span>吨</span></div>
                            <!--<div style="margin-left: 1em;line-height: 30px"><span>时间：<span style="color:#0CFED9;font-size: 18px">${data.plant_time
              ? data.plant_time.substr(0, 10)
              : ''
            }</span></span></div>-->
                        </div>`
          return html
        },
      },
    }
  },
  mounted() {
    this.mapList()
    this.selectEveryPlantList()
    this.selectBrandList()
    this.selectBaojingSurvey()
    this.selectTodaySupply()
    this.selectTodayTypeSumList()
    this.selectLast()
    this.Bjgetcountlist()
    this.bjjdgkInit('bjjdgk', 'Bjjdgk')
    this.bjjdgkInit('bjjdgkDialog', 'BjjdgkDialog')
    this.pzgyzsInit('pzgyzs', 'Pzgyzs')
    this.pzgyzsInit('pzgyzsDialog', 'PzgyzsDialog')
    this.bjgyqkInit('bjgyqk', 'Bjgyqk')
    this.bjgyqkInit('bjgyqkDialog', 'BjgyqkDialog')
  },
  methods: {
    mapSelect(obj) {
      // 地图市区县选择
      // console.log('地图市区县选择')
      // console.log(JSON.parse(JSON.stringify(obj)))
      this.currCityObj = obj
      if (obj.code || obj.code == '') {
        // console.log('地级市')
        this.areaCode = obj.code
        this.mapList()
        this.selectEveryPlantList()
        this.Bjgetcountlist()
        this.selectBrandList()
        this.selectBaojingSurvey()
        this.selectTodaySupply()
        this.selectTodayTypeSumList()
        this.selectLast()
      } else {
        // console.log('县')
      }
    },
    // marker点击
    markerSelect(obj) {
      this.$refs.enterpriseRef.show(obj)
    },
    mapList() {
      //基地列表
      api
        .selectBaojingList({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('基地列表')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          /*let data = res.data.map((item) => {
            return {
              value: [item.longitude, item.latitude],
              ...item,
            }
          })
          this.$refs.plantMapRef.addMarker([
            {
              name: '供京基地',
              url:
                'image://' +
                require('./../../assets/images/qyfb_marker.png'),
              size: [39, 51],
              data: data,
            },
          ])*/

         /* let marker = []
          res.data.forEach(item => {
            let obj = {
              name: item.name,
              url:
                      'image://' +
                      require('./../../assets/images/qyfb_marker.png'),
              size: [39, 51],
              data: [{
                value: [item.longitude, item.latitude],
                ...item
              }]
            }
            marker.push(obj)
          })*/
          var data = res?.data?.filter(item => !item.name.includes('京津冀蔬菜'))?.filter(item => !item.name.includes('高村春光'))?.filter(item => !item.name.includes('大尹村镇设施农'))??[]
          var data1 = []
          res?.data?.forEach(item => {
            if(item.name.includes('京津冀蔬菜') ||  item.name.includes('高村春光')|| item.name.includes('大尹村镇设施农')) {
              data1.push(item)
            }
          })??[]
          var marker = data?.map(item => {
            return {
              name: item.name,
              url: 'image://' + require('./../../assets/images/qyfb_marker.png'),
              size: [39, 51],
              type: 1,
              data: [{
                value: [item.longitude, item.latitude],
                ...item
              }]
            }
          })
          var marker1 = []
          console.log(marker1,'marker1----')
          this.$refs.plantMapRef.addMarker([...marker,...marker1].filter(item => ((item?.data??[{}])[0]?.longitude??null) && ((item?.data??[{}])[0]?.latitude??null)),'2')
          this.qiyeTotal = [...marker,...marker1].length
            // this.$refs.plantMapRef.addMarker([...marker1].filter(item => ((item?.data??[{}])[0]?.longitude??null) && ((item?.data??[{}])[0]?.latitude??null)))
        })
        .catch(() => { })
    },
    Bjgetcountlist() {

      api
        .Bjgetcountlist({
          areaCode: this.areaCode,
        })
        .then((res) => {
          this.countyTotal = res.code == 200 ? res.data && res.data.jd : 0
          this.areaTotal = res.code == 200 ? res.data && res.data.area : 0
          this.varietiesTotal = res.code == 200 ? res.data && res.data.varieties : 0
          this.yieldTotal = res.code == 200 ? res.data && res.data.yield : 0




        })
    },
    selectEveryPlantList() {
      //各品种种植情况
      api
        .selectEveryPlantList({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('各品种种植情况')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.zzqkData = res.data || []
          if (this.zzqkData.length != 0) {
            this.currZzqk = this.zzqkData[0].id
            this.currZzqkObj = this.zzqkData[0]
          } else {
            this.currZzqkObj = {}
            this.currZzqk = ''
          }
        })
        .catch(() => {
          this.zzqkData = []
          this.currZzqkObj = {}
          this.currZzqk = ''
        })
    },
    zzqkSelect(val) {
      //各品种种植情况选择
      // console.log('各品种种植情况选择');
      // console.log(val);
      for (let i = 0; i < this.zzqkData.length; i++) {
        if (val == this.zzqkData[i].id) {
          this.currZzqkObj = this.zzqkData[i]
          break
        }
      }
    },
    selectBrandList() {
      //品牌列表
      api
        .selectBrandList({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('品牌列表')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.ppjsData = res.data || []
        })
        .catch(() => {
          this.ppjsData = []
        })
    },
    selectBaojingSurvey() {
      //概况
      api
        .selectBaojingSurvey({
          areaCode: this.areaCode,
        })
        .then((res) => {
          console.log(res, "123baojing")
          // console.log('概况')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let xData = res.data.xData
          let dataC1 = res.data.yData['面积']
          let dataC2 = res.data.yData['个数']
          console.log(dataC2, "132ge")
          this.qiyeTotal = 0
          /*dataC2.filter(item => {
            return this.qiyeTotal += item
          })*/
          this.bjjdgkReload(xData, dataC1, dataC2, 'bjjdgk')
          this.bjjdgkReload(xData, dataC1, dataC2, 'bjjdgkDialog')
        })
        .catch(() => {
          this.bjjdgkReload([], [], [], 'bjjdgk')
          this.bjjdgkReload([], [], [], 'bjjdgkDialog')
        })
    },
    selectTodaySupply() {
      //每日供应情况
      api
        .selectTodaySupply({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('每日供应情况')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.todaySupply = res.data || []
          clearInterval(this.todayTimer)
          if (this.todaySupply.length > 4) {
            this.todayTimer = setInterval(this.roll, 5000)
          }
        })
        .catch(() => {
          this.todaySupply = []
        })
    },
    selectTodayTypeSumList() {
      //今日各品类供应图
      api
        .selectTodayTypeSumList({
          areaCode: this.areaCode,
        })
        .then((res) => {
          // console.log('今日各品类供应图')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.bjgyqkData = res.data || []
          let dataX = []
          let data = []
          let maxData = []
          res.data.forEach((item) => {
            dataX.push(item.plant_name)
            data.push(Number(item.yield))
          })
          let maxNum = Math.max(...data)
          data.forEach((item) => {
            maxData.push(maxNum)
          })
          this.bjgyqkReload(dataX, data, maxData, 'bjgyqk')
          this.bjgyqkReload(dataX, data, maxData, 'bjgyqkDialog')
        })
        .catch(() => {
          this.bjgyqkReload([], [], [], 'bjgyqk')
          this.bjgyqkReload([], [], [], 'bjgyqkDialog')
        })
    },
    selectLast(Name) {
      //品种供应走势
      api
        .selectLast12MonthsTrend({
          areaCode: this.areaCode,
          plantName: Name || '',
        })
        .then((res) => {
          // console.log('品种供应走势')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          let xData = res.data.xData
          let legendData = Object.keys(res.data.yData)
          let seriesData = []
          for (const key in res.data.yData) {
            seriesData.push({
              name: key,
              type: 'line',
              data: res.data.yData[key],
            })
          }
          this.pzgyzsReload(xData, legendData, seriesData, 'pzgyzs')
          this.pzgyzsReload(xData, legendData, seriesData, 'pzgyzsDialog')
        })
        .catch(() => {
          this.pzgyzsReload([], [], [], 'pzgyzs')
          this.pzgyzsReload([], [], [], 'pzgyzsDialog')
        })
    },
    bjjdgkInit(Init, Id) {
      //供京基地概况
      this[Init] = this.$echarts.init(document.getElementById(Id))
      // let dataC1 = [120, 130, 160,120, 130, 160,120, 130];
      // let dataC2 = [110, 210, 501,110, 210, 501,110, 210];
      // let xData = ['永清', '固安', '滦南','乐亭','饶阳','肃宁','青县','定兴'];
      let dataC1 = []
      let dataC2 = []
      let xData = []
      let legendData = ['面积', '个数']
      // var fontColor = '#30eee9';
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '60',
          right: '10',
          top: '40',
          bottom: '40',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
            // interval: 0 ,
          },
          data: xData,
        },
        yAxis: {
          name: '亩',
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: '#3585d5',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: Init == 'bjjdgk' ? 10 : 20,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,168,255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,168,255,0)',
                  },
                ],
              },
            },
            data: dataC1,
          },
          {
            name: legendData[1],
            data: dataC2,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#0BFFD5',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#0BFFD5',
                // borderColor: 'rgba(255, 234, 0, 0.5)', //圆点透明 边框
                // borderWidth: 5,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(11,255,213,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(11,255,213,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      this[Init].setOption(option)
      if (Init == 'bjjdgk') {
        this.bjjdgk.on('click', (params) => {
          cityList({
            name: params.name,
          }).then((res) => {
            // console.log('地区列表')
            // console.log(JSON.parse(JSON.stringify(res.rows)))
            let code = res.rows[0].cityId
            let parent = {
              code: res.rows[0].parentId,
            }
            let obj = {
              type: 2,
              parentInfo: parent,
              param: {
                // companyTypeId:2,
                // market:'1',//是否是市场标记 0是；1否
                typeName: '保京基地',
                parentAreaCodes: code, //区域id 12位
              },
              title: '供京基地概况',
            }
            this.$refs.enterpriseList.show(obj)
          })
        })
      }
    },
    bjjdgkReload(xData, dataC1, dataC2, Init) {
      //供京基地概况重载
      let zoomShow = false
      if (xData.length > 10) {
        zoomShow = true
      } else {
        zoomShow = false
      }
      this[Init].setOption({
        dataZoom: [
          //滚动条
          {
            show: zoomShow,
            type: 'slider',
            realtime: true,
            startValue: 0,
            endValue: 8,
            xAxisIndex: [0],
            bottom: '3%',
            left: '30',
            height: 10,
            borderColor: 'rgba(0,0,0,0)',
            textStyle: {
              color: '#05D5FF',
            },
          },
        ],
        xAxis: {
          data: xData,
        },
        series: [
          {
            data: dataC1,
          },
          {
            data: dataC2,
          },
        ],
      })
    },
    pzgyzsInit(Init, Id) {
      //品种供应走势
      this[Init] = this.$echarts.init(document.getElementById(Id))
      let dataC1 = []
      let dataC2 = []
      let dataC3 = []
      let xData = []
      let legendData = []
      // var fontColor = '#30eee9';
      let option = {
        // backgroundColor: '#0b1146',
        grid: {
          left: '60',
          right: '10',
          top: '40',
          bottom: '40',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B',
            },
          },
        },
        legend: {
          data: legendData,
          top: '10',
          textStyle: {
            color: '#BBD6F9',
            fontSize: 14,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: '#3585d5',
            },
          },
          axisLabel: {
            fontSize: 16,
            color: '#B9C3D3',
          },
          data: xData,
        },
        yAxis: {
          name: '亩',
          nameTextStyle: {
            color: '#B9C3D3',
            // align:'left'
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: false,
            // lineStyle: {
            //     color: '#3585d5',
            // },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#39547F',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: '#A2D4E6',
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            data: dataC1,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#FFD800',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#FFD800',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255,216,0,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,216,0,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: legendData[1],
            data: dataC2,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#00FCFF',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#00FCFF',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,252,255,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,252,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: legendData[2],
            data: dataC3,
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#59A4FF',
              // width: 3
            },
            itemStyle: {
              normal: {
                color: '#59A4FF',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(89,164,255,0.4)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(89,164,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      this[Init].setOption(option)
    },
    pzgyzsReload(xData, legendData, seriesData, Init) {
      //品种供应走势重载
      if (seriesData.length < 3) {
        for (let i = 0; i < 3; i++) {
          if (!seriesData[i]) {
            seriesData.push({ name: '', data: [] })
          }
        }
      }
      // console.log(seriesData)
      this[Init].setOption({
        legend: {
          data: legendData,
        },
        xAxis: {
          data: xData,
        },
        series: seriesData,
      })
    },
    bjgyqkInit(Init, Id) {
      //供京基地品种今日供应情况
      this[Init] = this.$echarts.init(document.getElementById(Id))
      let data1 = []
      let dataX = []
      let option = {
        // backgroundColor: '#0e2147',
        // tooltip: {
        //     trigger: 'axis',
        //     backgroundColor: 'rgba(17,95,182,0.5)',
        //     textStyle: {
        //     color: "#fff"
        //     },
        //     formatter: function (params) {
        //     return params[1].marker + '' + params[1].name + '\n' +
        //         "<div>" +
        //         "<span style='display:inline-block;border-radius:1px;width:10px;height:10px;background-color:#007AFF;option:0.5'></span>" +
        //         "<span style=''> " + params[1].seriesName + ': ' + params[1].value + "\n" + ' </span>' +
        //         "</div>" +
        //         "<span style='display:inline-block;border-radius:1px;width:10px;height:10px;background-color:#FF9B61;'></span>" +
        //         '' + "<span style=''> " + params[3].seriesName + ': ' + params[3].value;
        //     }
        // },
        grid: {
          left: '20',
          right: '40',
          top: '40',
          bottom: '40',
        },
        xAxis: [
          {
            data: dataX,
            show: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 16,
              color: '#B9C3D3',
              interval: 0,
            },
          },
          {
            type: 'category',
            show: true,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              interval: 0,
              verticalAlign: 'top',
              margin: 10,
              color: '#00FFFF',
              fontSize: '20',
            },
            data: data1,
          },
        ],
        yAxis: [
          {
            show: false,
          },
          {
            show: true,
            name: '单位：吨',
            nameTextStyle: {
              align: 'right',
              color: '#BBD6F9',
              padding: [0, 0, 0, 10],
              fontSize: 14,
            },
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            // 三个最低下的圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, 2],
            z: 12,
            // itemStyle: {
            //     opacity: 1,
            //     color: params=> {
            //         var a = params.name.slice(0, 2)
            //         return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //             offset: 0,
            //             color: '#407dfd' // 0% 处的颜色
            //         }, {
            //             offset: 1,
            //             color: '#448eff' // 100% 处的颜色
            //         }], false)
            //     }
            // },
            data: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
          },
          {
            // 下半截柱状图
            name: '',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            itemStyle: {
              // lenged文本
              opacity: 0.7,
              color: (params) => {
                var a = params.name.slice(0, 2)
                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#94d8e9', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#4892f9', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            data: data1,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 12],
            symbolOffset: [0, -4],
            z: 5,
            itemStyle: {
              opacity: 1,
              color: (params) => {
                var a = params.name.slice(0, 2)

                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#559ff6', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#85ccec', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            symbolPosition: 'end',
            data: data1,
          },
          {
            name: '', //头部
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color: '#366390',
              opacity: 1,
            },
            data: [150, 150, 150, 150, 150, 150],
          },
          {
            name: '',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            z: 0,
            itemStyle: {
              color: 'rgba(40,78,114,.5)',
              opacity: 0.7,
            },
            data: [150, 150, 150, 150, 150, 150],
          },
        ],
      }
      this[Init].setOption(option)
      if (Init == 'bjgyqk') {
        this.bjgyqk.on('click', (params) => {
          // console.log(params.name)
          // console.log(JSON.parse(JSON.stringify(this.bjgyqkData)))
          let plantId = ''
          for (let i = 0; i < this.bjgyqkData.length; i++) {
            if (params.name == this.bjgyqkData[i].plant_name) {
              this.selectLast(params.name)
              plantId = this.bjgyqkData[i].plant_id
              break
            }
          }
          let obj = {
            companyTypeId: 9,
            plantId, //种植品种id
            areaCode: this.currCityObj.code || 130000000000, //区划代码
          }
          this.$refs.supplyList.show(obj)
        })
      }
    },
    bjgyqkReload(dataX, data, maxData, Init) {
      //供京基地品种今日供应情况重载
      this[Init].setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,95,182,0.5)',
          textStyle: {
            color: '#fff',
          },
          formatter: function (params) {
            return (
              params[1].marker + '' + params[1].name + ':' + params[1].value
            )
          },
        },
        xAxis: [
          {
            data: dataX,
          },
          {
            data,
          },
        ],
        series: [
          {
            // 三个最低下的圆片
            itemStyle: {
              opacity: 1,
              color: (params) => {
                // if (params.name) {
                //     var a = String(params.name).slice(0, 2)
                // }
                return new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#407dfd', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#448eff', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
          },
          {
            // 下半截柱状图
            data,
          },
          {
            data,
          },
          {
            data: maxData,
          },
          {
            data: maxData,
          },
        ],
      })
    },
    // lookBrand(){//查看品牌列表
    //     this.$refs.brandList.show()
    // },
    lookBrandDetail(v) {
      //查看品牌展示
      let obj = {
        name: v.name, //品牌名称
        company_name: v.company_name, //品牌企业
        number: v.number, //品牌编号
        create_time: v.create_time, //时间
        imgUrl: v.img_url, //时间
        // classify:v.classify,//类型
      }
      this.$refs.brandDetail.show(obj)
    },
    arrowClick(val) {
      //上下切换
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    roll: function () {
      //每日供应情况滚动
      this.animate = true
      setTimeout(() => {
        this.todaySupply.push(this.todaySupply[0])
        this.todaySupply.shift()
        this.animate = false
      }, 500)
    },
    scjdEnter() {
      //每日供应情况进入
      // console.log('县蔬菜基地明细进入')
      clearInterval(this.todayTimer)
    },
    scjdOut() {
      //每日供应情况移出
      // console.log('县蔬菜基地明细移出')
      if (this.todaySupply.length > 4) {
        this.todayTimer = setInterval(this.roll, 5000)
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.bjjd {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 30px;
  // border: 3px solid #f00;
  background: url('~@/assets/images/cygk/cydx_bg.png') no-repeat center/100% 100%;
  display: flex;

  .left {
    // border: 3px solid #0f0;
    width: 475px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .chart {
        flex: 1;
        width: 100%;
      }
    }

    .middle {
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      // border: 1px solid #f00;
      overflow: hidden;

      .content {
        flex: 1;
        // border: 1px solid #f0f;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .list {
          // border: 2px solid #0f0;
          box-sizing: border-box;
          flex: 1;
          overflow: hidden;
          position: relative;

          .zz_select {
            // border: 1px solid #f00;
            box-sizing: border-box;
            flex: none;
            position: absolute;
            left: 50%;
            top: 20px;
            width: 172px;
            height: 55px;
            transform: translateX(-50%);
            background: url('~@/assets/images/pzzz_select_bg.png') no-repeat center/100% 100%;

            ::v-deep .el-input__inner {
              background-color: transparent;
              color: #fff;
              font-size: 16px;
              border: none;
              height: 55px;
              line-height: 55px;
              text-align: center;
            }

            ::v-deep.el-input__suffix {
              right: 25px;
            }
          }

          >img {
            width: 100%;
            height: 100%;
          }

          >li {
            position: absolute;
            text-align: center;
            // border: 1px solid #0f0;
            width: 100px;

            >div {
              text-align: center;
              position: relative;
              top: 25px;

              p {
                color: #fefefe;
                font-size: 18px;
                margin-bottom: 5px;
              }

              span {
                color: #00ffff;
                font-size: 24px;

                // display: block;
                // margin-top: 10px;
                i {
                  font-size: 14px;
                }
              }
            }

            .bottom_img {
              width: 80px;
              height: 82px;
              // border: 1px solid #0f0;
            }
          }

          li:nth-child(3) {
            // border: 1px solid #0f0;
            left: 0;
            top: 50px;
          }

          li:nth-child(4) {
            // border: 1px solid #f0f;
            left: 80px;
            top: 110px;
          }

          li:nth-child(5) {
            // border: 1px solid #f00;
            right: 0;
            top: 50px;
          }

          li:nth-child(6) {
            // border: 1px solid #ff0;
            right: 80px;
            top: 110px;
          }

          li:nth-child(7) {
            // border: 1px solid #0ff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 96px;
              height: 88px;
            }
          }
        }
      }
    }

    .bottom {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 20px 30px;
        box-sizing: border-box;
        overflow: hidden;

        .wrap {
          overflow: hidden;
          height: 100%;

          >ul {
            // border:1px solid #0f0;
            height: 170px;
            overflow: hidden;

            >li {
              // border:1px solid #0f0;
              height: 35px;
              display: flex;
              align-items: center;
              background-color: rgba(21, 78, 149, 0.3);
              margin-bottom: 10px;
              padding: 0 30px;
              box-sizing: border-box;
              position: relative;

              span {
                font-size: 16px;
                display: inline-block;
              }

              span:nth-child(1) {
                color: #fff;
                // border:1px solid #f0f;
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 1%;
              }

              span:nth-child(2) {
                color: #fff;
                // border:1px solid #0f0;
                width: 14%;
                margin-right: 1%;
              }

              span:nth-child(3) {
                color: #fff;
                // border:1px solid #0ff;
                width: 34%;

                i {
                  color: #2cc6ff;
                }
              }

              &::before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                width: 2px;
                height: 15px;
                background-color: #2cc6ff;
              }
            }
          }

          .marquee_top {
            transition: all 0.5s;
            margin-top: -40px;
          }
        }
      }
    }
  }

  .center {
    // border: 2px solid #f0f;
    flex: 1;
    margin: 0 10px;
    position: relative;

    .centerMenu {
      display: flex;
      align-items: center;
      position: absolute;
      justify-content: space-around;
      width: 100%;
      z-index: 99;
      padding: 10px;
      background: rgba(1, 46, 104, 0.5);

      .label {
        color: #aae5ed;
        font-size: 22px;
      }

      .number {
        font-size: 32px;
        margin-top: 10px;
      }

    }

    .plantMap {
      position: relative;
      top: 100px;
    }
  }

  .right {
    // border: 3px solid #0f0;
    width: 475px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .chart {
        flex: 1;
        width: 100%;
      }
    }

    .middle {
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;

      .chart {
        flex: 1;
        width: 100%;
      }
    }

    .bottom {
      height: 270px;
      flex: none;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 10px 50px;
        box-sizing: border-box;
        overflow: hidden;

        .wrap {
          background: url('~@/assets/images/jppp_border.png') no-repeat center/100% 100%;
          height: 200px;
          padding: 0 50px;
          box-sizing: border-box;
          position: relative;

          .inner {
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            h3 {
              font-size: 20px;
              background: url('~@/assets/images/jppp_bg.png') no-repeat center/100% 100%;
              margin-top: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            img {
              max-width: 100%;
              height: 90px;
              margin: 10px 0;
            }

            p {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }

          .left_arrow {
            left: 8px;
          }

          .right_arrow {
            right: 5px;
          }

          ::v-deep.el-carousel__button {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #25b0ff;
          }
        }
      }
    }
  }

  .zzqk_dialog {
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
