<template>
  <div class="list">
    <base-dialog
      :width="form.title=='重点发展县'?'700px':'1200px'"
      :label="form.title"
      v-show="dialogFlag"
      :show-close="true"
      @closeDialog="closeDialog"
    >
      <div class="wrap">
        <div v-if="form.title=='重点发展县'">
          <div class="table_list">
            <el-table :data="tableData" stripe class="pub_table" height="700">
              <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
              <el-table-column prop="city" label="市" align="center"></el-table-column>
              <el-table-column prop="area" label="县/区" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else class="table_list">
          <el-table :data="tableData" stripe class="pub_table" height="700">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="city" label="市" width="120" align="center"></el-table-column>
            <el-table-column prop="area" label="县/区" width="120" align="center"></el-table-column>
            <el-table-column prop="special" label="产业带" align="center"></el-table-column>
            <el-table-column prop="from" label="来源" align="center"></el-table-column>
          </el-table>
        </div>
        <!-- <el-pagination
          class="pub_laypage"
          :page-size="15"
          @current-change="listData"
          :current-page.sync="form.page"
          prev-text="上一页"
          next-text="下一页"
          :pager-count="11"
          layout="prev, pager, next,total"
          :total="total"
        ></el-pagination>-->
      </div>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
export default {
  components: { BaseDialog },
  data() {
    return {
      form: {
        page: 1,
        title: '',
      },
      tableData: [],
      total: 0,
      dialogFlag: false,
    }
  },
  created() {},
  methods: {
    listData(page) {
      //专家列表
      this.form.page = page || 1
      this.tableData = this.total = this.tableData.length
    },
    closeDialog() {
      //关闭弹框
      this.dialogFlag = false
      // this.$emit('closeDialog')
    },
    show(obj) {
      //显示
      this.dialogFlag = true
      this.form.title = obj.title
      this.tableData = obj.list
    },
  },
}
</script>

<style lang='scss' scoped>
.list {
  // border: 1px solid #f00;
  .wrap {
    // border: 3px solid #0f0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .myform {
      flex: none;
      .btn {
        font-size: 20px;
        padding: 0 20px;
        height: 40px;
      }
    }
    .table_list {
      flex: 1;
      overflow: hidden;
      margin-bottom: 10px;
      .look {
        color: #00ffff;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .pub_laypage {
      flex: none;
    }
  }
}
</style>