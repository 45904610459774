 const gj = [{
         city: '廊坊市',
         area: '固安县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '唐山市',
         area: '滦南县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '沧州市',
         area: '青县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '保定市',
         area: '定兴县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '衡水市',
         area: '阜城县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '衡水市',
         area: '饶阳县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '保定市',
         area: '涞源县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '邢台市',
         area: '任泽区',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '邢台市',
         area: '南和县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },
     {
         city: '石家庄市',
         area: '正定县',
         special: '环京津高端精品产业带',
         from: '国家资金新建2022年环京津设施蔬菜优势特色产业集群',
     },

 ]
 const sheng = [{
         city: '邯郸市',
         area: '馆陶县',
         special: '滏阳河流域设施蔬菜',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '邯郸市',
         area: '经开区',
         special: '滏阳河流域设施蔬菜',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '邯郸市',
         area: '永年区',
         special: '滏阳河流域设施蔬菜',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '雄县',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '容城县',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '安新县',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '清苑区',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '望都县',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '涿州市',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '高碑店',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '满城区',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '保定市',
         area: '定州市',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '廊坊市',
         area: '固安县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '廊坊市',
         area: '安次区',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '廊坊市',
         area: '三河市',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '唐山市',
         area: '玉田县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '秦皇岛市',
         area: '昌黎县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '衡水市',
         area: '饶阳县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '张家口市',
         area: '赤城县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '张家口市',
         area: '阳原县',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '承德市',
         area: '双滦区',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '承德市',
         area: '双桥区',
         special: '环京津高端精品产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '石家庄市',
         area: '正定县',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
     {
         city: '石家庄市',
         area: '无极限',
         special: '环雄安设施果蔬产业带',
         from: '河北省2022年精品蔬菜产业集群项目',
     },
 ]
 export default { gj, sheng };