<template>
  <div class="map-wrapper" :style="{ 'height': height }">
    <div class="back" v-show="hasDrop && drop" @click="back"></div>
    <div class="map" id="Map"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import areaCode from '../../../public/config/area1.json'
import countyCode from '../../../public/config/county.json'
import hebei1 from '@/assets/map/map-hebei.json'
import hebei from '@/assets/map/areas.json'
import county from '@/assets/map/map-county.json'
import bd from '@/assets/map/bd.json'
import cd from '@/assets/map/cd.json'
import cz from '@/assets/map/cz.json'
import hd from '@/assets/map/hd.json'
import hs from '@/assets/map/hs.json'
import lf from '@/assets/map/lf.json'
import qhd from '@/assets/map/qhd.json'
import sjz from '@/assets/map/sjz.json'
import ts from '@/assets/map/ts.json'
import xt from '@/assets/map/xt.json'
import zjk from '@/assets/map/zjk.json'
import yongqing from '@/assets/map/yongqing.json'
import guan from '@/assets/map/guan.json'
import weichang from '@/assets/map/weichang.json'
import laoting from '@/assets/map/laoting.json'
import yutian from '@/assets/map/yutian.json'
import changli from '@/assets/map/changli.json'
import dingxing from '@/assets/map/dingxing.json'
import zhuzhou from '@/assets/map/zhuzhou.json'
import qingxian from '@/assets/map/qingxian.json'
import luannan from '@/assets/map/luannan.json'
import raoyang from '@/assets/map/raoyang.json'
import chongli from '@/assets/map/chongli.json'
import fengning from '@/assets/map/fengning.json'
import qingyuan from '@/assets/map/qingyuan.json'
import fucheng from '@/assets/map/fucheng.json'
import xinle from '@/assets/map/xinle.json'
import guantao from '@/assets/map/guantao.json'
import wangdu from '@/assets/map/wangdu.json'
import jize from '@/assets/map/jize.json'

const geoJSON = {
  // 保定市: bd,
  // 承德市: cd,
  // 沧州市: cz,
  // 邯郸市: hd,
  // 衡水市: hs,
  // 廊坊市: lf,
  // 秦皇岛市: qhd,
  // 石家庄市: sjz,
  // 唐山市: ts,
  // 邢台市: xt,
  // 张家口市: zjk,
  永清县: yongqing,
  固安县: guan,
  围场满族蒙古族自治县: weichang,
  乐亭县: laoting,
  玉田县: yutian,
  昌黎县: changli,
  定兴县: dingxing,
  涿州市: zhuzhou,
  青县: qingxian,
  滦南县: luannan,
  饶阳县: raoyang,
  崇礼区: chongli,
  丰宁满族自治县: fengning,
  清苑区: qingyuan,
  阜城县: fucheng,
  新乐市: xinle,
  馆陶县: guantao,
  望都县: wangdu,
  鸡泽县: jize
}

let countyJson = {
  type: 'map',
  map: 'county',
  aspectScale: 0.88,
  roam: false,
  scaleLimit: {
    min: 1,
  },
  z: 3,
  animationDurationUpdate: 0,
  label: {
    normal: {
      show: false,
      textStyle: {
        color: '#fff',
        fontSize: '17',
      },
    },
    emphasis: {
      textStyle: {
        color: '#fff',
        fontSize: '17',
      },
    },
  },
  itemStyle: {
    normal: {
      borderColor: 'rgba(162,255,0,0.61)',
      areaColor: 'rgba(162,255,0,0.61)',
    },
  },
  emphasis: {
    itemStyle: {
      borderColor: 'rgba(255,117,63,0.63)',
      areaColor: 'rgba(255,117,63,0.63)',
    },
  },
}

export default {
  props: {
    hasDrop: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '664px',
    },
    chartOption: {
      type: Object,
      default: () => {
        return {}
      },
    },
    hasCounty: {
      type: Boolean,
      default: false,
    },
    geoData: {
      type: Array,
      default: () => [],
    },
    countySelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drop: false,
    }
  },
  mounted() {
    this.initMap()
    // if (this.option.geo[0].map != '河北') {
    //   console.log('111111111111111111111');
    //   this.option.geo[0].label.normal.show = true;
    //   this.mapChart.setOption(this.option, true)
    // }
  },
  methods: {
    initMap() {
      this.$echarts.registerMap('河北', hebei)
      this.$echarts.registerMap('county', county)
      this.mapChart = this.$echarts.init(document.getElementById('Map'))
      this.option = {
        tooltip: {},
        geo: [
          {
            map: '河北',
            layoutCenter: ['50%', '50%'],
            layoutSize: this.chartOption.layoutSize || '100%',
            z: 3,
            zlevel: 3,
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: '#fff',
                  fontSize: '16',
                  backgroundColor: 'rgba(0,96,255,0.5)',
                  lineHeight: 32,
                  padding: [0, 8],
                  borderWidth: 1,
                  borderColor: 'rgba(0,96,255,1)',
                },
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: '#fff',
                  fontSize: '16',
                  // backgroundColor: 'rgba(255,117,63,0.63)',
                  // lineHeight: 32,
                  // padding: [0, 8],
                  // borderWidth: 1,
                  // borderColor: 'rgba(255,117,63,1)',
                },
              },
            },
            roam: true, //是否允许缩放
            aspectScale: 0.8,
            scaleLimit: {
              min: 1,
            },
            selectedMode: 'single',
            itemStyle: {
              normal: {
                borderColor: '#B5E9FB',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgb(21,171,246)',
                shadowOffsetX: 0,
                shadowOffsetY: 6,
                shadowBlur: 4,
              },
            },
            emphasis: {
              itemStyle: {
                borderColor: 'rgba(229,185,8, 1)',
                borderWidth: 2,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255,0.11)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgba(229,185,8, 1)',
                shadowOffsetX: 2,
                shadowOffsetY: 4,
                shadowBlur: 4,
              },
            },
            select: {
              itemStyle: {
                borderColor: 'rgba(229,185,8, 1)',
                borderWidth: 2,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgba(229,185,8, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 4,
                shadowBlur: 10,
              },
            },
            tooltip: {
              show: true,
              formatter: (params) => {
                //                            console.log(params)
                return this.chartOption.tooltip
                  ? this.chartOption.tooltip(params)
                  : params.name
              },
            },
          },
        ],
        series: [],
      }
      // 检查是否包含指定区县地图
      if (this.hasCounty) {
        this.option.geo.push(countyJson);
      }
      let click_type;
      this.mapChart.setOption(this.option, true)
      var that = this
      this.mapChart.on('click', (params) => {
        click_type = false;
        setTimeout(check, 500);
        function check() {
          if (click_type != false) return;
          const callback = () => {
            if (params.componentType === 'geo') {
              if (params.region.selected || params.region.name !== that.lastName) {
                const key = that.option.geo[0].map === '河北' ? that.lastName : that.option.geo[0].map
                if (
                  countyCode[key] !== undefined &&
                  countyCode[key][params.name] &&
                  !that.countySelect
                )
                  return
                const code =
                  areaCode[params.name] || countyCode[key][params.name] || ''
                that.$emit('selectCity', {
                  parent: that.option.geo[0].map,
                  name: params.name,
                  level: areaCode[params.name] ? 2 : 3,
                  code: code,
                  pageX: params.event.event.pageX + 50,
                  pageY: params.event.event.pageY,
                })
                //                            console.log(code)
                that.lastName = params.region.name
                if (!that.hasDrop) return
                //                            that.clickStatus = true
                if (geoJSON[params.name]) {
                  that.$echarts.registerMap(params.name, geoJSON[params.name])
                  that.option.geo[0].map = params.name
                  // 包含则下钻去掉
                  if (that.hasCounty) {
                    that.option.geo.length = 1
                  }
                  that.option.geo[0].label.normal = {
                    show: true,
                    textStyle: {
                      color: '#fff',
                      fontSize: '16',
                    }
                  };  //下钻显示乡镇
                  that.mapChart.setOption(that.option, true);
                  that.drop = true;
                }
              } else {
                if (that.option.geo[0].map === '河北') {
                  that.$emit('selectCity', {
                    parent: '',
                    name: '河北省',
                    level: 1,
                    code: '',
                  })
                  that.lastName = '河北省'
                } else {
                  that.$emit('selectCity', {
                    parent: '河北省',
                    name: that.option.geo[0].map,
                    level: 2,
                    code: areaCode[that.option.geo[0].map],
                    pageX: params.event.event.pageX + 50,
                    pageY: params.event.event.pageY,
                  })
                  that.lastName = that.option.geo[0].map
                }
              }
            } else if (params.seriesType === 'scatter') {
              // console.log(params, 'dddddddddddddddddddddd');
              that.$emit('lookDetail', params.data)
            } else {
              console.log('scatter', 123456, params.data)
            }
          }
          //                setTimeout(callback, 500)
          callback()
        }
      })
      //            this.mapChart.on('dblclick', (params) => {
      //                if (!this.hasDrop) return
      //                this.clickStatus = true
      //                if (geoJSON[params.name]) {
      //                    this.$echarts.registerMap(params.name, geoJSON[params.name])
      //                    this.option.geo[0].map = params.name
      //                    // 包含则下钻去掉
      //                    if (this.hasCounty) {
      //                        this.option.geo.length = 1
      //                    }
      //                    this.mapChart.setOption(this.option, true)
      //                    this.drop = true
      //                }
      //            })
      this.mapChart.on('georoam', (params) => {
        if (!this.hasCounty) return
        let option = this.mapChart.getOption()
        if (params.zoom !== null && params.zoom !== undefined) {
          option.geo[1].zoom = option.geo[0].zoom
          option.geo[1].center = option.geo[0].center
        } else {
          option.geo[1].center = option.geo[0].center
        }
        this.mapChart.setOption(option, true)
      })
      this.mapChart.on('mousemove', (params) => {
        // console.log(params, 'mmmmmmmmmmmmmmmmmmmmmmmmmm');
        if (params.componentType === 'geo') {
          this.$emit('geoMousemove', {
            pageX: params.event.event.pageX + 50,
            pageY: params.event.event.pageY,
            name: params.name,
          })
          //                    console.log(params,params.event.event.pageX,params.event.event.pageY)
        } else if (params.componentType === 'series') {
          this.$emit('geoMousemove', {
            pageX: params.event.event.pageX + 50,
            pageY: params.event.event.pageY,
            name: params.name,
          })
        }
      })
      this.mapChart.on('dblclick', (params) => {
        click_type = true
        if (params.name == "饶阳县") {
          window.open("http://47.94.107.242:35015/vegetableScreen/#/raoyangMode?name=饶阳模式&area=131124&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxMjg1N2JjMTQ3YzI0ZDVlOGEyNThjNzVlNzY2ZmI1NSIsInVzZXJuYW1lIjoiMTg5MDAwMDAwMTAifQ.s6yJ5hMsHcOhPSexzlqd11Yl-9k9YvvT0Ha_d2TCnFU", '_blank');
        } else if (params.name == "固安县") {
          window.open("http://47.94.107.242:35015/vegetable_stats_guan/#/guanMode?name=%E5%9B%BA%E5%AE%89%E6%A8%A1%E5%BC%8F&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkMjkwNjQzZTQ2YTI0Y2EzYmMyMzhmZTQ2YWJhZjQ0YyIsInVzZXJuYW1lIjoi5Zu65a6JLeiUrOiPnCJ9.GiAjyKBO9SP17fMLFoEPUmYnWre0N22rLrsq6t6RBCg", "_blank");
        }
      })
    },
    addMarker(markers) {
      let series = []
      markers.forEach((marker) => {
        let { data, name, url, size } = marker
        let markers = {
          name,
          type: 'scatter',
          coordinateSystem: 'geo',
          symbol: url,
          symbolSize: size,
          itemStyle: {
            opacity: 1,
          },
          data,
          z: 300,
          zlevel: 300,
        }
        series.push(markers)
      })
      if (!series.length) {
        series = [
          {
            data: [],
          },
        ]
      }
      this.mapChart.clear()
      this.option.series = series
      this.mapChart.setOption(this.option, true)
      this.option = this.mapChart.getOption()
    },
    back() {
      this.drop = false
      this.option.geo[0].map = '河北'
      this.option.geo[0].label.normal = { show: false }; //省级不显示县
      // 包含则返回加上区县地图数据
      if (this.hasCounty) {
        this.option.geo.push(countyJson)
      }
      this.option.series = [];
      this.option.geo[0].regions = [];
      // this.geoData = [];
      this.mapChart.setOption(this.option, true);
      this.$emit('initPage');
      // this.$emit('selectCity', {
      //   name: '河北省',
      //   code: '',
      // })
    },
    setGeoSetting(data) {
      // console.log(data, '123data')
      let customSetting = []
      if (Array.isArray(data) && data.length) {
        data.sort((a, b) => a.plantnum - b.plantnum).forEach((item, index) => {
          let color = "rgba(255,182,46, 0.5)"// `rgba(5, 173, 90, 0.5)`
          let color1 = `rgba(173, 5, 5, 0.5)`
          // let color = `rgba(5,173,90, ${(index + 1) * 0.1})`

          customSetting.push({
            name: item.areaName,
            itemStyle: {
              borderColor: String(item.areaCode).includes('00000000') ? '#87fff8' : 'rgba(181,233,251,0.4)',
              borderWidth: String(item.areaCode).includes('00000000') ? 3 : 1,
              areaColor: (item.areaName == '饶阳县' || item.areaName == '固安县') ? color1 : (String(item.areaCode).includes('00000000') ? 'rgba(0,0,0,0)' : color),
              shadowColor: (item.areaName == '饶阳县' || item.areaName == '固安县') ? 'rgb(173,5,5)' : "rgba(21,209,249,0.8)" //'rgb(5,173,90)',
            },
            emphasis: {
              show: false,
            },
          })
        })
      }
      this.option.geo[0].regions = customSetting
    },
  },
  watch: {
    geoData: {
      //监听的对象
      deep: true, //深度监听设置为 true
      handler: function () {
        this.setGeoSetting(JSON.parse(JSON.stringify(this.geoData)))
        this.mapChart.setOption(this.option, true)
      },
    },
  },
}
</script>

<style scoped lang='scss'>
.map-wrapper {
  position: relative;
  top: 90px;

  .back {
    position: absolute;
    width: 179px;
    height: 51px;
    background: url('back.png') no-repeat center/cover;
    cursor: pointer;
    z-index: 100;
    top: 10px;
    left: 10px;
  }

  .map {
    height: 100%;
  }
}
</style>
