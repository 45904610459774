import { request } from "@/utils/axios";

// 产业概况
//概况-全省概况表
export const listByYear = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/admin/provincesurvey/ListByYear",
    params,
  });
};
//概况-服务企业
export const labelsCount = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/comAndLabRelation/labelsCount",
    params,
  });
};
//概况-精品品牌
export const getBandList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/brandInfo/getBandList",
    params,
  });
};
//概况-面积及产量列表
export const yieldListByYear = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/business/yield/ListByYear",
    params,
  });
};
//概况-面积及产量-年份列表接口
export const getYearList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/business/yield/getYearList",
    params,
  });
};
//概况-销售列表
export const saleListByYear = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/survey/sale/listByYear",
    params,
  });
};
//概况-总体概况
export const ztListByYear = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/business/population/listByYear",
    params,
  });
};
//产业大县-总数
export const getcountlist = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/business/company/getcountlist",
    params,
  });
};
//产业大县-品种面积
export const groupCountByAreaCode = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/plantInfo/groupCountByAreaCode",
    params,
  });
};
//产业大县-种植情况
export const getPlatInfoByAreaCode = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/business/company/getPlatInfoByAreaCode",
    params,
  });
};
//产业大县-县蔬菜基地明细
export const getVegetablesBaseInfo = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/CompanyRecoveryInfo/getVegetablesBaseInfo",
    params,
  });
};
//产业大县-蔬菜产量
export const getRecoveryCount = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/CompanyRecoveryInfo/getRecoveryCount",
    params,
  });
};
//产业大县-品种历史产量
export const getHisData = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/CompanyRecoveryInfo/getHisDataByPlantIdAndAreaCode",
    params,
  });
};
//产业大县-基地品牌
export const getCydxBandList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/web/brandInfo/getBandList",
    params,
  });
};
//产业大县-总数
export const Bjgetcountlist = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/getcountlist",
    params,
  });
};
//供京基地-品牌列表
export const selectBrandList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectBrandList",
    params,
  });
};
//供京基地-近12月走势-品种供应走势
export const selectLast12MonthsTrend = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectLast12MonthsTrend",
    params,
  });
};
//供京基地-今日各品类供应图
export const selectTodayTypeSumList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectTodayTypeSumList",
    params,
  });
};
//供京基地-每日供应情况
export const selectTodaySupply = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectTodaySupply",
    params,
  });
};
//供京基地-概况
export const selectBaojingSurvey = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectBaojingSurvey",
    params,
  });
};
//供京基地-基地列表-地图
export const selectBaojingList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectBaojingList",
    params,
  });
};
//供京基地-各品种种植情况
export const selectEveryPlantList = (params) => {
  return request({
    method: "get",
    url: sysUrl + "/baojing/selectEveryPlantList",
    params,
  });
};
//首页概况-产业人才-团队人数
export const countExpertData = (params, headers) => {
  return request({
    method: "get",
    url: thingsApi + "/expertv2/v1/api/expert/countExpertData",
    params,
    headers,
  });
};
//示范园区
export const demonstrationpark = (data) => {
  return request({
    method: "post",
    url: sysUrlgis + "/countyfarm/demonstrationpark/getList",
    data,
  });
};
//示范园区-信息
export const demonstrationparkById = (params) => {
  return request({
    method: "get",
    url: sysUrlgis + "/countyfarm/demonstrationpark/getById",
    params,
  });
};
//重点项目
export const keyproject = (data) => {
  return request({
    method: "post",
    url: sysUrlgis + "/countyfarm/keyproject/getList",
    data,
  });
};
//重点项目-信息
export const keyprojectById = (params) => {
  return request({
    method: "get",
    url: sysUrlgis + "/countyfarm/keyproject/getById",
    params,
  });
};
//龙头企业
export const listNoPage = (params) => {
  return request({
    method: "get",
    url: sysUrlgis + "/business/company/listNoPage",
    params,
  });
};
//产业概况-汇总信息
export const sumInfo = (params) => {
  return request({
    method: "get",
    url: sysUrlgis + "/countyfarm/demonstrationpark/sumInfo",
    params,
  });
};
